import {
  UsergroupAddOutlined,
  FileTextOutlined,
  UserOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";

export const publicMenuItems = [
  { key: "home", label: "Home" },
  { key: "about", label: "About Us" },
  {
    key: "services",
    label: "Services",
    children: [{ key: "first-service", label: "First Service" }],
  },
  {
    key: "portfolio",
    label: "Portfolio",
    children: [{ key: "first-portfolio", label: "First Portfolio" }],
  },
  { key: "contact", label: "Contact Us" },
  { key: "news", label: "News" },
];

export const privateMenuItems = (role) => {
  return [
    { key: "/profile", label: "My Account", icon: <UserOutlined /> },
    role === "admin" && {
      key: "/projects",
      icon: <FileTextOutlined />,
      label: "Projects",
    },
    role === "admin" && {
      key: "contractors",
      icon: <UsergroupAddOutlined />,
      label: "Contractors",
      children: [
        {
          key: "/contractors/active",
          label: "Active",
        },
        {
          key: "/contractors/deactive",
          label: "Deactivated",
        },
      ],
    },
    role === "contractor" && {
      key: "projects",
      icon: <FileTextOutlined />,
      label: "Projects",
      children: [
        {
          key: "/projects",
          label: "Submitted",
        },
        {
          key: "/projects/drafts",
          label: "Drafts",
        },
      ],
    },
    role === "contractor" && {
      key: "/payments-section",
      icon: <CreditCardOutlined />,
      label: "Payment",
      children: [
        {
          key: "/payment-methods",
          label: "Credit Cards",
        },
        {
          key: "/payments",
          label: "Transactions",
        },
      ],
    },
    role === "admin" && {
      key: "/transactions",
      label: "Transactions",
      icon: <CreditCardOutlined />,
    },
  ];
};
export const findOpenKeys = (items, path) => {
  for (const item of items) {
    if (item.children) {
      if (item.children.some((child) => path.startsWith(child.key))) {
        return [item.key];
      }
    }
  }
  return [];
};
