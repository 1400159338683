import FloatingLabelInput from "../inputs/FloatingLabelInput.js";
import "./styles.css";

const ProjectInformationForm = ({ errors, initialValues = {} }) => {
  return (
    <div className="form-wrapper margin-top-xxl">
      <FloatingLabelInput
        name="projectName"
        errors={errors}
        initialValue={initialValues.projectName || initialValues.name}
      />
      <FloatingLabelInput
        name="address"
        errors={errors}
        initialValue={initialValues.address}
      />
      <FloatingLabelInput
        name="city"
        errors={errors}
        initialValue={initialValues.city}
      />
      <FloatingLabelInput
        name="state"
        errors={errors}
        initialValue={initialValues.state}
      />
      <FloatingLabelInput
        name="zipCode"
        errors={errors}
        initialValue={initialValues.zipCode}
      />
      <FloatingLabelInput
        name="utilityCompany"
        errors={errors}
        initialValue={initialValues.utilityCompany}
      />
      <FloatingLabelInput
        name="phoneNumberNotRequired"
        errors={errors}
        initialValue={
          initialValues.phoneNumberNotRequired || initialValues.phone
        }
      />
    </div>
  );
};

export default ProjectInformationForm;
