import { Form, Select } from "antd";
import { emailValidators } from "../../utils/formValidators";
import { useState } from "react";

const CreatableMultipleSelect = ({
  name = "",
  label = "",
  options = [],
  resetValue = () => {},
  handleBlur = () => {},
}) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (values) => {
    // make handleChange reusable
    setSelectedValues(values);
    const currentValue = values[values.length - 1];
    const validationMessage = emailValidators(currentValue);
    if (!!validationMessage) {
      resetValue(values.filter((val) => val !== currentValue));
      return;
    }
  };
  const onBlur = (params) => {
    handleBlur(name, selectedValues);
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: true, message: "The field is required" }]}
      className="creatable-select-form-item"
    >
      <Select
        className="creatable-select"
        mode="tags"
        onChange={handleChange}
        options={options}
        virtual={false}
        filterOption
        optionFilterProp="label"
        onBlur={onBlur}
      />
    </Form.Item>
  );
};

export default CreatableMultipleSelect;
