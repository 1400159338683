import { memo, useEffect, useState } from "react";
import {
  deactivateContractorByAdmin,
  getContractors,
  getContractorsTotalCount,
  restoreContractorByAdmin,
} from "../../services/contractor";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  UserDeleteOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { message } from "../../utils/utils";
import InfiniteScrollCards from "../../components/infiniteScrollCards";
import SearchInput from "../../components/inputs/SearchInput";
import GeneralInfo from "../../components/generalInfo";
import IconWithText from "../../components/iconWithText";
import CustomModal from "../../components/modal";
import ModalContent from "../../components/modal/ModalContent";
import ModalFooter from "../../components/modal/ModalFooter";
import "./styles.css";

const fields = {
  fullName: {
    icon: UserOutlined,
  },
  email: {
    label: "Email",
  },
  companyName: {
    label: "Company",
  },
  totalCosts: {
    label: "Spent",
  },
};

const ContractorsMobile = ({ deactivated }) => {
  const [contractors, setContractors] = useState({ items: [], count: 0 });
  const [loading, setLoading] = useState(true);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState();
  const [generalInfo, setGeneralInfo] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    getContractors({
      offset: 0,
      limit: 25,
      searchQuery,
      deactivated,
    })
      .then(setContractors)
      .catch((error) =>
        console.error("GET-ActiveContractors - CATCH --- ERROR", error)
      )
      .finally(() => setLoading(false));
  };
  const fetchMoreData = () => {
    setLoading(true);
    getContractors({
      offset: contractors.items.length,
      limit: 25,
      searchQuery,
      deactivated,
    })
      .then((data) =>
        setContractors({
          count: data.count,
          items: [...contractors.items, ...data.items],
        })
      )
      .catch((error) =>
        console.error("GET-ActiveContractors - CATCH --- ERROR", error)
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!!searchQuery) {
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchData();
    }
    return () => {
      setContractors({ count: 0, items: [] });
    };
  }, [searchQuery, deactivated]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("search") || "";
    setSearchQuery(query);
  }, [location.search]);

  useEffect(() => {
    getContractorsTotalCount()
      .then(setGeneralInfo)
      .catch((error) => console.error(error));
  }, [contractors]);

  const handleSearch = (value) => {
    setLoading(true);
    setContractors({ count: 0, items: [] });
    setSearchQuery(value);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("search", value);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const renderActions = ({ profileId }) => {
    return [
      <Link to={`/contractors/${profileId}`}>
        <div className="is-link">
          <IconWithText
            text="View Profile"
            IconComponent={UserOutlined}
            iconClassname="text-md"
            styles={{ justifyContent: "center" }}
          />
        </div>
      </Link>,
      <div
        onClick={() => {
          setSelectedContractor(profileId);
          return setDeactivateModalOpen(true);
        }}
      >
        <IconWithText
          IconComponent={!deactivated ? UserDeleteOutlined : UserAddOutlined}
          text={!deactivated ? "Deactivate" : "Restore"}
          status={!deactivated ? "error" : "success"}
          iconClassname="text-md"
          styles={{ justifyContent: "center" }}
        />
      </div>,
    ];
  };

  const handleDeactivateOrRestore = () => {
    const fetchData = !deactivated
      ? deactivateContractorByAdmin(selectedContractor)
      : restoreContractorByAdmin(selectedContractor);
    fetchData
      .then(() => {
        setContractors((contractors) => ({
          count: contractors.count,
          items: contractors.items.filter(
            (contractor) => contractor.profileId !== selectedContractor
          ),
        }));
        message({
          type: "success",
          message: `Contractor has been successfully ${
            !deactivated ? "deactivated" : "activated"
          }`,
        });
      })
      .catch((error) => {
        message({
          type: "error",
          message: "Something went wrong",
        });
        console.error(
          "Contractors List Deactivate - ERROR ----------- ",
          error
        );
      })
      .finally(() => {
        setDeactivateModalOpen(false);
      });
  };
  const renderGeneralInfo = [
    {
      title: "Total",
      value: generalInfo.total,
      icon: UserOutlined,
    },
    {
      title: "Active",
      value: generalInfo.active,
      icon: UserAddOutlined,
    },
    {
      title: "Deactivated",
      value: generalInfo.deactivated,
      icon: UserDeleteOutlined,
    },
  ];

  return (
    <div>
      <GeneralInfo items={renderGeneralInfo} />
      <div className="margin-top-xl margin-bottom-xs">
        <SearchInput
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <InfiniteScrollCards
        data={contractors}
        fetchMoreData={fetchMoreData}
        fields={fields}
        loading={loading}
        renderActions={renderActions}
      />
      <CustomModal
        open={deactivateModalOpen}
        footer={null}
        content={
          <>
            <ModalContent
              text={
                !deactivated
                  ? "Are you sure you want to deactivate this account?"
                  : "Are you sure you want to restore this account?"
              }
            />
            <ModalFooter
              submitTitle={!deactivated ? "Deactivate" : "Restore"}
              onSubmit={handleDeactivateOrRestore}
              onCancel={() => setDeactivateModalOpen(false)}
              type="default"
              danger
            />
          </>
        }
      />
    </div>
  );
};

export default memo(ContractorsMobile);
