import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Drawer } from "antd";
import "./styles.css";

const CustomDrawer = ({
  open = false,
  onCancel = () => {},
  title = "",
  placement = "right",
  content = null,
  customClass = "",
}) => {
  const [contentHeight, setContentHeight] = useState("100%");
  const { isMobile } = useWindowSize();
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.getBoundingClientRect().height;
      const calc = window.innerHeight - 56 - 60;
      setContentHeight(height > calc ? "calc(100% - 65px)" : "auto");
    }
  }, [open, title, contentRef && contentRef.current]);

  return (
    <Drawer
      className={`drawer ${customClass}`}
      open={open}
      title={title}
      placement={isMobile ? "bottom" : placement}
      width={isMobile ? "100%" : "fit-content"}
      height={contentHeight}
      onClose={onCancel}
      destroyOnClose
      closable={false}
    >
      <div ref={contentRef}>{content}</div>
    </Drawer>
  );
};

export default CustomDrawer;
