const GmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
    >
      <g clipPath="url(#clip0_1977_2747)">
        <path
          d="M5.18183 15.4264V7.73905L2.84526 5.55799L0.75 4.34766V14.0698C0.75 14.8204 1.34604 15.4264 2.07958 15.4264H5.18183Z"
          fill="#4285F4"
        />
        <path
          d="M15.8184 15.4263H18.9206C19.6564 15.4263 20.2502 14.818 20.2502 14.0697V4.34766L17.877 5.73395L15.8184 7.73898V15.4263Z"
          fill="#34A853"
        />
        <path
          d="M5.1822 7.7388L4.86426 4.73514L5.1822 1.86035L10.5004 5.93009L15.8185 1.86035L16.1742 4.57994L15.8185 7.7388L10.5004 11.8085L5.1822 7.7388Z"
          fill="#EA4335"
        />
        <path
          d="M15.8184 1.86039V7.73884L20.2502 4.34744V2.53865C20.2502 0.861064 18.3733 -0.0952832 17.0594 0.910805L15.8184 1.86039Z"
          fill="#FBBC04"
        />
        <path
          d="M0.75 4.34747L2.78828 5.90732L5.18183 7.73887V1.86043L3.94084 0.910841C2.62459 -0.0953252 0.75 0.8611 0.75 2.53861V4.34739V4.34747Z"
          fill="#C5221F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1977_2747">
          <rect
            width="19.5"
            height="15"
            fill="white"
            transform="translate(0.75 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GmailIcon;
