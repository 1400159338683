import { useState } from "react";
import { Form } from "antd";
import UploadWithDragAndDrop from "../../../components/uploadWithDragAndDrop";
import CustomForm from "../../../components/form";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput";
import ModalFooter from "../../../components/modal/ModalFooter";
import useSetUploadError from "../../../hooks/useSetUploadError";
import "../styles.css";

const SendPriceForm = ({
  name,
  onFinish,
  onDeleteAttachment,
  uploadRequest,
  attachments = [],
  onCancel = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { errors, setErrors, setFilesList, currentError, setUploadErrorField } =
    useSetUploadError({ form });

  const beforeFinish = async (values) => {
    if (setUploadErrorField().errors[0]) {
      return;
    }
    setLoading(true);
    onFinish(values);
  };

  return (
    <div className="forms-container">
      <CustomForm
        form={form}
        className="full-width"
        onFinish={beforeFinish}
        setErrors={setErrors}
        setUploadErrorField={setUploadErrorField}
      >
        <FloatingLabelInput name={name} errors={errors} />
        <Form.Item name="upload">
          <p className="text-semibold-light text-sm margin-bottom-xs">
            Choose PDF Files
          </p>
          <UploadWithDragAndDrop
            accept="application/pdf"
            onDeleteAttachement={onDeleteAttachment}
            uploadRequest={uploadRequest}
            clientAttachments={attachments}
            setList={setFilesList}
            unlimited
            currentError={currentError}
            setUploadErrorField={setUploadErrorField}
          />
        </Form.Item>
        <FloatingLabelInput name="notes" errors={errors} />
        <div className="margin-top-xxxl">
          <ModalFooter
            htmlType="submit"
            submitTitle="Submit"
            onCancel={onCancel}
            loading={loading}
            disabled={loading}
          />
        </div>
      </CustomForm>
    </div>
  );
};

export default SendPriceForm;
