export const roofTypes = {
  "RAFTER 2 X 4 @ 12\" OC, SPAN <9'": "RAFTER2X412OCSPAN9.png",
  "RAFTER 2 X 4 @ 16\" OC, SPAN <8'": "RAFTER2X416OCSPAN8.png",
  "RAFTER 2 X 4 @ 24\" OC, SPAN <7'": "RAFTER2X424OCSPAN7.png",
  "RAFTER 2 X 6 @ 12\" OC, SPAN <15'": "RAFTER2X612OCSPAN15.png",
  "RAFTER 2 X 6 @ 16\" OC, SPAN <14'": "RAFTER2X616OCSPAN14.png",
  "RAFTER 2 X 6 @ 24\" OC, SPAN <11'": "RAFTER2X624OCSPAN11.png",
  "RAFTER 2 X 8 @ 12\" OC, SPAN <20'": "RAFTER2X812OCSPAN20.png",
  "RAFTER 2 X 8 @ 16\" OC, SPAN <18'": "RAFTER2X816OCSPAN18.png",
  "RAFTER 2 X 8 @ 24\" OC, SPAN <15'": "RAFTER2X824OCSPAN15.png",
  "RAFTER 2 X 10 @ 12\" OC, SPAN <26'": "RAFTER2X1012OCSPAN26.png",
  "RAFTER 2 X 10 @ 16\" OC, SPAN <22'": "RAFTER2X1016OCSPAN22.png",
  "RAFTER 2 X 10 @ 24\" OC, SPAN <18'": "RAFTER2X1024OCSPAN18.png",
  "RAFTER 2 X 12 @ 12\" OC, SPAN <26'": "RAFTER2X1212OCSPAN26.png",
  "RAFTER 2 X 12 @ 16\" OC, SPAN <26'": "RAFTER2X1216OCSPAN26.png",
  "RAFTER 2 X 12 @ 24\" OC, SPAN <21'": "RAFTER2X1224OCSPAN21.png",
  "RAFTER 4 X 4 @ 24\" OC, SPAN <10'": "RAFTER4X424OCSPAN10.png",
  "RAFTER 4 X 4 @ 32\" OC, SPAN <9'": "RAFTER4X432OCSPAN9.png",
  "RAFTER 4 X 4 @ 48\" OC, SPAN <7'": "RAFTER4X448OCSPAN7.png",
  "RAFTER 4 X 6 @ 24\" OC, SPAN <15'": "RAFTER4X624OCSPAN15.png",
  "RAFTER 4 X 6 @ 32\" OC, SPAN <13'": "RAFTER4X632OCSPAN13.png",
  "RAFTER 4 X 6 @ 48\" OC, SPAN <10'": "RAFTER4X648OCSPAN10.png",
  "TRUSS 2 X 4 @ 12\" OC, SPAN <18'": "TRUSS2X412OCSPAN18.png",
  "TRUSS 2 X 4 @ 16\" OC, SPAN <16'": "TRUSS2X416OCSPAN16.png",
  "TRUSS 2 X 4 @ 24\" OC, SPAN <14'": "TRUSS2X424OCSPAN14.png",
  "TRUSS 2 X 4 @ 12\" OC, SPAN >18' - <27'": "TRUSS2X412OCSPAN18-27.png",
  "TRUSS 2 X 4 @ 16\" OC, SPAN >16' - <24'": "TRUSS2X416OCSPAN16-24.png",
  "TRUSS 2 X 4 @ 24\" OC, SPAN >14' - <21'": "TRUSS2X424OCSPAN14-21.png",
  "TRUSS 2 X 6 @ 12\" OC, SPAN <30'": "TRUSS2X612OCSPAN30.png",
  "TRUSS 2 X 6 @ 16\" OC, SPAN <28'": "TRUSS2X616OCSPAN28.png",
  "TRUSS 2 X 6 @ 24\" OC, SPAN <22'": "TRUSS2X624OCSPAN22.png",
  "TRUSS 2 X 6 @ 12\" OC, SPAN >30' - <45'": "TRUSS2X612OCSPAN30-45.png",
  "TRUSS 2 X 6 @ 16\" OC, SPAN >28' - <42'": "TRUSS2X616OCSPAN28-42.png",
  "TRUSS 2 X 6 @ 24\" OC, SPAN >22' - <33'": "TRUSS2X624OCSPAN22-33.png",
  "TRUSS 2 X 8 @ 12\" OC, SPAN <40'": "TRUSS2X812OCSPAN40.png",
  "TRUSS 2 X 8 @ 16\" OC, SPAN <36'": "TRUSS2X816OCSPAN36.png",
  "TRUSS 2 X 8 @ 24\" OC, SPAN <30'": "TRUSS2X824OCSPAN30.png",
  "TRUSS 2 X 8 @ 12\" OC, SPAN >40' - <60'": "TRUSS2X812OCSPAN40-60.png",
  "TRUSS 2 X 8 @ 16\" OC, SPAN >36' - <54'": "TRUSS2X816OCSPAN36-54.png",
  "TRUSS 2 X 8 @ 24\" OC, SPAN >30' - <45'": "TRUSS2X824OCSPAN30-45.png",
  "TRUSS 2 X 10 @ 12\" OC, SPAN <52'": "TRUSS2X1012OCSPAN52.png",
  "TRUSS 2 X 10 @ 16\" OC, SPAN <44'": "TRUSS2X1016OCSPAN44.png",
  "TRUSS 2 X 10 @ 24\" OC, SPAN <36'": "TRUSS2X1024OCSPAN36.png",
  "TRUSS 2 X 10 @ 12\" OC, SPAN >52' - <78'": "TRUSS2X1012OCSPAN52-78.png",
  "TRUSS 2 X 10 @ 16\" OC, SPAN >44' - <66'": "TRUSS2X1016OCSPAN44-66.png",
  "TRUSS 2 X 10 @ 24\" OC, SPAN >36' - <54'": "TRUSS2X1024OCSPAN36-54.png",
  "TRUSS 2 X 12 @ 12\" OC, SPAN <52'": "TRUSS2X1212OCSPAN52.png",
  "TRUSS 2 X 12 @ 16\" OC, SPAN <52'": "TRUSS2X1216OCSPAN52.png",
  "TRUSS 2 X 12 @ 24\" OC, SPAN <42'": "TRUSS2X1224OCSPAN42.png",
  "TRUSS 2 X 12 @ 12\" OC, SPAN >52' - <78'": "TRUSS2X1212OCSPAN52-78.png",
  "TRUSS 2 X 12 @ 16\" OC, SPAN >52' - <78'": "TRUSS2X1216OCSPAN52-78.png",
  "TRUSS 2 X 12 @ 24\" OC, SPAN >42' - <63'": "TRUSS2X1224OCSPAN42-63.png",
  "TRUSS 4 X 4 @ 24\" OC, SPAN <20'": "TRUSS4X424OCSPAN20.png",
  "TRUSS 4 X 4 @ 32\" OC, SPAN <18'": "TRUSS4X432OCSPAN18.png",
  "TRUSS 4 X 4 @ 48\" OC, SPAN <14'": "TRUSS4X448OCSPAN14.png",
  "TRUSS 4 X 4 @ 24\" OC, SPAN >20' - <30'": "TRUSS4X424OCSPAN20-30.png",
  "TRUSS 4 X 4 @ 32\" OC, SPAN >18' - <27'": "TRUSS4X432OCSPAN18-27.png",
  "TRUSS 4 X 4 @ 48\" OC, SPAN >14' - <21'": "TRUSS4X448OCSPAN14-21.png",
};
