const SolarIconSmall = ({ fill = "#7A7A7A" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
    >
      <g clipPath="url(#clip0_2981_29369)">
        <path
          d="M12.0002 4.53516C7.884 4.53516 4.53516 7.884 4.53516 12.0002C4.53516 16.1165 7.884 19.4653 12.0002 19.4653C16.1165 19.4653 19.4653 16.1165 19.4653 12.0002C19.4653 7.884 16.1165 4.53516 12.0002 4.53516ZM12.0002 18.5278C8.40089 18.5278 5.47266 15.5996 5.47266 12.0002C5.47266 8.40089 8.40089 5.47266 12.0002 5.47266C15.5996 5.47266 18.5278 8.40089 18.5278 12.0002C18.5278 15.5996 15.5996 18.5278 12.0002 18.5278Z"
          fill={fill}
        />
        <path
          d="M12 3.15436C12.1243 3.15436 12.2435 3.10497 12.3315 3.01707C12.4194 2.92916 12.4688 2.80993 12.4688 2.68561V0.46875C12.4688 0.34443 12.4194 0.225201 12.3315 0.137294C12.2435 0.049386 12.1243 0 12 0C11.8757 0 11.7565 0.049386 11.6685 0.137294C11.5806 0.225201 11.5312 0.34443 11.5312 0.46875V2.68561C11.5312 2.80993 11.5806 2.92916 11.6685 3.01707C11.7565 3.10497 11.8757 3.15436 12 3.15436Z"
          fill={fill}
        />
        <path
          d="M12 20.8438C11.8757 20.8438 11.7565 20.8931 11.6685 20.981C11.5806 21.069 11.5312 21.1882 11.5312 21.3125V23.5294C11.5312 23.6537 11.5806 23.7729 11.6685 23.8608C11.7565 23.9487 11.8757 23.9981 12 23.9981C12.1243 23.9981 12.2435 23.9487 12.3315 23.8608C12.4194 23.7729 12.4688 23.6537 12.4688 23.5294V21.3125C12.4688 21.1882 12.4194 21.069 12.3315 20.981C12.2435 20.8931 12.1243 20.8438 12 20.8438Z"
          fill={fill}
        />
        <path
          d="M6.93644 4.16865C6.99861 4.27631 7.10099 4.35488 7.22108 4.38706C7.34116 4.41924 7.46911 4.40239 7.57678 4.34023C7.68445 4.27807 7.76301 4.17569 7.79519 4.0556C7.82737 3.93551 7.81053 3.80756 7.74837 3.6999L6.63991 1.78004C6.60936 1.7263 6.56848 1.67913 6.51962 1.64125C6.47076 1.60338 6.4149 1.57554 6.35524 1.55934C6.29558 1.54315 6.23331 1.53891 6.172 1.54688C6.1107 1.55485 6.05158 1.57486 5.99804 1.60577C5.94451 1.63668 5.89761 1.67788 5.86006 1.72698C5.82251 1.77609 5.79504 1.83214 5.77924 1.89191C5.76344 1.95167 5.75962 2.01397 5.76799 2.07522C5.77637 2.13647 5.79677 2.19546 5.82804 2.24879L6.93644 4.16865Z"
          fill={fill}
        />
        <path
          d="M17.0622 19.832C17.0001 19.7244 16.8977 19.6458 16.7776 19.6136C16.6575 19.5815 16.5296 19.5983 16.4219 19.6605C16.3142 19.7226 16.2357 19.825 16.2035 19.9451C16.1713 20.0652 16.1881 20.1931 16.2503 20.3008L17.3588 22.2207C17.3893 22.2744 17.4302 22.3216 17.479 22.3594C17.5279 22.3973 17.5838 22.4251 17.6434 22.4413C17.7031 22.4575 17.7654 22.4618 17.8267 22.4538C17.888 22.4458 17.9471 22.4258 18.0006 22.3949C18.0542 22.364 18.1011 22.3228 18.1386 22.2737C18.1762 22.2246 18.2036 22.1685 18.2194 22.1088C18.2352 22.049 18.239 21.9867 18.2307 21.9255C18.2223 21.8642 18.2019 21.8052 18.1706 21.7519L17.0622 19.832Z"
          fill={fill}
        />
        <path
          d="M4.16865 6.9384L2.24879 5.82999C2.19546 5.79873 2.13647 5.77832 2.07522 5.76995C2.01397 5.76157 1.95167 5.76539 1.89191 5.7812C1.83214 5.797 1.77609 5.82446 1.72698 5.86201C1.67788 5.89957 1.63668 5.94646 1.60577 5.99999C1.57486 6.05353 1.55485 6.11265 1.54688 6.17396C1.53891 6.23526 1.54315 6.29753 1.55934 6.35719C1.57554 6.41685 1.60338 6.47272 1.64125 6.52157C1.67913 6.57043 1.7263 6.61131 1.78004 6.64187L3.6999 7.75032C3.80756 7.81248 3.93551 7.82932 4.0556 7.79715C4.17569 7.76497 4.27807 7.6864 4.34023 7.57873C4.40239 7.47107 4.41924 7.34312 4.38706 7.22303C4.35488 7.10294 4.27631 7.00056 4.16865 6.9384Z"
          fill={fill}
        />
        <path
          d="M22.2207 17.3588L20.3008 16.2503C20.1931 16.1881 20.0652 16.1713 19.9451 16.2035C19.825 16.2357 19.7226 16.3142 19.6605 16.4219C19.5983 16.5296 19.5815 16.6575 19.6136 16.7776C19.6458 16.8977 19.7244 17.0001 19.832 17.0622L21.7519 18.1706C21.8052 18.2019 21.8642 18.2223 21.9255 18.2307C21.9867 18.239 22.049 18.2352 22.1088 18.2194C22.1685 18.2036 22.2246 18.1762 22.2737 18.1386C22.3228 18.1011 22.364 18.0542 22.3949 18.0006C22.4258 17.9471 22.4458 17.888 22.4538 17.8267C22.4618 17.7654 22.4575 17.7031 22.4413 17.6434C22.4251 17.5838 22.3973 17.5279 22.3594 17.479C22.3216 17.4302 22.2744 17.3893 22.2207 17.3588Z"
          fill={fill}
        />
        <path
          d="M3.15436 12C3.15436 11.8757 3.10497 11.7565 3.01707 11.6685C2.92916 11.5806 2.80993 11.5312 2.68561 11.5312H0.46875C0.34443 11.5312 0.225201 11.5806 0.137294 11.6685C0.049386 11.7565 0 11.8757 0 12C0 12.1243 0.049386 12.2435 0.137294 12.3315C0.225201 12.4194 0.34443 12.4688 0.46875 12.4688H2.68561C2.80993 12.4688 2.92916 12.4194 3.01707 12.3315C3.10497 12.2435 3.15436 12.1243 3.15436 12Z"
          fill={fill}
        />
        <path
          d="M23.5313 11.5312H21.3145C21.1901 11.5312 21.0709 11.5806 20.983 11.6685C20.8951 11.7565 20.8457 11.8757 20.8457 12C20.8457 12.1243 20.8951 12.2435 20.983 12.3315C21.0709 12.4194 21.1901 12.4688 21.3145 12.4688H23.5313C23.6556 12.4688 23.7749 12.4194 23.8628 12.3315C23.9507 12.2435 24.0001 12.1243 24.0001 12C24.0001 11.8757 23.9507 11.7565 23.8628 11.6685C23.7749 11.5806 23.6556 11.5312 23.5313 11.5312Z"
          fill={fill}
        />
        <path
          d="M3.6999 16.2503L1.78004 17.3588C1.7263 17.3893 1.67913 17.4302 1.64125 17.479C1.60338 17.5279 1.57554 17.5838 1.55934 17.6434C1.54315 17.7031 1.53891 17.7654 1.54688 17.8267C1.55485 17.888 1.57486 17.9471 1.60577 18.0006C1.63668 18.0542 1.67788 18.1011 1.72698 18.1386C1.77609 18.1762 1.83214 18.2036 1.89191 18.2194C1.95167 18.2352 2.01397 18.239 2.07522 18.2307C2.13647 18.2223 2.19546 18.2019 2.24879 18.1706L4.16865 17.0622C4.27631 17.0001 4.35488 16.8977 4.38706 16.7776C4.41924 16.6575 4.40239 16.5296 4.34023 16.4219C4.27807 16.3142 4.17569 16.2357 4.0556 16.2035C3.93551 16.1713 3.80756 16.1881 3.6999 16.2503Z"
          fill={fill}
        />
        <path
          d="M20.067 7.81318C20.1492 7.81318 20.2298 7.7915 20.3009 7.75032L22.2208 6.64187C22.2745 6.61131 22.3217 6.57043 22.3596 6.52157C22.3974 6.47272 22.4253 6.41685 22.4415 6.35719C22.4577 6.29753 22.4619 6.23526 22.4539 6.17396C22.446 6.11265 22.426 6.05353 22.395 5.99999C22.3641 5.94646 22.3229 5.89957 22.2738 5.86201C22.2247 5.82446 22.1687 5.797 22.1089 5.7812C22.0491 5.76539 21.9868 5.76157 21.9256 5.76995C21.8644 5.77832 21.8054 5.79873 21.752 5.82999L19.8322 6.9384C19.7427 6.98999 19.6728 7.06968 19.6333 7.16507C19.5938 7.26047 19.5869 7.36624 19.6137 7.46597C19.6405 7.56569 19.6994 7.65378 19.7814 7.71657C19.8634 7.77935 19.9638 7.81331 20.067 7.81318Z"
          fill={fill}
        />
        <path
          d="M7.57676 19.6605C7.4691 19.5983 7.34115 19.5815 7.22107 19.6136C7.10099 19.6458 6.99861 19.7244 6.93644 19.832L5.82804 21.7519C5.79677 21.8052 5.77637 21.8642 5.76799 21.9254C5.75962 21.9867 5.76344 22.049 5.77924 22.1088C5.79504 22.1685 5.82251 22.2246 5.86006 22.2737C5.89761 22.3228 5.94451 22.364 5.99804 22.3949C6.05158 22.4258 6.1107 22.4458 6.172 22.4538C6.23331 22.4618 6.29558 22.4575 6.35524 22.4413C6.4149 22.4251 6.47076 22.3973 6.51962 22.3594C6.56848 22.3215 6.60936 22.2744 6.63991 22.2206L7.74837 20.3008C7.81052 20.1931 7.82736 20.0652 7.79517 19.9451C7.76299 19.825 7.68442 19.7226 7.57676 19.6605Z"
          fill={fill}
        />
        <path
          d="M16.4219 4.34021C16.5296 4.40237 16.6575 4.41921 16.7776 4.38704C16.8977 4.35486 17 4.27631 17.0622 4.16865L18.1706 2.24879C18.2019 2.19546 18.2223 2.13647 18.2307 2.07522C18.239 2.01397 18.2352 1.95167 18.2194 1.89191C18.2036 1.83214 18.1761 1.77609 18.1386 1.72698C18.101 1.67788 18.0541 1.63668 18.0006 1.60577C17.9471 1.57486 17.888 1.55485 17.8267 1.54688C17.7653 1.53891 17.7031 1.54315 17.6434 1.55934C17.5838 1.57554 17.5279 1.60338 17.479 1.64125C17.4302 1.67913 17.3893 1.7263 17.3587 1.78004L16.2503 3.6999C16.1881 3.80756 16.1713 3.93551 16.2035 4.05559C16.2357 4.17568 16.3142 4.27805 16.4219 4.34021Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2981_29369">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SolarIconSmall;
