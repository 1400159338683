const CustomSuffix = ({
  iconVisible = null,
  iconInvisible = null,
  visible = false,
  setVisible = () => {},
}) => {
  return (
    <div
      className="custom-suffix cursor-pointer"
      onClick={() => {
        return setVisible((visible) => !visible);
      }}
    >
      {visible ? iconVisible : iconInvisible}
    </div>
  );
};

export default CustomSuffix;
