import PageTitle from "../../components/pageTitle";
import { useWindowSize } from "../../hooks/useWindowSize";
import ProjectsDesktop from "./ProjectsDesktop";
import ProjectsMobile from "./ProjectsMobile";

const Projects = ({ submitted = false }) => {
  const { isMobile } = useWindowSize();
  if (isMobile === undefined) {
    return null;
  }
  return (
    <div>
      <PageTitle title="Project List Management"/>
      {!isMobile ? (
        <ProjectsDesktop submitted={submitted} />
      ) : (
        <ProjectsMobile submitted={submitted} />
      )}
    </div>
  );
};

export default Projects;
