import { Flex, Form, Radio } from "antd";
import { selectUser } from "../../../redux/slices/userSlice.js";
import { useSelector } from "react-redux";
import ModalFooter from "../../../components/modal/ModalFooter.js";
import CustomForm from "../../../components/form/index.js";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import "../styles.css";

dayjs.extend(weekday);
dayjs.extend(localeData);

const FiltersForm = ({ onFilterFinish, onCancel, initialValues = {}, filterOptions = [] }) => {
  const { role } = useSelector(selectUser);
  const [form] = Form.useForm();
  const statusFilterOptions = Object.keys(filterOptions);
  return (
    <CustomForm
      form={form}
      onFinish={onFilterFinish}
      validateTrigger="onBlur"
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
    >
      <div className="margin-top-xxs margin-left-xl">
        <Form.Item name="status">
          <Radio.Group className="radio-group">
            {statusFilterOptions.map((item) => {
              const label = filterOptions[item].label;
              return (
                <Flex vertical key={item} className="margin-bottom-md">
                  <Radio value={item}>{label}</Radio>
                </Flex>
              );
            })}
          </Radio.Group>
        </Form.Item>
      </div>
      <ModalFooter htmlType="submit" submitTitle="Apply" onCancel={onCancel} />
    </CustomForm>
  );
};

export default FiltersForm;
