import { Form } from "antd";
import uniqBy from "lodash/uniqBy";

const CustomForm = ({
  initialValues = {},
  children,
  onFinish,
  onFailed,
  setErrors = () => {},
  form,
  help = null,
  setUploadErrorField = () => {},
}) => {
  const onBlur = async (e) => {
    const name = e.target.name || e.target.id;
    form
    .validateFields([name])
    .then((res) => {
        if (res[name] || res[name] === "") {
          setErrors((prevErrors) =>
            prevErrors.filter((err) => err.name[0] !== name)
          );
        }
      })
      .catch(({ errorFields }) => {
        setErrors((prevErrors) => {
          return uniqBy([...errorFields, ...prevErrors], "name[0]");
        });
      });
  };

  const onFinishFailed = (params) => {
    const uploadError = setUploadErrorField();
    if (uploadError) {
      setErrors([...params.errorFields, uploadError]);
      return;
    }
    if (onFailed) {
      onFailed(params);
    }
    setErrors(params.errorFields);
  };

  return (
    <Form
      className="full-width form"
      help={help}
      form={form}
      onBlur={onBlur}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateTrigger="onBlur"
      layout="vertical"
      initialValues={{ ...initialValues }}
    >
      {children}
    </Form>
  );
};

export default CustomForm;
