import CustomSuffix from "../components/inputs/CustomSuffix";
import {
  emailValidator,
  confirmPasswordValidator,
  passwordValidator,
  phoneNumberValidator,
  onlyPositiveNumbersValidator,
} from "./formValidators";
import { renderCardMonuths, renderCardYears } from "./utils";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const renderRequired = (message = "The field is required") => {
  return { required: true, message };
};
const required = renderRequired();

export const inputs = {
  fullName: {
    name: "fullName",
    placeholder: "Full Name",
    rules: [required],
  },
  email: {
    name: "email",
    placeholder: "Email",
    normalize: (value) => value.trim(),
    rules: [required, { validator: emailValidator }],
  },
  phoneNumber: {
    name: "phoneNumber",
    placeholder: "Phone",
    rules: [
      required,
      {
        validator: (validator, value) =>
          phoneNumberValidator({ ...validator }, value, true),
      },
    ],
  },
  phoneNumberNotRequired: {
    name: "phoneNumberNotRequired",
    placeholder: "Phone",
  },
  companyName: {
    name: "name",
    placeholder: "Name",
    rules: [required],
  },
  projectName: {
    name: "name",
    placeholder: "Project Name",
    rules: [required],
  },
  address: {
    name: "address",
    placeholder: "Address",
    rules: [required],
  },
  city: {
    name: "city",
    placeholder: "City",
    rules: [required],
  },
  state: {
    name: "state",
    placeholder: "State",
    rules: [required],
  },
  zipCode: {
    name: "zipCode",
    placeholder: "Zip Code",
    rules: [required],
  },
  licenseType: {
    name: "licenseType",
    placeholder: "License Type",
    rules: [required],
  },
  licenseNumber: {
    name: "licenseNumber",
    placeholder: "License Number",
    rules: [required],
  },
  utilityCompany: {
    name: "utilityCompany",
    placeholder: "Utility Company/AHJ",
    rules: [required],
  },
  moduleMakeAndModelNumber: {
    name: "moduleMakeAndModelNumber",
    placeholder: "Module Make and Model Number",
    rules: [required],
  },
  numberOfModules: {
    name: "numberOfModules",
    placeholder: "Number of Modules",
    rules: [required],
  },
  numberOfInverters: {
    name: "numberOfInverters",
    placeholder: "Number of Inverters",
    rules: [required],
  },
  batteryMakeAndModelNumber: {
    name: "batteryMakeAndModelNumber",
    placeholder: "Battery Make and Model Number",
    rules: [required],
  },
  batteryInterconnection: {
    name: "batteryInterconnection",
    placeholder: "Battery Interconnection",
  },
  size: {
    name: "size",
    placeholder: "Size",
    rules: [required],
  },
  rackingMakeAndModel: {
    name: "rackingMakeAndModel",
    placeholder: "Racking Make And Model",
  },
  attachmentMakeAndModel: {
    name: "attachmentMakeAndModel",
    placeholder: "Attachment Make And Model",
  },
  attachmentSpacing: {
    name: "attachmentSpacing",
    placeholder: "Attachment Spacing",
  },
  roofPitch: {
    name: "roofPitch",
    placeholder: "Roof Pitch",
  },
  clientAttachmentLink: {
    name: "clientAttachmentLink",
    placeholder: "Link",
    rules: [required],
  },
  projectPrice: {
    name: "price",
    placeholder: "Price",
    rules: [required, { validator: onlyPositiveNumbersValidator }],
  },
  price: {
    name: "price",
    placeholder: "Price",
    rules: [{ validator: onlyPositiveNumbersValidator }],
  },
  cardholderName: {
    name: "cardholderName",
    placeholder: "Cardholder Name",
    rules: [renderRequired("Cardholder's name is required")],
  },
  cardNumber: {
    name: "cardNumber",
    placeholder: "Card Number",
    rules: [renderRequired("Card number is required")],
  },
  cvv: {
    name: "cvv",
    placeholder: "CVV",
    rules: [renderRequired("Security code (CVV) is required")],
  },
  password: {
    name: "password",
    placeholder: "Password",
    rules: [required, { validator: passwordValidator }],
    type: (visible) => (!visible ? "password" : "text"),
    renderSuffix: ({ visible, setVisible }) => (
      <CustomSuffix
        visible={visible}
        setVisible={setVisible}
        iconVisible={<EyeOutlined />}
        iconInvisible={<EyeInvisibleOutlined />}
      />
    ),
  },
  currentPassword: {
    name: "currentPassword",
    placeholder: "Current Password",
    rules: [required, { validator: passwordValidator }],
    type: (visible) => (!visible ? "password" : "text"),
    renderSuffix: ({ visible, setVisible }) => (
      <CustomSuffix
        visible={visible}
        setVisible={setVisible}
        iconVisible={<EyeOutlined />}
        iconInvisible={<EyeInvisibleOutlined />}
      />
    ),
  },
  confirmPassword: {
    name: "confirmPassword",
    placeholder: "Confirm Password",
    dependencies: ["password"],
    rules: [required, confirmPasswordValidator],
    type: (visible) => (!visible ? "password" : "text"),
    renderSuffix: ({ visible, setVisible }) => (
      <CustomSuffix
        visible={visible}
        setVisible={setVisible}
        iconVisible={<EyeOutlined />}
        iconInvisible={<EyeInvisibleOutlined />}
      />
    ),
  },
  newPassword: {
    name: "newPassword",
    placeholder: "New Password",
    rules: [required, { validator: passwordValidator }],
    type: (visible) => (!visible ? "password" : "text"),
    renderSuffix: ({ visible, setVisible }) => (
      <CustomSuffix
        visible={visible}
        setVisible={setVisible}
        iconVisible={<EyeOutlined />}
        iconInvisible={<EyeInvisibleOutlined />}
      />
    ),
  },
  confirmNewPassword: {
    name: "confirmNewPassword",
    placeholder: "Confirm New Password",
    dependencies: ["newPassword"],
    rules: [required, confirmPasswordValidator],
    type: (visible) => (!visible ? "password" : "text"),
    renderSuffix: ({ visible, setVisible }) => (
      <CustomSuffix
        visible={visible}
        setVisible={setVisible}
        iconVisible={<EyeOutlined />}
        iconInvisible={<EyeInvisibleOutlined />}
      />
    ),
  },
  note: {
    name: "note",
    placeholder: "Notes",
  },
  notes: {
    name: "notes",
    placeholder: "Notes",
  },
  electricalNote: {
    name: "note",
    placeholder: "Notes",
  },
  structuralNote: {
    name: "note",
    placeholder: "Notes",
  },
};

export const selectInputs = {
  batteryStorage: {
    name: "batteryStorage",
    placeholder: "Battery Storage",
    defaultOptions: ["Yes", "No"],
    rules: [required],
  },
  inverterMakeAndModelNumber: {
    name: "inverterMakeAndModelNumber",
    placeholder: "Inverter Make And Model Number",
    rules: [required],
  },
  interconnectionType: {
    name: "interconnectionType",
    placeholder: "Interconnection Type",
    rules: [required],
  },
  conduitRun: {
    name: "conduitRun",
    placeholder: "Conduit Run",
    defaultOptions: ["Through Attic Space", "Above Roof"],
  },
  conduitType: {
    name: "conduitType",
    placeholder: "Conduit Type",
  },
  optimizer: {
    name: "optimizer",
    placeholder: "Optimizer",
  },
  optimizerRequired: {
    name: "optimizer",
    placeholder: "Optimizer",
    rules: [required],
  },
  indicateMspLocation: {
    name: "indicateMspLocation",
    placeholder: "Indicate M.S.P. Location",
    defaultOptions: [
      "South",
      "East",
      "West",
      "North",
      "South-East",
      "South-West",
      "North-East",
      "North-West",
    ],
  },
  mainElectricalPanelUpgrade: {
    name: "mainElectricalPanelUpgrade",
    placeholder: "Main Electrical Panel Upgrade",
    defaultOptions: ["Yes", "No"],
  },
  mspBusRating: {
    name: "mspBusRating",
    placeholder: "M.S.P. Bus Rating",
    rules: [required],
  },
  mainBreakerRating: {
    name: "mainBreakerRating",
    placeholder: "Main Breaker Rating",
    rules: [required],
  },
  mspBusBarFeedLocation: {
    name: "mspBusBarFeedLocation",
    placeholder: "M.S.P. Bus Bar Feed Location",
    defaultOptions: ["Top Feed", "Center Feed", "Bottom Feed", "N/A"],
  },
  existingBusRating: {
    name: "existingBusRating",
    placeholder: "Bus Rating",
  },
  existingBreakerRating: {
    name: "existingBreakerRating",
    placeholder: "Breaker Rating",
  },
  newBusRating: {
    name: "newBusRating",
    placeholder: "Bus Rating",
  },
  newBreakerRating: {
    name: "newBreakerRating",
    placeholder: "Breaker Rating",
  },
  acDisconnect: {
    name: "acDisconnect",
    placeholder: "AC Disconnect",
    defaultOptions: ["Yes", "No"],
  },
  pvProductionMeter: {
    name: "pvProductionMeter",
    placeholder: "PV Production Meter",
    defaultOptions: ["Yes", "No"],
  },
  roofSheetingType: {
    name: "roofSheetingType",
    placeholder: "Roof Sheeting Type",
  },
  roofFraming: {
    name: "roofFraming",
    placeholder: "Roof Framing",
    rules: [required],
  },
  expYear: {
    name: "expYear",
    placeholder: "YY",
    rules: [renderRequired("Expiration year is required")],
    defaultOptions: renderCardYears(),
    // initialValue: renderCardYears()[1],
  },
  expMonth: {
    name: "expMonth",
    placeholder: "MM",
    rules: [renderRequired("Expiration month is required")],
    defaultOptions: renderCardMonuths(),
    // initialValue: renderCardMonuths()[0],
  },
  email: {
    name: "email",
    placeholder: "",
    label: "To",
    rules: [required],
    defaultOptions: [
      "ani.m@engineerinc.io",
      "inessa.m@engineerinc.io",
      "elida.b@engineerinc.io",
      "meri.m@engineerinc.io",
      "mariam.g@engineerinc.io",
      "hrant.b@engineerinc.io",
      "elen.s@engineerinc.io",
      "nanar.m@engineerinc.io",
      "felix.s@engineerinc.io",
      "vahe.v@engineerinc.io",
      "gevorg.kh@engineerinc.io",
      "habet.y@engineerinc.io",
      "naira.m@engineerinc.io",
      "supun.j@engineerinc.io",
      "sl@engineerinc.io",
      "new@engineerinc.io",
    ],
  },
};
