import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  company: {}
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const { user } = action.payload;
      state.user = user
    },
    setCompany(state, action) {
      const { company } = action.payload;
      state.company = company
    },
  },
});

export const { setUser } = userSlice.actions;
export const { setCompany } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectCompany = (state) => state.user.company;

export default userSlice.reducer;
