import { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { findOpenKeys } from "../../utils/menuItems";
import LogoutMenuItem from "../logoutMenuItem";
import CustomMenu from ".";
import "./styles.css";

const VerticalMenu = ({ menuItems }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath =
    location.pathname !== "/" ? location.pathname : "/projects";

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const onItemClick = ({ key }) => {
    navigate(key);
    setOpen(false);
    return;
  };
  const defaultOpenKeys = findOpenKeys(menuItems, currentPath);

  return (
    <div>
      <div onClick={showDrawer}>
        <MenuOutlined className="menu-icon" />
      </div>
      <Drawer
        className="menu-drawer"
        open={open}
        width={500}
        height="100%"
        placement={"bottom"}
        onClose={onClose}
        destroyOnClose
        footer={<LogoutMenuItem />}
      >
        <CustomMenu
          defaultSelectedKeys={[currentPath]}
          defaultOpenKeys={defaultOpenKeys}
          items={menuItems}
          onMenuItemClick={onItemClick}
        />
      </Drawer>
    </div>
  );
};

export default VerticalMenu;
