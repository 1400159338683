import { Card } from "antd";
import "./styles.css";

const CustomCard = ({
  description,
  renderActions = null,
  customClass = "",
}) => {
  return (
    <Card
      className={`card-container ${customClass}`}
      bordered={false}
      actions={renderActions}
    >
      <Card.Meta description={description} />
    </Card>
  );
};

export default CustomCard;
