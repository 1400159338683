import { CloseOutlined } from "@ant-design/icons";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import "./styles.css";

const MultipleTagsEmailInput = ({ emails, setEmails }) => {
  return (
    <div className="multi-email-container">
      <ReactMultiEmail
        className="multi-email"
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        autoFocus={true}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                <CloseOutlined />
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default MultipleTagsEmailInput;
