import { FloatButton, List } from "antd";
import { UpOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import NewInfoCard from "../infoCard/NewInfoCard";
import "./styles.css";

const InfiniteScrollCards = ({
  data = {},
  renderActions = () => {},
  fetchMoreData = () => {},
  fields = {},
  loading = false,
}) => {
  return (
    <div id="scrollableDiv" className="scrollable-container">
      <div>
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={data.items.length}
          next={fetchMoreData}
          hasMore={data.items.length < data.count}
          scrollThreshold={0.9}
        >
          <List
            loading={loading}
            dataSource={data.items}
            renderItem={(item) => (
              <div id={item.id} className="scrollable-list-item-wrapper">
                <List.Item
                  key={item.id}
                  className="scrollable-list-item-content-wrapper"
                >
                  <NewInfoCard
                    item={item}
                    fields={fields}
                    renderActions={renderActions(item)}
                  />
                </List.Item>
              </div>
            )}
          />
        </InfiniteScroll>
        <FloatButton.BackTop
          type="primary"
          icon={<UpOutlined />}
          target={() => document.getElementById("scrollableDiv")}
        />
      </div>
    </div>
  );
};

export default InfiniteScrollCards;
