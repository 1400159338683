import { Space } from "antd";
import { Link } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import ByGmail from "./components/ByGmail";
import LoginActions from "./components/LoginActions";

const Login = () => {
  return (
    <div className="wrapper">
      <div className="auth-forms-section-content">
        <LoginForm />
        <LoginActions />
        <ByGmail />
        <div className="other-mode margin-top-sm">
          <Space>
            <span>Don’t have an account?</span>
            <Link to="/sign-up">
              <p className="is-link text-sm">Sign Up</p>
            </Link>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default Login;
