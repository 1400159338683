import { getContractors } from "../services/contractor";
import {
  getDraftByAdmin,
  getDraftByContractor,
  getDraftsByAdmin,
  getDraftsByContractor,
} from "../services/drafts";
import {
  getPaymentsGeneralInfoByAdmin,
  getPaymentsGeneralInfoByContractor,
  getTransactionsByAdmin,
  getTransactionsByContractor,
} from "../services/payments";
import {
  getRevisionByAdmin,
  getRevisionByContractor,
  getRevisionsListByAdmin,
  getRevisionsListByContractor,
} from "../services/revisions";

export const apiByRole = {
  admin: {
    contractors: {
      getContractors
    },
    projects: {
      getDrafts: getDraftsByAdmin,
      getDraft: getDraftByAdmin,
    },
    revisions: {
      getRevisions: getRevisionsListByAdmin,
      getRevision: getRevisionByAdmin,
    },
    transactions: {
      getPayments: getTransactionsByAdmin,
      getGeneralInfo: getPaymentsGeneralInfoByAdmin,
    },
  },
  contractor: {
    projects: {
      getDrafts: getDraftsByContractor,
      getDraft: getDraftByContractor,
    },
    revisions: {
      getRevisions: getRevisionsListByContractor,
      getRevision: getRevisionByContractor,
    },
    transactions: {
      getPayments: getTransactionsByContractor,
      getGeneralInfo: getPaymentsGeneralInfoByContractor,
    },
  },
};
