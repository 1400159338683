import { Link } from "react-router-dom";
import { Typography } from "antd";

const Text = ({
  text = "",
  src = "",
  isLink = false,
  ellipsis = false,
  minWidth = "unset",
  onClick = () => {},
}) => {
  return (
    <Typography.Text ellipsis={ellipsis}>
      {isLink ? (
        <Link onClick={onClick} to={src}>
          {text}
        </Link>
      ) : (
        <p style={{ minWidth }}>{text}</p>
      )}
    </Typography.Text>
  );
};

export default Text;
