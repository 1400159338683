import { memo, useEffect, useState } from "react";
import {
  deactivateContractorByAdmin,
  getContractors,
  getContractorsTotalCount,
  restoreContractorByAdmin,
} from "../../services/contractor";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  UserDeleteOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { message } from "../../utils/utils";
import Modal from "../../components/modal";
import ModalFooter from "../../components/modal/ModalFooter";
import ModalContent from "../../components/modal/ModalContent";
import SearchInput from "../../components/inputs/SearchInput";
import GeneralInfo from "../../components/generalInfo";
import CustomTable from "../../components/table";
import "./styles.css";

const ContractorsDesktop = ({ deactivated }) => {
  const [contractors, setContractors] = useState({ items: [], count: 0 });
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [selectedContractorId, setSelectedContractorId] = useState("");
  const [generalInfo, setGeneralInfo] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      isSmall:true,
      render: ({ fullName, profileId }) => (
        <Link to={`/contractors/${profileId}`}>{fullName}</Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      isSmall:true,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      isSmall:true,
    },
    {
      title: "Spent",
      dataIndex: "totalCosts",
      key: "totalCosts",
      isSmall: true,
    },
    {
      title: !deactivated ? "Deactivate" : "Restore",
      key: "action",
      isSmall: true,
      render: ({ profileId }) => {
        return !deactivated ? (
          <UserDeleteOutlined
            className="text-error text-xl"
            onClick={() => {
              setSelectedContractorId(profileId);
              return setDeactivateModalOpen(true);
            }}
          />
        ) : (
          <UserAddOutlined
            className="text-success text-xl"
            onClick={() => {
              setSelectedContractorId(profileId);
              return setDeactivateModalOpen(true);
            }}
          />
        );
      },
    },
  ];

  const fetchData = () => {
    setLoading(true);
    getContractors({
      offset: (currentPage - 1) * 25,
      limit: 25,
      searchQuery,
      deactivated,
    })
      .then(setContractors)
      .catch((error) =>
        console.error("GET-ActiveContractors - CATCH --- ERROR", error)
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!currentPage) {
      return;
    }
    if (!!searchQuery) {
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchData();
    }
  }, [currentPage, searchQuery, deactivated, setContractors]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get("page"), 10) || 1;
    setCurrentPage(page);
    const query = searchParams.get("search") || "";
    setSearchQuery(query);
  }, [location.search]);

  useEffect(() => {
    getContractorsTotalCount()
      .then(setGeneralInfo)
      .catch((error) => console.error(error));
  }, [contractors]);

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("search", value);
    searchParams.set("page", "1");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleDeactivateOrRestore = () => {
    const fetchData = !deactivated
      ? deactivateContractorByAdmin(selectedContractorId)
      : restoreContractorByAdmin(selectedContractorId);
    fetchData
      .then(() => {
        setContractors({
          count: contractors.count,
          items: contractors.items.filter(
            (item) => item.profileId !== selectedContractorId
          ),
        });
        message({
          type: "success",
          message: `Contractor has been successfully ${
            !deactivated ? "deactivated" : "activated"
          }`,
        });
      })
      .catch((error) => {
        message({
          type: "error",
          message: "Something went wrong",
        });
        console.error(
          "Contractors List Deactivate - ERROR ----------- ",
          error
        );
      })
      .finally(() => {
        setDeactivateModalOpen(false);
        setSelectedContractorId("");
      });
  };
const renderGeneralInfo = [
  {
    title: "Total",
    value: generalInfo.total,
    icon: UserOutlined,
  },
  {
    title: "Active",
    value: generalInfo.active,
    icon: UserAddOutlined,
  },
  {
    title: "Deactivated",
    value: generalInfo.deactivated,
    icon: UserDeleteOutlined,
  },
]

  return (
    <div>
      <GeneralInfo items={renderGeneralInfo} />
      <div className="margin-top-xl margin-bottom-md">
        <SearchInput
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <CustomTable
        columns={columns}
        data={contractors}
        loading={loading}
        handlePaginationChange={handlePaginationChange}
        currentPage={currentPage}
        customClass="scrollable-container-contractors"
      />
      <Modal
        open={deactivateModalOpen}
        footer={null}
        content={
          <>
            <ModalContent
              text={
                !deactivated
                  ? "Are you sure you want to deactivate this account?"
                  : "Are you sure you want to restore this account?"
              }
            />
            <ModalFooter
              submitTitle={!deactivated ? "Deactivate" : "Restore"}
              onSubmit={handleDeactivateOrRestore}
              onCancel={() => setDeactivateModalOpen(false)}
              type="default"
              danger
            />
          </>
        }
      />
    </div>
  );
};

export default memo(ContractorsDesktop);
