import { useEffect, useState } from "react";
import { Flex, Steps, Form } from "antd";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
  createDraft,
  deleteAttachmentsByDraftId,
  deleteDraftAttachment,
  getDraftOptions,
  submitDraft,
  updateDraft,
  uploadDraftAttachment,
} from "../../services/drafts";
import { renderSolarDraftSteps } from "../../utils/drafts";
import { message, removePrefix } from "../../utils/utils";
import CustomButton from "../button";
import CustomForm from "../form";
import isEmpty from "lodash/isEmpty";

const stepsTitles = {
  1: "Project Information",
  2: "Solar Engineering Draft",
  3: "Electrical Engineering Draft",
  4: "Structural Engineering Draft",
};

const allPossibleTypes = ["solar", "electrical", "structural"];

const SolarRequest = ({
  selectedTypes,
  selectedDraft = {},
  onCancel,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const [draft, setDraft] = useState(selectedDraft);
  const [current, setCurrent] = useState(selectedDraft.step || 0);
  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  const { isMobile } = useWindowSize();

  const uploadRequest = (formData) => {
    const upload = uploadDraftAttachment(draft.id, formData);
    return upload;
  };

  const onDeleteAttachement = (file) => {
    const deleteFile = deleteDraftAttachment(draft.id, file.dbName);
    return deleteFile;
  };
  const onDeleteAllAttachements = () => {
    const deleteFile = deleteAttachmentsByDraftId(draft.id);
    return deleteFile;
  };
  const steps = renderSolarDraftSteps(
    current,
    isMobile,
    options,
    uploadRequest,
    onDeleteAttachement,
    onDeleteAllAttachements,
    draft.clientAttachments,
    errors,
    draft
  );

  const step = () => {
    if (current === 0) {
      return "project";
    }
    if (current === 1) {
      return "solar";
    }
    if (current === 2) {
      return "electrical";
    }
    return "structural";
  };

  useEffect(() => {
    if (!isEmpty(selectedDraft)) {
      return () => {
        localStorage.removeItem("solar-request-selected-battery");
        localStorage.removeItem("solar-request-selected-iverter");
        localStorage.removeItem("solar-request-selected-acDisconnect");
        localStorage.removeItem("solar-request-roof-type");
      };
    }
  }, [selectedDraft]);

  useEffect(() => {
    if (current !== 0) {
      setLoading(true);
      getDraftOptions(step())
        .then(setOptions)
        .catch((error) => {
          console.error({ error });
        })
        .finally(() => setLoading(false));
    }
  }, [current]);

  const onNext = (values) => {
    setLoading(true);
    const draftTypes = allPossibleTypes.reduce((result, type) => {
      result[type] = selectedTypes.includes(type);
      return result;
    }, {});
    Object.keys(values).forEach((key) => !values[key] && delete values[key]);
    if (current === 0) {
      values.draftTypes = draftTypes;
      values.phone = values.phoneNumberNotRequired;
      values.name = values.projectName;
    }
    if (current === 1) {
      values.batteryStorage = !values.batteryStorage
        ? undefined
        : values.batteryStorage === "Yes"
        ? true
        : false;
      values.optimizer = values.optimizerRequired
        ? values.optimizerRequired
        : values.optimizer;
      delete values.optimizerRequired;
    }
    if (current === 2) {
      values.pvProductionMeter =
        values.pvProductionMeter === "Yes" ? true : false;
      values.acDisconnect = !values.acDisconnect
        ? undefined
        : values.acDisconnect === "Yes"
        ? true
        : false;
      values.mainElectricalPanelUpgrade =
        values.mainElectricalPanelUpgrade === "Yes" ? true : false;
      values.subpanelNew = {
        busRating: values.newBusRating,
        breakerRating: values.newBreakerRating,
      };
      values.subpanelExisting = {
        busRating: values.existingBusRating,
        breakerRating: values.existingBreakerRating,
      };
      values.note = values.electricalNote;
      delete values.newBusRating;
      delete values.newBreakerRating;
      delete values.existingBusRating;
      delete values.existingBreakerRating;
      delete values.electricalNote;
    }
    if (current === 3) {
      values.note = values.structuralNote;
      delete values.structuralNote;
    }
    const fetchRequest = draft.id
      ? updateDraft(values, draft.id, step())
      : createDraft(values);
    fetchRequest
      .then((response) => {
        setDraft({
          ...response,
          clientAttachments: response.clientAttachments.map((item) => {
            return { name: removePrefix(item.fileName), dbName: item.fileName };
          }),
        });
        if (current === 3) {
          submitDraft(draft.id)
            .then(() => {
              onSubmit();
              return onCancel();
            })
            .catch((error) => {
              message({
                type: "error",
                message: "Something went wrong",
              });
              console.error("ERROR draft-SUBMIT --- ", error);
            });
          return;
        }
        setCurrent(current + 1);
      })
      .catch((error) => {
        message({
          type: "error",
          message: "Something went wrong",
        });
        console.error("ERROR draft --- ", error);
      })
      .finally(() => setLoading(false));
  };
  const onBack = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <div className="solar-form-container">
        <Steps
          className="solar-steps"
          current={current}
          items={steps}
          labelPlacement="vertical"
          responsive={false}
          size="small"
        />
        <p className="text-center margin-xl text-semibold text-primary">
          {stepsTitles[current + 1]}
        </p>
        <CustomForm
          form={form}
          className="steps-form"
          setErrors={setErrors}
          initialValues={{ ...draft }}
          onFinish={onNext}
        >
          <div className="form-content">
            <div>
              {steps[current].content}
              <Flex
                gap={12}
                justify="space-between"
                align={!isMobile && "center"}
                vertical={isMobile}
              >
                <Flex gap={24} className="buttons-container">
                  {current > 0 && (
                    <CustomButton
                      type="default"
                      customClass="yellow-border"
                      buttonTitle={current > 0 ? "Back" : "Cancel"}
                      onClick={() => onBack()}
                    />
                  )}
                  <CustomButton
                    type="primary"
                    buttonTitle={
                      current === steps.length - 1 ? "Submit" : "Next"
                    }
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  />
                </Flex>
                <CustomButton
                  customClass="text-gray padding-none"
                  type="text"
                  buttonTitle="Cancel"
                  onClick={onCancel}
                />
              </Flex>
            </div>
          </div>
        </CustomForm>
      </div>
    </>
  );
};

export default SolarRequest;
