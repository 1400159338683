import { useState } from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../../redux/slices/userSlice.js";
import {
  selectContractorProfile,
  setContractorProfile,
} from "../../../redux/slices/contractorSlice.js";
import { updateProfile } from "../../../services/user.js";
import { updateContractorProfileById } from "../../../services/contractor.js";
import { message } from "../../../utils/utils.js";
import ModalFooter from "../../../components/modal/ModalFooter.js";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput.js";
import CustomForm from "../../../components/form/index.js";
import isEmpty from "lodash/isEmpty";
import "../styles.css";

const ProfileForm = ({ onCancel = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  const currentUser = useSelector(selectUser);
  const contractorProfile = useSelector(selectContractorProfile);
  const user = !isEmpty(contractorProfile) ? contractorProfile : currentUser;
  const dispatch = useDispatch();

  const onFinish = (values) => {
    setLoading(true);

    const newData = {
      ...user,
      fullName: values.fullName,
      phoneNumber: values.phoneNumber,
    };

    const updatePromise = !isEmpty(contractorProfile)
      ? updateContractorProfileById(values, user.id)
      : updateProfile(values);

    updatePromise
      .then(() =>
        message({
          message: "Profile has been successfully updated",
          type: "success",
        })
      )
      .then(() => {
        if (isEmpty(contractorProfile)) {
          dispatch(
            setUser({
              user: {
                ...user,
                fullName: values.fullName,
                phoneNumber: values.phoneNumber,
              },
            })
          );
        } else {
          dispatch(setContractorProfile({ ...newData }));
        }

        onCancel();
      })
      .catch((error) => {
        message({
          message: "Something went wrong",
          type: "error",
        });
        console.error("ProfileForm - ERROR ----------- ", error);
      })
      .finally(() => setLoading(false));
  };

  function handleCancel() {
    onCancel();
  }
  return (
    <>
      <div className="forms-container margin-top-md">
        <CustomForm
          form={form}
          onFinish={onFinish}
          initialValues={{ ...user }}
          setErrors={setErrors}
        >
          <FloatingLabelInput
            name="fullName"
            initialValue={user.fullName}
            errors={errors}
          />
          <FloatingLabelInput
            name="phoneNumber"
            initialValue={user.phoneNumber}
            errors={errors}
          />
          <ModalFooter
            htmlType="submit"
            submitTitle="Update"
            onCancel={handleCancel}
            loading={loading}
            disabled={loading}
          />
        </CustomForm>
      </div>
    </>
  );
};

export default ProfileForm;
