import { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProfile } from "../services/user.js";
import { setUser } from "../redux/slices/userSlice.js";
import Loading from "../components/loading";

const PrivateRoutes = () => {
  const [loading, setLoading] = useState(true);
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  localStorage.setItem("last-pathname", pathname);

  useEffect(() => {
    const fetchProfile = () => {
      getProfile()
        .then((response) => {
          dispatch(setUser({ user: response }));
          return;
        })
        .catch((error) =>
          console.error("privateRoutes - ERROR ----------- ", error)
        )
        .finally(() => setLoading(false));
    };

    fetchProfile();
  }, [dispatch]);

  if (loading) {
    return (
      <div className="page-loading-container">
        <Loading />
      </div>
    );
  }
  return accessToken ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default PrivateRoutes;
