import { Link, Outlet } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

const ForgotPassword = () => {
  return (
    <div className="wrapper">
      <div className="auth-forms-section-content">
        <Link to="/sign-in">
          <p className="is-link text-sm margin-bottom-xxxl display-inline">
            <LeftOutlined /> Back to Sign In
          </p>
        </Link>
        <Outlet />
      </div>
    </div>
  );
};

export default ForgotPassword;
