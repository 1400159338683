import moment from "moment";
import { downloadFile } from "../services/downloadFile";
import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const statuses = {
  admin: {
    project: {
      new: { label: "New", color: "#531dab", listColor: "purple" },
      in_process: {
        label: "In Process",
        color: "#d46b08",
        listColor: "orange",
      },
      completed_project: {
        label: "Need Payment",
        color: "#1d39c4",
        listColor: "geekblue",
      },
      ready_to_download: {
        label: "Complete",
        color: "#389e0d",
        listColor: "green",
      },
    },
    revision: {
      new: { label: "New", color: "#531dab", listColor: "purple" },
      in_process: {
        label: "In Process",
        color: "#d46b08",
        listColor: "orange",
      },
      need_payment: {
        label: "Need Payment",
        color: "#1d39c4",
        listColor: "geekblue",
      },
      complete: { label: "Complete", color: "#389e0d", listColor: "green" },
    },
  },
  contractor: {
    project: {
      in_process: {
        label: "In Process",
        color: "#d46b08",
        listColor: "orange",
      },
      completed_project: {
        label: "Need Payment",
        color: "#1d39c4",
        listColor: "geekblue",
      },
      ready_to_download: {
        label: "Download",
        color: "#389e0d",
        listColor: "green",
      },
    },
    revision: {
      in_process: {
        label: "In Process",
        color: "#d46b08",
        listColor: "orange",
      },
      need_payment: {
        label: "Need Payment",
        color: "#1d39c4",
        listColor: "geekblue",
      },
      complete: { label: "Complete", color: "#389e0d", listColor: "green" },
    },
  },
};

export const removePrefix = (str) => {
  const parts = str.split("-");
  return parts.slice(1).join("-");
};

export const detailsHeaderButtonProps = (
  isAmdin,
  onPriceClick,
  onProccedToPaymentClick
) => {
  // need refactor
  return {
    new: {
      buttonTitle: "Send Drawing",
      onClick: onPriceClick,
      disabled: false,
      invisible: !isAmdin,
    },
    in_process: {
      buttonTitle: "Send Drawing",
      onClick: onPriceClick,
      disabled: false,
      invisible: !isAmdin,
    },
    completed_project: {
      buttonTitle: isAmdin ? "Send Drawing" : "Pay",
      onClick: isAmdin ? onPriceClick : onProccedToPaymentClick,
      invisible: isAmdin && true,
    },
    ready_to_download: {
      buttonTitle: "Send Drawing",
      onClick: onPriceClick,
      invisible: true,
    },
    need_payment: {
      buttonTitle: "Pay",
      onClick: onProccedToPaymentClick,
      invisible: isAmdin ? true : false,
    },
    complete: {
      invisible: true,
    },
  };
};

export const renderCardYears = () => {
  const currentYear = moment().year();
  const yearsArray = Array.from(
    { length: 21 },
    (_, index) => `${currentYear + index}`
  );
  return yearsArray;
};

export const renderCardMonuths = () => {
  const monthsArray = Array.from({ length: 12 }, (_, index) =>
    `${index + 1}`.padStart(2, "0")
  );
  return monthsArray;
};

export const creditCardImage = {
  Visa: require("../components/images/visa.png"),
  MasterCard: require("../components/images/mastercard.png"),
  Discover: require("../components/images/discover.png"),
  "American Express": require("../components/images/american-express.png"),
};

export const handleDownloadWithBlob = async ({ url = "", fileName = "" }) => {
  return downloadFile(url)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Failed to download file: ${response.status}`);
      }
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "download.pdf";
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    })
    .catch((error) => console.error("handleDownloadWithBlob - error", error));
};

export const message = ({ message = "", type = "success" }) => {
  notification.open({
    type,
    message,
    duration: 2.5,
    closeIcon: false,
    icon:
      type === "success" ? (
        <CheckCircleOutlined className="text-success" />
      ) : (
        <CloseCircleOutlined className="text-error" />
      ),
  });
};

export const getValueFromBoolean = (value) => {
  const val = value !== undefined ? (value ? "Yes" : "No") : "";
  return val;
};
