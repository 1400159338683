import { useState } from "react";
import { Flex } from "antd";
import CustomButton from "../../../components/button";
import CustomCard from "../../../components/card";
import CustomDrawer from "../../../components/drawer";
import PasswordForm from "./PasswordForm";

const PasswordManagement = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <CustomCard
        customClass="profile-password-card padding-xl"
        description={
          <Flex
            vertical
            className="card-description text-center"
            align="center"
            justify="center"
            gap={24}
          >
            <p className="text-lg text-black">Update your password </p>
            <p>
              To update your password, please enter your current password for
              verification
            </p>
            <CustomButton
              buttonTitle="Set New Password"
              onClick={() => setOpen(true)}
            />
          </Flex>
        }
      />
      <CustomDrawer
        open={open}
        onCancel={() => setOpen(false)}
        title="Update Password"
        content={<PasswordForm onCancel={() => setOpen(false)} />}
      />
    </>
  );
};

export default PasswordManagement;
