import { Divider } from "antd";
import { roofTypes } from "./roofTypes";
import ProjectInformationIcon from "../components/icons/ProjectInformationIcon";
import ProjectInformationIconSmall from "../components/icons/ProjectInformationIconSmall";
import SolarIcon from "../components/icons/SolarIcon";
import SolarIconSmall from "../components/icons/SolarIconSmall";
import ElectricalIcon from "../components/icons/ElectricalIcon";
import ElectricalIconSmall from "../components/icons/ElectricalIconSmall";
import StructuralIcon from "../components/icons/StructuralIcon";
import StructuralIconSmall from "../components/icons/StructuralIconSmall";
import ProjectInformationForm from "../components/createDraftForm/ProjectInformationForm";
import SolarForm from "../components/createDraftForm/SolarForm";
import ElectricalForm from "../components/createDraftForm/ElectricalForm";
import StructuralForm from "../components/createDraftForm/StructuralForm";
import DetailsStep from "../pages/projects/components/DetailsStep";
import Attachments from "../components/attachments";
import NewInfoField from "../components/infoField/NewInfoField";
import CustomImage from "../components/image";

const draftTypes = {
  solar: "Solar",
  electrical: "Electrical",
  structural: "Structural",
};

export const compactFields = {
  name: {
    label: "Project",
  },
  address: {
    label: "Address",
  },
  city: {
    label: "City",
  },
  state: {
    label: "State",
  },
  zipCode: {
    label: "Zip Code",
  },
  note: {
    label: "Note",
  },
  clientAttachments: {
    render: ({ id, clientAttachments }, { onDownloadClick, onDownloadAll }) => {
      if (!clientAttachments.length) {
        return null;
      }
      return (
        <div key={id}>
          <Divider />
          <Attachments
            id={id}
            title="Attached Files"
            files={clientAttachments}
            onDownloadClick={onDownloadClick}
            onDownloadAll={onDownloadAll}
          />
        </div>
      );
    },
  },
  clientAttachmentLink: {
    label: "Link",
  },
};

const projectInformationFields = {
  name: {
    label: "Project",
  },
  address: {
    label: "Address",
  },
  city: {
    label: "City",
  },
  state: {
    label: "State",
  },
  zipCode: {
    label: "Zip Code",
  },
  utilityCompany: {
    label: "Utility Company/AJH",
  },
  phoneNumber: {
    label: "Phone",
  },
};

const solarRequestFields = {
  moduleMakeAndModelNumber: {
    label: "Module Make and Model Number",
  },
  numberOfModules: {
    label: "Number of Modules",
  },
  inverterMakeAndModelNumber: {
    label: "Inverter Make and Model Number",
  },
  numberOfInverters: {
    label: "Number of Inverters",
  },
  optimizer: {
    label: "Optimizer",
  },
  batteryStorage: {
    label: "Battery Storage",
    isBoolean: true,
  },
  batteryMakeAndModelNumber: {
    label: "Battery Make And Model Number",
  },
  batteryInterconnection: {
    label: "Battery Interconnection",
  },
  interconnectionType: {
    label: "Interconnection Type",
  },
  conduitRun: {
    label: "Conduit Run",
  },
  conduitType: {
    label: "Conduit Type",
  },
  note: {
    label: "Note",
  },
};

const electricalRequestFields = {
  indicateMspLocation: {
    label: "Indicate M.S.P. Location",
  },
  mainElectricalPanelUpgrade: {
    label: "Main Electrical Panel Upgrade",
    isBoolean: true,
  },
  mspBusRating: {
    label: "M.S.P. Bus Rating",
  },
  mainBreakerRating: {
    label: "Main Breaker Rating",
  },
  mspBusBarFeedLocation: {
    label: "M.S.P. Bus Bar Feed Location",
  },
  subpanelExisting: {
    isSubpanel: true,
    label: "Existing Service Sub panel",
    subpanel: {
      busRating: {
        label: "Bus Rating",
      },
      breakerRating: {
        label: "Breaker Rating",
      },
    },
  },
  subpanelNew: {
    isSubpanel: true,
    label: "New Service Sub Panel",
    subpanel: {
      busRating: {
        label: "Bus Rating",
      },
      breakerRating: {
        label: "Breaker Rating",
      },
    },
  },
  acDisconnect: {
    label: "AC Disconnect",
    isBoolean: true,
  },
  size: {
    label: "Size",
  },
  pvProductionMeter: {
    isBoolean: true,
    label: "PV Production Meter",
  },
  note: {
    label: "Note",
  },
};

const structuralRequestFields = {
  roofSheetingType: {
    label: "Roof Sheeting Type",
  },
  roofFraming: {
    render: ({ roofFraming }) => (
      <div key={roofFraming}>
        <NewInfoField
          field="Roof Framing Member Type/Framing Size/Spacing"
          value={roofFraming}
        />
        {roofTypes[roofFraming] && (
          <div className="roof-image margin-md margin-top-none">
            <CustomImage
              width={100}
              height={100}
              src={require("../components/roofImages/" +
                roofTypes[roofFraming])}
            />
          </div>
        )}
      </div>
    ),
  },
  rackingMakeAndModel: {
    label: "Racking Make and Model",
  },
  attachmentMakeAndModel: {
    label: "Attachment Make and Model",
  },
  attachmentSpacing: {
    label: "Attachment Spacing",
  },
  roofPitch: {
    label: "Roof Pitch",
  },
  note: {
    label: "Note",
  },
  clientAttachments: {
    render: ({ id, clientAttachments }, { onDownloadClick, onDownloadAll }) => {
      if (!clientAttachments.length) {
        return null;
      }
      return (
        <div key={id}>
          <Divider />
          <Attachments
            id={id}
            title="Attached Files"
            files={clientAttachments}
            onDownloadClick={onDownloadClick}
            onDownloadAll={onDownloadAll}
          />
        </div>
      );
    },
  },
};

export const renderSolarDraftSteps = (
  currentStep,
  isMobile,
  options,
  uploadRequest,
  onDeleteAttachement,
  onDeleteAllAttachements,
  clientAttachments,
  errors,
  initialValues
) => {
  return [
    {
      key: "1",
      content: (
        <ProjectInformationForm initialValues={initialValues} errors={errors} />
      ),
      icon: !isMobile ? (
        <ProjectInformationIcon />
      ) : (
        <ProjectInformationIconSmall />
      ),
    },
    {
      key: "2",
      content: (
        <SolarForm
          options={options}
          initialValues={initialValues.solarData}
          errors={errors}
        />
      ),
      icon: !isMobile ? (
        <SolarIcon fill={currentStep >= 1 ? "#4681F4" : "#7A7A7A80"} />
      ) : (
        <SolarIconSmall fill={currentStep >= 1 ? "#4681F4" : "#7A7A7A80"} />
      ),
    },
    {
      key: "3",
      content: (
        <ElectricalForm
          options={options}
          initialValues={initialValues.electricalData}
          errors={errors}
        />
      ),
      icon: !isMobile ? (
        <ElectricalIcon fill={currentStep >= 2 ? "#4681F4" : "#7A7A7A80"} />
      ) : (
        <ElectricalIconSmall
          fill={currentStep >= 2 ? "#4681F4" : "#7A7A7A80"}
        />
      ),
    },
    {
      key: "4",
      content: (
        <StructuralForm
          options={options}
          uploadRequest={uploadRequest}
          onDeleteAttachement={onDeleteAttachement}
          onDeleteAllAttachements={onDeleteAllAttachements}
          clientAttachments={clientAttachments}
          errors={errors}
          initialValues={initialValues.structuralData}
        />
      ),
      icon: !isMobile ? (
        <StructuralIcon fill={currentStep === 3 ? "#4681F4" : "#7A7A7A80"} />
      ) : (
        <StructuralIconSmall
          fill={currentStep === 3 ? "#4681F4" : "#7A7A7A80"}
        />
      ),
    },
  ];
};

export const getDraftTypeLabel = (response = draftTypes) => {
  if (response.solar && response.electrical && response.structural) {
    return `${draftTypes.solar}, ${draftTypes.electrical} & ${draftTypes.structural}`;
  }
  if (response.solar && response.electrical) {
    return `${draftTypes.solar} & ${draftTypes.electrical}`;
  }
  if (response.solar && response.structural) {
    return `${draftTypes.solar} & ${draftTypes.structural}`;
  }
  if (response.electrical && response.structural) {
    return `${draftTypes.electrical} & ${draftTypes.structural}`;
  }
  if (response.solar) {
    return draftTypes.solar;
  }
  if (response.electrical) {
    return draftTypes.electrical;
  }
  if (response.structural) {
    return draftTypes.structural;
  }
};

export const renderSolarDraftDetailsSteps = (
  currentStep,
  isMobile,
  draft,
  onNextClick,
  onBackClick,
  onDownloadClick,
  onDownloadAll
) => {
  const projectInformation = {
    name: draft.name,
    address: draft.address,
    city: draft.city,
    state: draft.state,
    zipCode: draft.zipCode,
    utilityCompany: draft.utilityCompany,
    phoneNumber: draft.phone,
  };

  return [
    {
      key: "1",
      title: "Project Information",
      content: (
        <DetailsStep
          item={projectInformation}
          fields={projectInformationFields}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          currentStep={currentStep}
        />
      ),
      icon: !isMobile ? (
        <ProjectInformationIcon />
      ) : (
        <ProjectInformationIconSmall />
      ),
    },
    {
      key: "2",
      title: "Solar Engineering Draft",
      content: (
        <DetailsStep
          item={draft.solarData}
          fields={solarRequestFields}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          currentStep={currentStep}
        />
      ),
      icon: !isMobile ? (
        <SolarIcon fill={currentStep >= 1 ? "#4681F4" : "#7A7A7A80"} />
      ) : (
        <SolarIconSmall fill={currentStep >= 1 ? "#4681F4" : "#7A7A7A80"} />
      ),
    },
    {
      key: "3",
      title: "Electrical Engineering Draft",
      content: (
        <DetailsStep
          item={draft.electricalData}
          fields={electricalRequestFields}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          currentStep={currentStep}
        />
      ),
      icon: !isMobile ? (
        <ElectricalIcon fill={currentStep >= 2 ? "#4681F4" : "#7A7A7A80"} />
      ) : (
        <ElectricalIconSmall
          fill={currentStep >= 2 ? "#4681F4" : "#7A7A7A80"}
        />
      ),
    },
    {
      key: "4",
      title: "Structural Engineering Draft",
      content: (
        <DetailsStep
          item={{
            ...draft.structuralData,
            clientAttachments: draft.clientAttachments,
            status: draft.status,
            id: draft.id,
          }}
          fields={structuralRequestFields}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          currentStep={currentStep}
          onDownloadClick={onDownloadClick}
          onDownloadAll={onDownloadAll}
        />
      ),
      icon: !isMobile ? (
        <StructuralIcon fill={currentStep === 3 ? "#4681F4" : "#7A7A7A80"} />
      ) : (
        <StructuralIconSmall
          fill={currentStep === 3 ? "#4681F4" : "#7A7A7A80"}
        />
      ),
    },
  ];
};
