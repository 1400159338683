import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Flex, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../hooks/useWindowSize.js";
import { sendDraftPriceByAdmin } from "../../services/drafts";
import {
  deleteDrawingByDraftId,
  uploadDrawingByDraftId,
} from "../../services/drawing.js";
import { handleDownloadWithBlob, message, statuses } from "../../utils/utils.js";
import { getDraftTypeLabel } from "../../utils/drafts";
import { selectUser } from "../../redux/slices/userSlice.js";
import {
  selectCurrentDraft,
  setCurrentDraft,
} from "../../redux/slices/draftSlice.js";
import { sendRevision } from "../../services/revisions.js";
import { apiByRole } from "../../utils/apiByRole.js";
import useSetUploadError from "../../hooks/useSetUploadError.js";
import ProjectDetailsHeader from "./components/ProjectDetailsHeader.js";
import ProjectDetails from "./components/ProjectDetails";
import Drawing from "./components/Drawing";
import Revisions from "./components/Revisions";
import PageTitle from "../../components/pageTitle";
import Loading from "../../components/loading";
import SendPriceForm from "./components/SendPriceForm.js";
import CustomDrawer from "../../components/drawer/index.js";
import "./styles.css";

const Project = () => {
  const [loading, setLoading] = useState(true);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const [sendRevisionModalOpen, setSendRevisionModalOpen] = useState(false);
  const [revisionRefetch, setRevisionRefetch] = useState(false);
  const { draftId } = useParams();
  const { pathname, state = {} } = useLocation();
  const [currentPath, setCurrentPath] = useState(
    pathname || `/projects/${draftId}/details`
  );
  const { isMobile } = useWindowSize();
  const { role } = useSelector(selectUser);
  const refetch = (state || {}).refetch;
  const draft = useSelector(selectCurrentDraft);
  const draftType = getDraftTypeLabel(draft.draftTypes);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { filesList } = useSetUploadError({});
  const fetchData = () => {
    const request = apiByRole[role].projects.getDraft;
    request(draftId)
      .then((item) => {
        dispatch(
          setCurrentDraft({
            ...item,
            drawings: (item.drawings || []).map((file) => {
              return {
                ...file,
                name: file.fileName,
                dbName: file.fileName,
                uid: file.id,
              };
            }),
          })
        );
      })
      .catch((error) => console.error("Project --- catch", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!draftId) {
      return;
    }
    fetchData();
    return () => dispatch(setCurrentDraft({}));
  }, [draftId, refetch, dispatch]);

  useEffect(() => {
    if ((draft.drawings || []).length !== filesList.length) {
      fetchData();
    }
  }, [priceModalOpen]);

  const onTabClick = (url) => {
    const paramsArray = url.split("/");
    const navigateTo = paramsArray[paramsArray.length - 1];
    setCurrentPath(url);
    navigate(navigateTo);
  };

  useEffect(() => {
    setCurrentPath(pathname || `/projects/${draftId}/details`);
  }, [pathname]);

  const uploadRequest = (formData) => {
    const upload = uploadDrawingByDraftId(draftId, formData);
    return upload;
  };
  const onDeleteAttachment = (file) => {
    return deleteDrawingByDraftId(draftId, file.id);
  };

  const onSendPriceFinish = (values) => {
    if (!!values.price) {
      values.price = +values.price;
    }
    if (!!values.projectPrice) {
      values.price = +values.projectPrice;
    }
    sendDraftPriceByAdmin(draftId, values)
      .then(() => {
        fetchData();
        setPriceModalOpen(false);
        return message({
          message: "Your email has been successfully sent",
          type: "success",
        });
      })
      .catch(() => {
        setPriceModalOpen(false);
        return message({
          message: "Something went wrong",
          type: "error",
        });
      });
  };

  const onSendRevision = (values) => {
    const send = sendRevision(draftId, values)
      .then(() => {
        setSendRevisionModalOpen(false);
        setRevisionRefetch(true);
        return message({
          message: "Your request has been successfully sent",
          type: "success",
        });
      })
      .catch(() => {
        setSendRevisionModalOpen(false);
        return message({ message: "Something went wrong", type: "error" });
      })
      .finally(() => setRevisionRefetch(false));
    return send;
  };

  const onDownloadClick = ({ file }) => {
    handleDownloadWithBlob({ url: file.url, fileName: file.fileName });
  };

  const onDownloadAll = () => {
    draft.drawings.forEach((file) => onDownloadClick({ file }));
  };

  const onProccedToPaymentClick = () => {
    navigate(`/payment-methods/drafts/${draft.id}`);
  };
  const buttonDisabled =
    draft.status === "completed_project" && role !== "admin";
  const currentOptions = statuses[role].project;

  const tabs = [
    {
      key: `/projects/${draftId}/details`,
      label: "Project Information",
      children: <ProjectDetails onSendPriceFinish={onSendPriceFinish} />,
    },
    {
      key: `/projects/${draftId}/drawing`,
      label: "Drawing",
      children: (
        <Drawing
          draft={draft}
          onSendRevision={onSendRevision}
          onDownloadClick={onDownloadClick}
          onDownloadAll={onDownloadAll}
          sendRevisionModalOpen={sendRevisionModalOpen}
          setSendRevisionModalOpen={setSendRevisionModalOpen}
          buttonDisabled={buttonDisabled}
        />
      ),
    },
    {
      key: `/projects/${draftId}/revisions`,
      label: "Revisions",
      children: (
        <Revisions
          draftId={draftId}
          draftName={draft.name}
          refetch={revisionRefetch}
        />
      ),
    },
  ];

  if (loading) {
    return (
      <div className="page-loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="project-container">
      <Flex justify="space-between" align="center" vertical={isMobile} gap={32}>
        <PageTitle title={`${draftType} Engineering Request`} />
        {role === "admin" && (
          <Flex gap={20}>
            <p>Company</p>
            <p
              className="is-link"
              onClick={() => navigate(`/contractors/${draft.userId}`)}
            >
              {draft.companyName}
            </p>
          </Flex>
        )}
      </Flex>
      <Flex
        justify="space-between"
        className="project-details-header-wrapper margin-top-xxxl"
        vertical={isMobile}
      >
        <Radio.Group
          className="projects-details-buttons-group"
          value={currentPath}
          onChange={(e) => onTabClick(e.target.value)}
        >
          {tabs.map((tab) => (
            <Radio.Button
              key={tab.key}
              className={
                currentPath === tab.key
                  ? "buttons-group-item-active"
                  : "buttons-group-item"
              }
              value={tab.key}
            >
              {tab.label}
            </Radio.Button>
          ))}
        </Radio.Group>
        <ProjectDetailsHeader
          data={draft}
          onPriceClick={() => setPriceModalOpen(true)}
          onProccedToPaymentClick={onProccedToPaymentClick}
          fetchData={fetchData}
          currentOptions={currentOptions}
        />
      </Flex>
      {tabs.find((tab) => tab.key === currentPath).children}
      <CustomDrawer
        open={priceModalOpen}
        title="Send Drawing"
        customClass="send-form-modal-container"
        content={
          <SendPriceForm
            name="projectPrice"
            onFinish={onSendPriceFinish}
            uploadRequest={uploadRequest}
            attachments={draft.drawings}
            onDeleteAttachment={onDeleteAttachment}
            onCancel={() => setPriceModalOpen(false)}
          />
        }
        onCancel={() => setPriceModalOpen(false)}
      />
    </div>
  );
};

export default Project;
