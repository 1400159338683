import { useState } from "react";
import { Flex } from "antd";
import CustomButton from "../../../components/button";

const PaySection = ({ price, projectName = "", onPayClick, noCard }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Flex vertical align="center" gap={24} className="text-black">
      <p className="text-semibold text-md">{projectName}</p>
      <p className="text-lg text-semibold">Total Price - {price}$</p>
      <CustomButton
        buttonTitle="Pay"
        onClick={() => {
          setLoading(true);
          return onPayClick();
        }}
        disabled={loading || noCard}
        loading={loading}
      />
    </Flex>
  );
};

export default PaySection;
