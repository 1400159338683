import PageTitle from "../../components/pageTitle";
import { useWindowSize } from "../../hooks/useWindowSize";
import ContractorsDesktop from "./ContractorsDesktop";
import ContractorsMobile from "./ContractorsMobile";

const Contractors = ({ deactivated }) => {
  const { isMobile } = useWindowSize();
  return (
    <div>
      <PageTitle
        title={`${!deactivated ? "Active" : "Deactivated"} Contractors`}
      />
      {!isMobile ? (
        <ContractorsDesktop deactivated={deactivated} />
      ) : (
        <ContractorsMobile deactivated={deactivated} />
      )}
    </div>
  );
};

export default Contractors;
