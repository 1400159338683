import { Flex } from "antd";

const IconWithText = ({
  IconComponent,
  text,
  status,
  gap = 12,
  justify = "normal",
  customClass = "",
  styles = {},
  iconClassname = "",
}) => {
  const className =
    status === "success" ? "success" : status === "error" ? "error" : "";

  return (
    <Flex
      justify={justify}
      align="center"
      gap={gap}
      style={styles}
      className={`icon-with-text ${customClass}`}
    >
      {IconComponent && (
        <IconComponent className={`text-${className} ${iconClassname}`} />
      )}
      <span className={`text-${className} text text-left`}>{text}</span>
    </Flex>
  );
};

export default IconWithText;
