import { useState } from "react";
import { Form, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../services/auth";
import CustomButton from "../../components/button";
import FloatingLabelInput from "../../components/inputs/FloatingLabelInput";
import CustomForm from "../../components/form";

const UpdatePasswordForm = () => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const verificationToken = searchParams.get("token");

  const onFinish = async (values) => {
    setLoading(true);
    if (verificationToken) {
      const params = {
        token: verificationToken,
        password: values.newPassword,
        confirmPassword: values.confirmNewPassword,
      };
      resetPassword(params)
        .then((response) => {
          if (response) {
            message.success("Password has been successfully updated");
            navigate("/update-password-result", {
              state: { success: response.success },
            });
          }
        })
        .catch((error) => {
          if (!error.success) {
            message.error("Something went wrong");
            navigate("/update-password-result", {
              state: { success: error.success },
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <p className="auth-form-title">Update Password</p>
      <p className="text-gray margin-bottom-sm">
        Please create a new password and confirm it to complete the update
        process.
      </p>
      <CustomForm form={form} onFinish={onFinish} setErrors={setErrors}>
        <FloatingLabelInput name="newPassword" errors={errors} />
        <FloatingLabelInput name="confirmNewPassword" errors={errors} />
        <CustomButton
          buttonTitle="Update Password"
          customClass="margin-top-xl"
          disabled={loading}
          loading={loading}
          htmlType="submit"
          block
        />
      </CustomForm>
    </>
  );
};

export default UpdatePasswordForm;
