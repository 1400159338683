import { useEffect, useState } from "react";
import { Flex } from "antd";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice.js";
import { useParams } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowSize.js";
import {
  detailsHeaderButtonProps,
  message,
  statuses,
} from "../../../utils/utils.js";
import { assignByRevisionId } from "../../../services/revisions.js";
import { MailOutlined } from "@ant-design/icons";
import { assignByDraftId } from "../../../services/drafts.js";
import CustomButton from "../../../components/button";
import CustomDrawer from "../../../components/drawer";
import Statuses from "../../../components/status/Statuses.js";
import EmailForm from "../../../components/emailForm/index.js";

const ProjectDetailsHeader = ({
  data = {},
  onPriceClick,
  onProccedToPaymentClick,
  fetchData,
  currentOptions,
}) => {
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedAll, setSelectedAll] = useState(true);
  const { role } = useSelector(selectUser);
  const { isMobile } = useWindowSize();
  const { draftId } = useParams();
  const isAmdin = role === "admin";
  const status = data.status;

  useEffect(() => {
    if (!!(data.clientAttachments || []).length) {
      setSelectedFiles(
        data.clientAttachments.map((file) => file.key || file.fileName)
      );
      return;
    }
  }, [emailModalOpen]);
  useEffect(() => {
    if ((data.clientAttachments || []).length === selectedFiles.length) {
      return setSelectedAll(true);
    }
    setSelectedAll(false);
  }, [selectedFiles, data.clientAttachments]);

  const onSendEmailFinish = (values) => {
    if (!!selectedFiles.length) {
      values.clientAttachments = selectedFiles;
    }
    const fetchRequest = draftId ? assignByDraftId : assignByRevisionId;
    const id = draftId || data.id;
    fetchRequest(id, values)
      .then(() => {
        fetchData();
        return message({ message: "Your email has been successfully sent" });
      })
      .catch(() => message({ message: "Something went wrong", type: "error" }))
      .finally(() => setEmailModalOpen(false));
  };

  const renderButton = () => {
    const buttonProps = detailsHeaderButtonProps(
      isAmdin,
      onPriceClick,
      onProccedToPaymentClick
    )[status];
    if (!buttonProps || buttonProps.invisible) {
      return null;
    }
    return <CustomButton {...buttonProps} block={isMobile} />;
  };
  const onEmailClick = () => {
    setEmailModalOpen(true);
  };

  const onFileSelect = (file, isChecked) => {
    if (isChecked) {
      setSelectedFiles((prevFiles) => [...prevFiles, file]);
      return;
    }
    setSelectedFiles((prevFiles) => prevFiles.filter((item) => item !== file));
  };

  const onFileSelectAll = (isChecked) => {
    if (isChecked) {
      setSelectedFiles(
        data.clientAttachments.map((file) => file.key || file.fileName)
      );
      return;
    }
    setSelectedFiles([]);
  };

  return (
    <>
      <div className="project-details-header">
        <Flex
          gap={16}
          justify="space-between"
          className="project-details-header-content"
          vertical={isMobile}
        >
          <Statuses status={status} currentOptions={currentOptions} />
          <Flex gap={16} className="project-details-header-buttons">
            {role === "admin" &&
              (status === "new" || status === "in_process") && (
                <CustomButton
                  buttonTitle="Email"
                  icon={<MailOutlined />}
                  type="default"
                  onClick={onEmailClick}
                />
              )}
            {renderButton()}
          </Flex>
        </Flex>
      </div>
      <CustomDrawer
        title="Email"
        open={emailModalOpen}
        content={
          <EmailForm
            onFinish={onSendEmailFinish}
            files={data.clientAttachments}
            selectedFiles={selectedFiles}
            onFileSelect={onFileSelect}
            onFileSelectAll={onFileSelectAll}
            selectedAll={selectedAll}
            onCancel={() => setEmailModalOpen(false)}
          />
        }
        onCancel={() => setEmailModalOpen(false)}
      />
    </>
  );
};

export default ProjectDetailsHeader;
