import { useState } from "react";
import { Flex, Form, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectCompany, setCompany } from "../../../redux/slices/userSlice.js";
import { CloudUploadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { createCompany, updateCompany } from "../../../services/user.js";
import { updateContractorCompanyById } from "../../../services/contractor.js";
import { message } from "../../../utils/utils.js";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput.js";
import CustomForm from "../../../components/form/index.js";
import ModalFooter from "../../../components/modal/ModalFooter.js";
import CustomCard from "../../../components/card/index.js";
import IconWithText from "../../../components/iconWithText/index.js";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import "../styles.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const removeXMLDeclaration = (svgText) => {
  return svgText.replace(/<\?xml(.+?)\?>/g, "");
};

const CompanyProfileForm = ({ onCancel = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const company = useSelector(selectCompany);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const logoValidation = (file) => {
    const allowedFormats = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
      "image/webp",
    ];
    const maxFileSize = 1024 * 1024; // 1 MB

    if (!allowedFormats.includes(file.type)) {
      setErrorMessage(
        "Please choose an image file in one of the formats mentioned above"
      );
      return false;
    }

    if (file.size > maxFileSize) {
      setErrorMessage("The logo size should be maximum 1 mb");
      return false;
    }
    if (file) {
      setImage(file);
      setErrorMessage(null);
    }
    return true;
  };

  const onFinish = async (values) => {
    if (errorMessage) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values.companyName);
    formData.append("address", values.address);
    formData.append("city", values.city);
    formData.append("state", values.state);
    formData.append("zipCode", values.zipCode);
    formData.append("licenseType", values.licenseType);
    formData.append("licenseNumber", values.licenseNumber);

    const fetchData = async () => {
      if (isEmpty(company)) {
        await createCompany(formData);
        return;
      }
      if (!company.contractorId) {
        await updateCompany(formData);
      } else {
        await updateContractorCompanyById(formData, company.contractorId);
      }
    };

    if (!isEmpty(values.logo)) {
      const logoFile = values.logo;
      if (logoFile.type === "image/svg+xml") {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const svgContent = event.target.result;
          const svgWithoutXML = removeXMLDeclaration(svgContent);
          const blob = new Blob([svgWithoutXML], { type: "image/svg+xml" });
          formData.append("logo", blob, logoFile.name);
          fetchData().catch((error) => console.error(error));
        };
        reader.readAsText(logoFile);
      } else {
        formData.append("logo", logoFile);
      }
    }
    fetchData()
      .then(() =>
        message({
          type: "success",
          duration: 1,
          message: isEmpty(company)
            ? "Company data has been successfully created"
            : "Company data has been successfully updated",
        })
      )
      .then(() => {
        const newData = { ...values, name: values.companyName };
        if (!isEmpty(values.logo)) {
          newData.logo = URL.createObjectURL(values.logo);
        } else {
          delete newData.logo;
        }
        setImage(null);
        setErrorMessage(null);
        onCancel();
        setLoading(false);
        form.setFieldsValue({
          logo: [],
        });
        dispatch(
          setCompany({
            company: { ...company, ...newData },
          })
        );
      })
      .catch((error) => {
        console.error("Error: ---- ", error);
        const errors = get(error, "response.data.validationErrors");
        if (errors) {
          Object.keys(errors).forEach((fieldName) => {
            form.setFields([
              {
                name: fieldName === "name" ? "companyName" : fieldName,
                errors: [errors[fieldName][0]],
              },
            ]);
          });
        }
        setLoading(false);
        const message = get(error, "response.data.message");
        if (message) {
          message({
            type: "error",
            message: message,
          });
          return;
        }
        message({
          type: "error",
          message: "Something went wrong",
        });
      });
  };

  function handleCancel() {
    setErrorMessage(null);
    setImage(null);
    form.resetFields();
    return onCancel();
  }

  const checkedUrl =
    company.logo && company.logo.startsWith("blob")
      ? company.logo
      : API_BASE_URL + company.logo;
  return (
    <>
      <div className="forms-container">
        <CustomForm
          form={form}
          onFinish={onFinish}
          setErrors={setErrors}
          initialValues={{ ...company, companyName: company.name, logo: [] }}
        >
          <Form.Item
            name="logo"
            valuePropName="logo"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return get(e, "target.files[0]", null);
            }}
          >
            <div className="margin-bottom-lg">
              <Upload
                name="logo"
                listType="picture-circle"
                className="logo-upload-container text-center"
                showUploadList={false}
                beforeUpload={logoValidation}
                customRequest={() => null}
              >
                {image || company.logo ? (
                  <img
                    className="logo-upload"
                    src={!!image ? URL.createObjectURL(image) : checkedUrl}
                    alt=""
                  />
                ) : (
                  <Flex vertical align="center" justify="center" gap={8}>
                    <CloudUploadOutlined className="logo-upload-icon" />
                    <p className="text-primary">Upload</p>
                  </Flex>
                )}
              </Upload>
              <p className="logo-info-text text-gray">
                PNG, JPEG, JPG, SVG, or WebP and max 1 mb
              </p>
              {!!errorMessage && (
                <CustomCard
                  customClass="text-error margin-top-xl"
                  description={
                    <IconWithText
                      IconComponent={ExclamationCircleFilled}
                      status="error"
                      text={errorMessage}
                    />
                  }
                />
              )}
            </div>
          </Form.Item>
          <FloatingLabelInput
            name="companyName"
            initialValue={company.name}
            errors={errors}
          />
          <FloatingLabelInput
            name="address"
            initialValue={company.address}
            errors={errors}
          />
          <FloatingLabelInput
            name="city"
            initialValue={company.city}
            errors={errors}
          />
          <FloatingLabelInput
            name="state"
            initialValue={company.state}
            errors={errors}
          />
          <FloatingLabelInput
            name="zipCode"
            initialValue={company.zipCode}
            errors={errors}
          />
          <FloatingLabelInput
            name="licenseType"
            initialValue={company.licenseType}
            errors={errors}
          />
          <FloatingLabelInput
            name="licenseNumber"
            initialValue={company.licenseNumber}
            errors={errors}
          />
          <Form.Item>
            <ModalFooter
              submitTitle="Update"
              loading={loading}
              disabled={loading}
              htmlType="submit"
              onCancel={handleCancel}
            />
          </Form.Item>
        </CustomForm>
      </div>
    </>
  );
};

export default CompanyProfileForm;
