import { Link } from "react-router-dom";
import { Flex } from "antd";
import { getValueFromBoolean } from "../../utils/utils";
import NewInfoField from "../infoField/NewInfoField";
import IconWithText from "../iconWithText";
import CustomCard from "../card";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

const NewInfoCard = ({
  item = {},
  fields = {},
  renderActions = [],
  params = {},
}) => {
  const fieldsClone = cloneDeep(fields);

  const renderInfo = () => {
    return (
      <Flex vertical>
        {Object.entries(fieldsClone).map(([key, value]) => {
          if ((fieldsClone[key] || {}).visible) {
            return (
              <div key={key}>
                {(fieldsClone[key] || {}).render(item, params)}
              </div>
            );
          }
          if (!item[key] || !!(fieldsClone[key] || {}).invisible) {
            return null;
          }
          if ((fieldsClone[key] || {}).icon) {
            return (
              <IconWithText
                key={key}
                customClass="main-info text-black text-md text-semibold"
                text={item[key]}
                IconComponent={value.icon}
                iconClassname="text-lg"
              />
            );
          }
          if ((fieldsClone[key] || {}).render) {
            if ((fieldsClone[key] || {}).label) {
              return (
                <NewInfoField
                  key={key}
                  field={fieldsClone[key].label}
                  value={(fieldsClone[key] || {}).render(item, params)}
                />
              );
            }
            return (
              <div key={key}>
                {(fieldsClone[key] || {}).render(item, params)}
              </div>
            );
          }
          const renderValue = (params) => {
            if (!item[key]) {
              return null;
            }
            const isDate = value.isDate;
            if (isDate) {
              return moment(item[key]).format("L");
            }
            if (value.isBoolean) {
              return getValueFromBoolean(item[key]);
            }
            if (value.url) {
              return (
                <Link to={value.url(item)} onClick={value.onClick}>
                  {item[key]}
                </Link>
              );
            }
            if (value.isSubpanel) {
              return item[key][params];
            }
            return item[key];
          };
          if (value.isSubpanel) {
            return (
              <div key={key}>
                <p className="text-black margin-left-md">{value.label}</p>

                {Object.keys(value.subpanel).map((r, index) => (
                  <NewInfoField
                    key={index + key}
                    field={fieldsClone[key].subpanel[r].label}
                    value={renderValue(r)}
                  />
                ))}
              </div>
            );
          }
          return (
            fieldsClone[key] && (
              <NewInfoField
                key={key}
                field={fieldsClone[key].label}
                value={renderValue()}
              />
            )
          );
        })}
      </Flex>
    );
  };

  return (
    <CustomCard description={renderInfo()} renderActions={renderActions} />
  );
};

export default NewInfoCard;
