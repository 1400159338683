import { Flex, Form, Radio } from "antd";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import ModalFooter from "../../../components/modal/ModalFooter";
import dayjs from "dayjs";

dayjs.extend(weekday);
dayjs.extend(localeData);

const TypeFilterForm = ({
  form,
  onFinish,
  initialValues = {},
  onCancel = () => {},
}) => {
  return (
    <Form
      className="modal-form-container"
      form={form}
      onFinish={onFinish}
      validateTrigger="onBlur"
      layout="vertical"
      requiredMark={false}
      initialValues={initialValues}
    >
      <div>
        <Form.Item label="Type" name="type">
          <Radio.Group className="radio-group">
            {["Draft", "Revision"].map((item) => (
              <Flex vertical key={item}>
                <Radio value={item}>{item}</Radio>
              </Flex>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="custom-modal-footer">
        <ModalFooter
          htmlType="submit"
          submitTitle="Apply"
          onCancel={onCancel}
        />
      </div>
    </Form>
  );
};

export default TypeFilterForm;
