import { useState } from "react";
import { Badge, Flex, Form } from "antd";
import { DownOutlined, CalendarOutlined } from "@ant-design/icons";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import SearchInput from "../../../components/inputs/SearchInput";
import RangePicker from "../../../components/rangePicker";
import RadioButtonFilters from "../../../components/radioButtonFilters";
import IconWithText from "../../../components/iconWithText";
import CustomDrawer from "../../../components/drawer";
import TypeFilterForm from "./TypeFilterForm";
import DateFilterForm from "./DateFilterForm";
import get from "lodash/get";
import moment from "moment";
import dayjs from "dayjs";
import "../styles.css";

const FilterSection = ({
  handleSearch,
  handleFilterChange,
  handleResetFilter,
}) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("search") || "";
  const { isMobile } = useWindowSize();
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const type = searchParams.get("type");
  const [dateModalOpen, setDateModalOpen] = useState(false);
  const [typefilterModalOpen, setTypeFilterModalOpen] = useState(false);
  const [range, setRange] = useState([]);
  const initialFilters = {
    date: [startDate && dayjs(startDate), endDate && dayjs(endDate)],
    type:
      searchParams.get("type") &&
      searchParams.get("type").charAt(0) +
        searchParams.get("type").slice(1).toLowerCase(),
  };

  const typeFilterOptions = {
    Draft: { label: "Draft" },
    Revision: { label: "Revision" },
  };

  const onFilterChange = (item, type) => {
    if (type === "date") {
      setRange(item);
      handleFilterChange({
        filters: {
          startDate:
            item && item[0] ? moment(item[0].$d).toISOString() : undefined,
          endDate:
            item && item[1] ? moment(item[1].$d).toISOString() : undefined,
        },
      });
      return;
    }
    handleFilterChange({
      filters: { [`${type}`]: item.item ? item.item : item },
    });
  };

  const onFiltersApply = ({ type, date }) => {
    if (type) {
      handleFilterChange({
        filters: {
          type,
        },
      });
      setTypeFilterModalOpen(false);
      return;
    }
    handleFilterChange({
      filters: {
        // type: get(params, "type", type),
        startDate: get(date, "[0]")
          ? moment(date[0].$d).toISOString()
          : undefined,
        endDate: get(date, "[1]")
          ? moment(date[1].$d).toISOString()
          : undefined,
      },
    });
    setDateModalOpen(false);
  };

  const onFiltersReset = () => {
    form.setFieldsValue({ date: [], type: "" });
    handleResetFilter();
    setRange(null);
  };

  const onCancel = () => {
    setRange(null);
    dateModalOpen ? setDateModalOpen(false) : setTypeFilterModalOpen(false);
  };

  return (
    <>
      <Flex
        className="margin-top-md"
        align={!isMobile ? "center" : "flex-start"}
        gap={16}
        vertical={isMobile}
      >
        <SearchInput
          value={query}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div className="transactions-filters-container">
          <Flex gap={24} align="center">
            {!isMobile && (type || startDate || endDate) && (
              <p className="is-link text-md" onClick={onFiltersReset}>
                Clear Filters
              </p>
            )}
            {!isMobile ? (
              <RadioButtonFilters
                filterOptions={typeFilterOptions}
                selectedItem={type}
                onFilterChange={onFilterChange}
                type="type"
                customClass="transactions-filters"
              />
            ) : (
              <div
                className="filter-badge-wrapper"
                onClick={() => setTypeFilterModalOpen(true)}
              >
                {type && <Badge dot color="#4D6EF2" className="filter-badge" />}
                <IconWithText
                  customClass="row-reverse text-gray"
                  text="Type"
                  IconComponent={DownOutlined}
                />
              </div>
            )}
            <div className="filter-badge-wrapper">
              {isMobile && (startDate || endDate) && (
                <Badge dot color="#4D6EF2" className="filter-badge" />
              )}
              {!isMobile ? (
                <RangePicker
                  onCalendarChange={(e) => onFilterChange(e, "date")}
                  defaultValue={initialFilters.date}
                  value={range}
                />
              ) : (
                <div onClick={() => setDateModalOpen(true)}>
                  <IconWithText
                    customClass="row-reverse text-gray"
                    iconClassname="calendar-icon"
                    text="Date"
                    IconComponent={CalendarOutlined}
                    gap={8}
                  />
                </div>
              )}
            </div>
            {isMobile && (type || startDate || endDate) && (
              <p className="is-link text-md" onClick={onFiltersReset}>
                Clear Filters
              </p>
            )}
          </Flex>
        </div>
      </Flex>
      <CustomDrawer
        title={dateModalOpen ? "Date" : "Type"}
        open={dateModalOpen || typefilterModalOpen}
        content={
          dateModalOpen ? (
            <DateFilterForm
              form={form}
              onFinish={onFiltersApply}
              initialValue={initialFilters.date}
              range={range}
              setRange={setRange}
              onCancel={onCancel}
            />
          ) : (
            <TypeFilterForm
              form={form}
              onFinish={onFiltersApply}
              initialValues={initialFilters.type}
              onCancel={onCancel}
            />
          )
        }
        onCancel={onCancel}
      />
    </>
  );
};

export default FilterSection;
