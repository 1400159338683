import { useState } from "react";
import { getValueFromBoolean } from "../../utils/utils.js";
import FloatingLabelInput from "../inputs/FloatingLabelInput.js";
import FloatingLabelSelectItem from "../inputs/FloatingLabelSelectItem.js";
import "./styles.css";

const SolarForm = ({ options = {}, initialValues = {}, errors = [] }) => {
  const [selectedIverter, setSelectedInverter] = useState(
    localStorage.getItem("solar-request-selected-iverter") || ""
  );
  const hasBattery = getValueFromBoolean(initialValues.batteryStorage);
  const [selectedBattery, setSelectedBattery] = useState(
    localStorage.getItem("solar-request-selected-battery") || hasBattery
  );
  const {
    optimizerIsRequired = [],
    optimizerIsOptional = [],
    optimizerIsMissing = [],
  } = options.inverterMakeAndModelNumber || {};
  const onIverterChange = (selected) => {
    if (!optimizerIsOptional.includes(selected)) {
      optimizerIsOptional.push(selected);
    }
    localStorage.setItem("solar-request-selected-iverter", selected);
    setSelectedInverter(selected);
  };

  const onBatteryChange = (selected) => {
    setSelectedBattery(selected);
    localStorage.setItem("solar-request-selected-battery", selected);
  };
  const renderOptimazer = () => {
    if (optimizerIsRequired.includes(selectedIverter)) {
      return (
        <FloatingLabelSelectItem
          name="optimizerRequired"
          options={options.optimizer}
          errors={errors}
          initialValue={initialValues.optimizer}
        />
      );
    }
    if (optimizerIsMissing.includes(selectedIverter)) {
      return null;
    }
    return (
      <FloatingLabelSelectItem
        name="optimizer"
        options={options.optimizer}
        errors={errors}
        initialValue={initialValues.optimizer}
      />
    );
  };

  return (
    <div className="form-wrapper margin-top-xxl">
      <FloatingLabelInput
        name="moduleMakeAndModelNumber"
        initialValue={initialValues.moduleMakeAndModelNumber}
        errors={errors}
      />
      <FloatingLabelInput
        name="numberOfModules"
        initialValue={initialValues.numberOfModules}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="inverterMakeAndModelNumber"
        options={[
          ...optimizerIsRequired,
          ...optimizerIsOptional,
          ...optimizerIsMissing,
        ]}
        onChange={onIverterChange}
        initialValue={initialValues.inverterMakeAndModelNumber}
        errors={errors}
      />
      <FloatingLabelInput
        name="numberOfInverters"
        initialValue={initialValues.numberOfInverters}
        errors={errors}
      />
      {renderOptimazer()}
      <FloatingLabelSelectItem
        name="batteryStorage"
        options={options.batteryStorage}
        onChange={onBatteryChange}
        initialValue={selectedBattery}
        errors={errors}
      />
      {selectedBattery === "Yes" && (
        <>
          <FloatingLabelInput
            name="batteryMakeAndModelNumber"
            initialValue={initialValues.batteryMakeAndModelNumber}
            errors={errors}
          />
          <FloatingLabelInput
            name="batteryInterconnection"
            initialValue={initialValues.batteryInterconnection}
            errors={errors}
          />
        </>
      )}
      <FloatingLabelSelectItem
        name="interconnectionType"
        options={options.interconnectionType}
        initialValue={initialValues.interconnectionType}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="conduitRun"
        options={options.conduitRun}
        initialValue={initialValues.conduitRun}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="conduitType"
        options={options.conduitType}
        initialValue={initialValues.conduitType}
        errors={errors}
      />
      <div className="margin-bottom-xxxl">
        <FloatingLabelInput
          name="note"
          errors={errors}
          initialValue={initialValues.note}
        />
      </div>
    </div>
  );
};

export default SolarForm;
