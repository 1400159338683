import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice.js";
import { EditOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { selectContractorProfile } from "../../../redux/slices/contractorSlice.js";
import { message } from "../../../utils/utils.js";
import IconWithText from "../../../components/iconWithText/index.js";
import ProfileForm from "./ProfileForm.js";
import NewInfoCard from "../../../components/infoCard/NewInfoCard.js";
import Modal from "../../../components/modal/index.js";
import ModalFooter from "../../../components/modal/ModalFooter.js";
import ModalContent from "../../../components/modal/ModalContent.js";
import CustomDrawer from "../../../components/drawer/index.js";
import isEmpty from "lodash/isEmpty";
import api from "../../../utils/request.js";
import "../styles.css";

const fields = {
  fullName: {
    label: "Full Name",
  },
  email: {
    label: "Email",
  },
  phoneNumber: {
    label: "Phone",
  },
  dateOfRegistration: {
    label: "Join Date",
    isDate: true,
  },
};
const renderActions = (role, setModalOpenEdit, setModalOpenDeactivate) => {
  const actions = [
    <div className="is-link" onClick={() => setModalOpenEdit(true)}>
      <IconWithText
        text="Edit Profile"
        IconComponent={EditOutlined}
        styles={{ justifyContent: "center" }}
      />
    </div>,
  ];
  if (role === "contractor") {
    actions.push(
      <div className="is-link" onClick={() => setModalOpenDeactivate(true)}>
        <IconWithText
          text="Deactivate"
          IconComponent={UserDeleteOutlined}
          customClass="text-error"
          styles={{ justifyContent: "center" }}
        />
      </div>
    );
  }
  return actions;
};

const ProfileInfo = () => {
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [modalOpenDeactivate, setModalOpenDeactivate] = useState(false);
  const currentUser = useSelector(selectUser);
  const contractorProfile = useSelector(selectContractorProfile);
  const user = !isEmpty(contractorProfile) ? contractorProfile : currentUser;

  const navigate = useNavigate();
  const onDeactivateClick = () => {
    api
      .delete("/profile")
      .then(() => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/sign-in");
        return;
      })
      .catch((error) => {
        message({
          type: "error",
          message: "Something went wrong",
        });
        console.error("ProfileForm Deactivate - ERROR ----------- ", error);
      })
      .finally(() => {
        setModalOpenDeactivate(false);
      });
  };

  return (
    <div className="profile-info-card">
      <NewInfoCard
        item={user}
        fields={fields}
        renderActions={renderActions(
          currentUser.role,
          setModalOpenEdit,
          setModalOpenDeactivate
        )}
      />
      <CustomDrawer
        open={modalOpenEdit}
        onCancel={() => setModalOpenEdit(false)}
        title="Edit Profile"
        content={<ProfileForm onCancel={() => setModalOpenEdit(false)} />}
      />
      <Modal
        open={modalOpenDeactivate}
        footer={null}
        content={
          <>
            <ModalContent text="Are you sure you want to deactivate your account?" />
            <ModalFooter
              onSubmit={onDeactivateClick}
              onCancel={() => setModalOpenDeactivate(false)}
              submitTitle="Deactivate"
              type="default"
              danger
            />
          </>
        }
      />
    </div>
  );
};

export default ProfileInfo;
