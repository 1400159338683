import { Checkbox } from "antd";
import ModalFooter from "../modal/ModalFooter";
import "./styles.css";

const ChooseTypesForm = ({
  types,
  onSubmit = () => {},
  onCancel = () => {},
  checked = false,
  setTypeValues,
}) => {
  return (
    <div>
      <p className="step-title margin-bottom-xxxl text-sm text-semibold">
        Please select request service type
      </p>
      <Checkbox.Group
        className="checkbox-group margin-bottom-xl"
        options={types}
        onChange={setTypeValues}
      />
      <ModalFooter
        submitTitle="Add"
        onSubmit={onSubmit}
        onCancel={onCancel}
        disabled={checked}
      />
    </div>
  );
};

export default ChooseTypesForm;
