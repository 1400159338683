import api from "../utils/request";

export const uploadDrawingByDraftId = async (draftId = "", formData) => {
  try {
    const url = `/admin/drawings/${draftId}`;
    const response = await api.post(url, formData);
    return response.data.data;
  } catch (error) {
    console.error("drawing - uploadDrawingByDraftId - catch", error);
    throw error.response.data;
  }
};

export const uploadDrawingByRevisionId = async (revisionId = "", formData) => {
  try {
    const url = `/admin/drawings/revision/${revisionId}`;
    const response = await api.post(url, formData);
    return response.data.data;
  } catch (error) {
    console.error("drawing - uploadDrawingByRevisionId - catch", error);
    throw error.response.data;
  }
};

export const deleteDrawingByDraftId = async (draftId = "", drawingId = "") => {
  try {
    const url = `/admin/drawings/${draftId}/${drawingId}`;
    const response = await api.delete(url);
    return response.data.data;
  } catch (error) {
    console.error("drawing - deleteDrawingByDraftId - catch", error);
    throw error.response.data;
  }
};

export const deleteDrawingByRevisionId = async (
  revisionId = "",
  drawingId = ""
) => {
  try {
    const url = `/admin/drawings/revisions/${revisionId}/${drawingId}`;
    const response = await api.delete(url);
    return response.data.data;
  } catch (error) {
    console.error("drawing - deleteDrawingByRevisionId - catch", error);
    throw error.response.data;
  }
};
