import { useState, useEffect } from "react";

export function useWindowSize() {
  const [isMobile, setIsMobile] = useState(undefined);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return { isMobile };
}
