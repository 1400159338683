import { Image } from "antd";
import { useWindowSize } from "../../hooks/useWindowSize";
import "./styles.css";

const Logo = () => {
  const { isMobile } = useWindowSize();
  const accessToken = localStorage.getItem("accessToken");
  const image = isMobile ? "/logo-black.png" : "/logo-white.png";

  return (
    <Image
      className={`header-logo ${!accessToken ? "auth-header-logo" : ""}`}
      src={image}
      preview={false}
    />
  );
};

export default Logo;
