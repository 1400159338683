import { useState } from "react";
import { roofTypes } from "../../utils/roofTypes.js";
import UploadWithDragAndDrop from "../uploadWithDragAndDrop/index.js";
import CustomImage from "../image/index.js";
import FloatingLabelSelectItem from "../inputs/FloatingLabelSelectItem.js";
import FloatingLabelInput from "../inputs/FloatingLabelInput.js";
import "./styles.css";

const StructuralForm = ({
  options = {},
  uploadRequest,
  onDeleteAttachement,
  onDeleteAllAttachements,
  clientAttachments,
  initialValues = {},
  errors = [],
}) => {
  const [selectedRoof, setSelectedRoof] = useState(
    localStorage.getItem("solar-request-roof-type", "")
  );
  const onRoofSelect = (selected) => {
    setSelectedRoof(selected);
    localStorage.setItem("solar-request-roof-type", selected);
  };
  const image = roofTypes[selectedRoof];

  return (
    <div className="form-wrapper margin-top-xxl">
      <FloatingLabelSelectItem
        name="roofSheetingType"
        options={options.roofSheetingType}
        initialValue={initialValues.roofSheetingType}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="roofFraming"
        options={options.roofFraming}
        initialValue={initialValues.roofFraming}
        errors={errors}
        onChange={onRoofSelect}
      />
      {selectedRoof && image && (
        <div className="roof-image margin-bottom-lg">
          <CustomImage
            width={100}
            height={100}
            src={require("../roofImages/" + image)}
          />
        </div>
      )}
      <FloatingLabelInput
        name="rackingMakeAndModel"
        initialValue={initialValues.rackingMakeAndModel}
        errors={errors}
      />
      <FloatingLabelInput
        name="attachmentMakeAndModel"
        initialValue={initialValues.attachmentMakeAndModel}
        errors={errors}
      />
      <FloatingLabelInput
        name="attachmentSpacing"
        initialValue={initialValues.attachmentSpacing}
        errors={errors}
      />
      <FloatingLabelInput
        name="roofPitch"
        initialValue={initialValues.roofPitch}
        errors={errors}
      />
      <p className="text-semibold text-sm margin-bottom-xs">Choose Files</p>
      <UploadWithDragAndDrop
        uploadRequest={uploadRequest}
        onDeleteAttachement={onDeleteAttachement}
        onDeleteAllAttachements={onDeleteAllAttachements}
        clientAttachments={clientAttachments}
      />
      <div className="margin-top-md">
        <FloatingLabelInput
          name="structuralNote"
          initialValue={initialValues.structuralNote}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default StructuralForm;
