import { useEffect } from "react";
import SolarRequest from "./SolarRequest";
import CompactForm from "./CompactForm";
import "./styles.css";
import { message } from "../../utils/utils";

const CreateDraftForm = ({
  selectedTypes,
  isSolar,
  onCancel,
  fetchData = () => {},
}) => {
  useEffect(() => {
    return () => {
      localStorage.removeItem("solar-request-selected-battery");
      localStorage.removeItem("solar-request-selected-iverter");
      localStorage.removeItem("solar-request-selected-acDisconnect");
      localStorage.removeItem("solar-request-roof-type");
    };
  }, []);

  const onSubmit = () => {
    fetchData();
    message({
      type: "success",
      message: "Your request has been successfully sent!",
    });
  };

  return (
    <>
      {isSolar ? (
        <SolarRequest
          selectedTypes={selectedTypes}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      ) : (
        <CompactForm
          selectedTypes={selectedTypes}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default CreateDraftForm;
