import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // isLoggedIn: false,
  // accessToken: localStorage.getItem('accessToken'),
  // refreshToken: null,
  isMe: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      // const { accessToken, refreshToken } = action.payload;
      // state.user = user
      // state.isLoggedIn = true;
      // state.accessToken = accessToken;
      // state.refreshToken = refreshToken;
      // state.user = user;
    },
    logoutSuccess(state) {
      // state.isLoggedIn = false;
      // state.accessToken = null;
      // state.refreshToken = null;
      state.user = null;
    },
    setMe(state, action) {
      state.isMe = action.payload;
    },
  },
});

export const { loginSuccess, logoutSuccess, setMe } = authSlice.actions;

// export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
// export const selectAccessToken = (state) => state.auth.accessToken;
// export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectIsMe = (state) => state.auth.isMe;
// export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;
