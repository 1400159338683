import { Navigate, Outlet } from "react-router-dom";
import AuthInfoSection from "../../pages/auth/components/AuthInfoSection";
import { useWindowSize } from "../../hooks/useWindowSize";
import Logo from "../logo";

const AuthLayout = () => {
  const { isMobile } = useWindowSize();
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    return <Navigate to="/" />;
  }
  return (
    <div className="auth-container">
      {!isMobile ? (
        <AuthInfoSection />
      ) : (
        <div className="auth-logo-mobile-wrapper">
          <Logo />
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default AuthLayout;
