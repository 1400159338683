import { Menu } from "antd";

const CustomMenu = ({
  items,
  defaultOpenKeys,
  defaultSelectedKeys,
  selectedKeys,
  onMenuItemClick,
}) => {
  return (
    <Menu
      className="page-main-menu"
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      items={items}
      theme="dark"
      selectedKeys={selectedKeys}
      onClick={onMenuItemClick}
    />
  );
};

export default CustomMenu;
