export const passwordValidators = (value) => {
  const eightChars = /.{8,}/;
  const upperCase = /[A-Z]/;
  const lowerCase = /[a-z]/;
  const number = /[0-9]/;

  if (!value) {
    return;
  }
  if (!eightChars.test(value)) {
    return "Password should contain at least 8 characters";
  }
  if (!upperCase.test(value)) {
    return "Password should contain at least 1 uppercase letter";
  }
  if (!lowerCase.test(value)) {
    return "Password should contain at least 1 lowercase letter";
  }
  if (!number.test(value)) {
    return "Password should contain at least 1 number";
  }
  return undefined;
};

export const emailValidators = (value) => {
  const validEmail =
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  if (!value) {
    return;
  }
  if (!validEmail.test(value)) {
    return "Invalid email";
  }
  return;
};

export const onlyPositiveNumbersValidators = ({ inputValue, field }) => {
  let value = inputValue;
  if (!!value) {
    value = value.trim();
  }
  if (!value && field === "price") {
    return;
  }
  if (!value && field === "projectPrice") {
    return "The field is required";
  }
  if (value <= 0 || isNaN(value)) {
    return "Price must be a positive number";
  }
  return;
};

export const phoneNumberValidators = (value) => {
  const phoneNumberUS = /^(\+)?\d+$/;
  if (value && value !== 1 && !phoneNumberUS.test(value)) {
    return "Invalid phone number";
  }
  return undefined;
};

export const maxCharactersValidators = ({ value, field }) => {
  const isNote = field.includes("note") || field.includes("Note");
  if (value && value.length > 1000 && isNote) {
    return "Maximum number of characters allowed is 1000";
  }
  if (value && value.length > 255 && !isNote) {
    return "Maximum number of characters allowed is 255";
  }
  return;
};

export const passwordValidator = (_, value) => {
  const validationMessage = passwordValidators(value);
  if (validationMessage) {
    return Promise.reject(validationMessage);
  }
  return Promise.resolve();
};

export const confirmPasswordValidator = ({ getFieldValue }) => ({
  validator(_, value) {
    if (
      !value ||
      getFieldValue("password") === value ||
      getFieldValue("newPassword") === value
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Passwords don’t match"));
  },
});

export const phoneNumberValidator = (_, value, isRequired) => {
  const validationMessage = phoneNumberValidators(value, isRequired);
  if (validationMessage) {
    return Promise.reject(validationMessage);
  }
  return Promise.resolve();
};

export const emailValidator = (_, value) => {
  const validationMessage = emailValidators(value);
  if (validationMessage) {
    return Promise.reject(validationMessage);
  }
  return Promise.resolve();
};

export const onlyPositiveNumbersValidator = (_, inputValue) => {
  const validationMessage = onlyPositiveNumbersValidators({
    inputValue,
    field: _.field,
  });
  if (validationMessage) {
    return Promise.reject(validationMessage);
  }
  return Promise.resolve();
};

export const maxCharactersValidator = (_, value) => {
  const validationMessage = maxCharactersValidators({ value, field: _.field });
  if (validationMessage) {
    return Promise.reject(validationMessage);
  }
  return Promise.resolve();
};
