import api from "../utils/request";

export const addNewCard = async (values) => {
  try {
    const url = `/payment-methods`;
    const response = await api.post(url, values);
    return response.data.data;
  } catch (error) {
    console.error("paymentMethods - addNewCard - catch", error);
    throw error.response.data;
  }
};

export const getPaymentMethods = async () => {
  try {
    const url = `/payment-methods`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("paymentMethods - getPaymentMethods - catch", error);
    throw error.response.data;
  }
};

export const deletePaymentMethod = async (id) => {
  try {
    const url = `/payment-methods/${id}`;
    const response = await api.delete(url);
    return response.data.data;
  } catch (error) {
    console.error("paymentMethods - deletePaymentMethod - catch", error);
    throw error.response.data;
  }
};

export const makePaymentMethodDefault = async (id) => {
  try {
    const url = `/payment-methods/${id}/default`;
    const response = await api.patch(url);
    return response.data.data;
  } catch (error) {
    console.error("paymentMethods - deletePaymentMethod - catch", error);
    throw error.response.data;
  }
};

export const payDraftByCard = async (draftId, params) => {
  try {
    const url = `/payments/draft/${draftId}`;
    const response = await api.post(url, params);
    return response.data.data;
  } catch (error) {
    console.error("paymentMethods - payByCard - catch", error);
    throw error.response.data;
  }
};

export const payRevisionByCard = async (revisionId, params) => {
  try {
    const url = `/payments/revision/${revisionId}`;
    const response = await api.post(url, params);
    return response.data.data;
  } catch (error) {
    console.error("paymentMethods - payRevisionByCard - catch", error);
    throw error.response.data;
  }
};
