import { Flex } from "antd";
import Logo from "../../../components/logo";
import "../styles.css";

const AuthInfoSection = () => {
  return (
    <div className="auth-info-container display-none-tablet">
      <Flex vertical justify="center" className="auth-info-content">
        <div className="text-center auth-logo-wrapper">
          <Logo />
        </div>
        <p className="text-xxl text-white text-semibold margin-bottom-xl">
          Empower your solar vision with us. Let's get started on your journey
          to cutting-edge solar solutions
        </p>
        <p className="text-lg text-white text-semibold">
          We turn your solar dreams into reality <br />
          Our experienced engineers are ready to create your solar masterpiece
        </p>
      </Flex>
    </div>
  );
};

export default AuthInfoSection;
