import { useEffect, useState } from "react";
import { Flex, Spin, Upload } from "antd";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { message } from "../../utils/utils";
import IconWithText from "../iconWithText";
import CustomButton from "../button";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const UploadWithDragAndDrop = ({
  accept = undefined,
  multiple = true,
  uploadRequest,
  onDeleteAttachement,
  onDeleteAllAttachements,
  requestOnUpload = true,
  clientAttachments = [],
  unlimited = false,
  setList = () => {},
  currentError = "",
  setUploadErrorField = () => {},
}) => {
  const [fileList, setFileList] = useState(clientAttachments);
  const [error, setError] = useState(currentError);
  const [filesUploading, setFilesUploading] = useState([]);

  useEffect(() => {
    if (setList) {
      setList(fileList);
    }
  }, [requestOnUpload, fileList.length, setList]);

  useEffect(() => {
    setError(currentError);
  }, [currentError]);

  useEffect(() => {
    if (unlimited) {
      const element = document.getElementsByClassName("ant-upload-list");
      if (element && element[0].style) {
        element[0].style.marginTop = "20px";
      }
      if (error) {
        element[0].style.marginTop = "32px";
      } else {
      }
      if (!error && !isEmpty(fileList)) {
        element[0].style.marginTop = "20px";
        if (fileList.length === 1) {
          element[0].style.marginBottom = "16px";
        } else {
          element[0].style.marginBottom = "0";
        }
      } else {
        element[0].style.marginBottom = "0";
      }
    }
  }, [error, fileList]);

  const onChange = (params) => {
    if (!params.file.originFileObj) {
      return;
    }
    const allFiles = [...fileList, ...params.fileList];
    // setUploadErrorField();
    if (!unlimited) {
      const totalSize = allFiles.reduce((acc, curr) => {
        return acc + curr.size;
      }, 0);
      if (totalSize > 20 * 1024 * 1024) {
        setError("Files size can’t exceed 20mb");
        return false;
      }
    }
    setError("");
    if (requestOnUpload) {
      // setUploadErrorField();
      if (params.file.status === "uploading") {
        setFilesUploading(
          params.fileList.filter((file) => {
            if (file.dbName) {
              return !file.dbName;
            }
            return !file.id;
          })
        );
      }
      const formData = new FormData();
      formData.append("file", params.file.originFileObj);
      uploadRequest(formData)
        .then((response) => {
          params.file.originFileObj.dbName = response.fileName;
          params.file.originFileObj.id = response.id;
          setFileList((prevList) => [...prevList, params.file.originFileObj]);
          setFilesUploading((prevList) =>
            prevList.filter((file) => {
              if (params.file.dbName) {
                return params.file.dbName !== file.dbName;
              }
              return params.file.uid !== file.uid;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          message({
            type: "error",
            message: "Something went wrong",
          });
          setFilesUploading((prevList) =>
            prevList.filter((file) => {
              if (params.file.dbName) {
                return params.file.dbName !== file.dbName;
              }
              return params.file.uid !== file.uid;
            })
          );
        });
    } else {
      setFileList((prevList) => [...prevList, params.file.originFileObj]);
    }
  };

  const onDelete = (file) => {
    setUploadErrorField(file);
    if (requestOnUpload) {
      onDeleteAttachement(file)
        .then(() => {
          setFileList((prevList) =>
            prevList.filter((item) => item.uid !== file.uid)
          );
        })
        .catch((error) => console.error(error));
    } else {
      setFileList((prevList) =>
        prevList.filter((item) => item.uid !== file.uid)
      );
    }
  };

  const onDeleteAll = () => {
    if (requestOnUpload) {
      if (onDeleteAllAttachements) {
        onDeleteAllAttachements()
          .then(() => {
            setFileList([]);
          })
          .catch((error) => console.error(error));
        return;
      }
      fileList.forEach((file) => onDelete(file));
      return;
    }
    setFileList([]);
  };
  const renderErrorMessage = () => {
    if (!unlimited) {
      return (
        <p className={`files-max-size ${!!error ? "text-error" : "text-gray"}`}>
          {!!error ? error : "Files size can’t exceed 20mb"}
        </p>
      );
    }
    if (!!error) {
      return <p className={"files-max-size text-error"}>{error}</p>;
    }
  };

  return (
    <div
      className={`upload ${
        fileList.length > 1
      } ? "multiple-upload-container" : "upload-container"`}
    >
      {!unlimited && (
        <p className={`files-max-size ${!!error ? "text-error" : "text-gray"}`}>
          {!!error ? error : "Files size can’t exceed 20mb"}
        </p>
      )}
      {renderErrorMessage()}
      <Upload.Dragger
        name="file"
        accept={accept}
        className="upload-container text-center"
        multiple={multiple}
        customRequest={() => null}
        onChange={onChange}
        fileList={fileList}
        onRemove={onDelete}
      >
        <IconWithText
          justify="center"
          customClass="is-link"
          IconComponent={CloudUploadOutlined}
          iconClassname="text-xl"
          text="Upload File"
        />
      </Upload.Dragger>
      {filesUploading.length > 0 && (
        <div className="uploading-indicator margin-bottom-md">
          {filesUploading.map((file) => (
            <div key={file.uid}>
              <Flex
                className="uploading-file ant-upload-list-item"
                align="center"
              >
                <Spin indicator={<LoadingOutlined spin />} size="small" />
                <p className="margin-left-xxs">{file.name}</p>
              </Flex>
            </div>
          ))}
        </div>
      )}
      {fileList.length > 1 && (
        <CustomButton
          buttonTitle="Delete all"
          type="text"
          customClass="text-error delete-all-button"
          block
          onClick={onDeleteAll}
          icon={<DeleteOutlined className="delete-icon" />}
        />
      )}
    </div>
  );
};

export default UploadWithDragAndDrop;
