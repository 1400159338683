import { useEffect } from "react";
import { CalendarOutlined, CloseOutlined } from "@ant-design/icons";
import { DatePicker, Form } from "antd";
import ModalFooter from "../../../components/modal/ModalFooter";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

dayjs.extend(weekday);
dayjs.extend(localeData);

const DateFilterForm = ({
  form,
  onFinish,
  initialValue = {},
  onCancel = () => {},
  range,
  setRange,
}) => {
  const onCalendarChange = (params) => {
    setRange(params);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const el = document.querySelector(".date-filter-range-picker");
      if (el) {
        el.style.visibility = "visible";
      }
    }, 250);
    return () => clearTimeout(timeoutId);
  }, []);

  const renderIcon = () => {
    return range && range.length ? <CloseOutlined /> : <CalendarOutlined />;
  };

  return (
    <Form
      className="date-form-container"
      form={form}
      onFinish={onFinish}
      validateTrigger="onBlur"
      layout="vertical"
      requiredMark={false}
      initialValues={initialValue}
    >
      <Form.Item name="date" className="full-width-range-picker">
        <RangePicker
          popupClassName="date-filter-range-picker range-picker-popup"
          className={`full-width`}
          allowEmpty={[true, true]}
          suffixIcon={renderIcon()}
          onCalendarChange={onCalendarChange}
          defaultValue={initialValue}
          format="MM-DD-YYYY"
          open
          placement="bottomRight"
        />
      </Form.Item>
      <div className="date-modal-footer">
        <ModalFooter
          htmlType="submit"
          submitTitle="Apply"
          onCancel={onCancel}
        />
      </div>
    </Form>
  );
};

export default DateFilterForm;
