import { Badge, Flex, Radio } from "antd";
import NewFilterDropdown from "../filters/NewFilterDropdown";

const RadioButtonFilters = ({
  filterOptions = [],
  selectedItem = "",
  onFilterChange = () => {},
  customClass = "",
  type = "status",
}) => {
  const statusFilterItems = Object.keys(filterOptions).map((item) => {
    const label = filterOptions[item].label;
    return {
      label: (
        <Flex
          gap={10}
          className={`status-container status-item cursor-pointer ${
            selectedItem === item && "activeItem"
          }`}
          onClick={() => onFilterChange({ item }, type)}
        >
          <Radio checked={selectedItem === item} value={item}>
            {label}
          </Radio>
        </Flex>
      ),
      key: item,
    };
  });

  return (
    <div className="filter-badge-wrapper cursor-pointer">
      {!!selectedItem && <Badge dot color="#4D6EF2" className="filter-badge" />}
      <NewFilterDropdown
        filter={type}
        items={statusFilterItems}
        customClass={customClass}
      />
    </div>
  );
};

export default RadioButtonFilters;
