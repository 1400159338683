import { LogoutOutlined } from "@ant-design/icons";
import { logout } from "../../services/auth.js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slices/userSlice.js";
import IconWithText from "../iconWithText";
import "./styles.css";

const LogoutMenuItem = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
      .then(() => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("last-pathname");
        dispatch(setUser({ user: {} }));
      })
      .finally(() => navigate("/sign-in"));
  };
  return (
    <div className="menu-item-logout cursor-pointer" onClick={handleLogout}>
      <IconWithText text="Log Out" IconComponent={LogoutOutlined} />
    </div>
  );
};

export default LogoutMenuItem;
