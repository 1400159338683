import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profile: {},
};

const contractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    setContractorProfile(state, action) {
      state.profile = action.payload;
    },
  },
});

export const { setContractorProfile } = contractorSlice.actions;

export const selectContractorProfile = (state) => state.contractor.profile;

export default contractorSlice.reducer;
