import { useState } from "react";
import { Form } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { login } from "../../../services/auth";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput";
import IconWithText from "../../../components/iconWithText";
import CustomButton from "../../../components/button";
import CustomCard from "../../../components/card";
import CustomForm from "../../../components/form";
import get from "lodash/get";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setErrorMessage(null);
    setLoading(true);
    login(values)
      .then((response) => {
        const { accessToken, refreshToken, verified } = get(response, "data");
        if (verified) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          const lastPathname = localStorage.getItem("last-pathname");
          navigate(!!lastPathname ? lastPathname : "/projects");
          return;
        }
        navigate("/verify-email", { state: { verified, accessToken } });
      })
      .catch((error) => {
        const errorMessage =
          get(error, "response.data.message") || get(error, "response.message");
        setErrorMessage(errorMessage);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="login-form">
      <p className="auth-form-title">Sign In</p>
      <CustomForm
        form={form}
        onFinish={onFinish}
        setErrors={setErrors}
        help={errorMessage}
      >
        <FloatingLabelInput name="email" errors={errors} />
        <FloatingLabelInput name="password" errors={errors} />
        {errorMessage && (
          <CustomCard
            description={
              <IconWithText
                IconComponent={ExclamationCircleFilled}
                status="error"
                text={errorMessage}
              />
            }
          />
        )}
        <CustomButton
          buttonTitle="Sign In"
          customClass="margin-top-xl margin-bottom-xs"
          loading={loading}
          disabled={loading}
          htmlType="submit"
          block
        />
      </CustomForm>
    </div>
  );
};

export default LoginForm;
