// import { useEffect } from "react";
import "./styles.css";

const InfoField = ({ field = "", value = "" }) => {
  // useEffect(() => {
  //   const func = function () {
  //     let fields = document.querySelectorAll(".info-field");
  //     let maxWidth = 0;
  //     fields.forEach(function (field) {
  //       maxWidth = Math.max(maxWidth, field.offsetWidth);
  //     });

  //     fields.forEach(function (field) {
  //       field.style.width = maxWidth + "px";
  //     });
  //   };
  //   func();
  // }, []);

  return (
    <div className="info-field-container">
      <div className="info-item">
        <div className="info-field">{field}</div>
        <div className="info-value text-black">{value}</div>
      </div>
    </div>
  );
};

export default InfoField;
