import "./styles.css";

const PageTitle = ({ title = "" }) => {
  return (
    <div
      className={`page-title text-semibold margin-bottom-xxxl mobile-mode-text-center`}
    >
      {title}
    </div>
  );
};

export default PageTitle;
