import api from "../utils/request";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const downloadFile = async (url = "") => {
  try {
    const fileUrl = API_BASE_URL + url;
    const response = await api.get(fileUrl, { responseType: "blob" });
    return response;
  } catch (error) {
    console.error("downloadFile - catch", error);
    throw error;
  }
};
