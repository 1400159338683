import { ProjectOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice.js";
import { getDraftTypeLabel } from "../../../utils/drafts.js";
import InfiniteScrollCards from "../../../components/infiniteScrollCards/index.js";

const TransactionsMobile = ({ data, fetchData, loading }) => {
  const { role } = useSelector(selectUser);
  const isAdmin = role === "admin";
  const fields = {
    projectName: {
      icon: ProjectOutlined,
    },
    ...(isAdmin && {
      companyName: {
        label: "Company",
        url: ({ contractorId }) => `/contractors/${contractorId}`,
      },
    }),
    targetType: {
      label: "Service Type",
      render: ({ targetType }) => <p>{getDraftTypeLabel(targetType)}</p>,
    },
    createdAt: {
      label: "Created Date",
      isDate: true,
    },
    price: {
      label: "Price",
    },
    type: {
      label: "Type",
      url: ({ targetId, type }) =>
        type === "Draft"
          ? `/projects/${targetId}/details`
          : `/revisions/${targetId}`,
      onClick: () => {},
    },
  };
  if (!isAdmin) {
    delete fields.companyName;
  }

  return (
    <div className="margin-top-xl">
      <InfiniteScrollCards
        data={data}
        fetchMoreData={fetchData}
        fields={fields}
        loading={loading}
      />
    </div>
  );
};

export default TransactionsMobile;
