import api from "../utils/request";

export const getRevisionsListByAdmin = async ({
  draftId = "",
  offset = 0,
  limit = 25,
  status = "",
}) => {
  try {
    let url = `/admin/revisions/list/${draftId}?offset=${offset}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("revisions - getRevisionsListByContractor - catch", error);
    throw error;
  }
};

export const getRevisionsListByContractor = async ({
  draftId = "",
  offset = 0,
  limit = 25,
  status = "",
}) => {
  try {
    let url = `/revisions/by-draft/${draftId}?offset=${offset}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("revisions - getRevisionsListByContractor - catch", error);
    throw error;
  }
};

export const sendRevision = async (draftId = "", formData) => {
  try {
    const url = `/revisions/by-draft/${draftId}`;
    const response = await api.post(url, formData);
    return response.data.data;
  } catch (error) {
    console.error("revisions - sendRevision - catch", error);
    throw error.response.data;
  }
};

export const getRevisionByAdmin = async (revisionId = "") => {
  try {
    const url = `/admin/revisions/${revisionId}`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("revisions - getRevisionByAdmin - catch", error);
    throw error.response.data;
  }
};

export const getRevisionByContractor = async (revisionId = "") => {
  try {
    const url = `/revisions/${revisionId}`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("revisions - getRevisionByContractor - catch", error);
    throw error.response.data;
  }
};

export const sendRevisionPriceByAdmin = async (
  revisionId = "",
  values = {}
) => {
  try {
    const url = `/admin/revisions/${revisionId}/resolve`;
    const response = await api.put(url, values);
    return response.data.data;
  } catch (error) {
    console.error("revisions - sendRevisionPriceByAdmin - catch", error);
    throw error.response.data;
  }
};

export const assignByRevisionId = async (id = "", values = {}) => {
  try {
    const url = `/admin/revisions/${id}/assign`;
    const response = await api.post(url, values);
    return response.data.data;
  } catch (error) {
    console.error("revisions - assignByRevisionId - catch", error);
    throw error.response.data;
  }
};
