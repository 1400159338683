import { useState } from "react";
import { Form } from "antd";
import { register } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/button";
import Agreement from "./Agreement";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput";
import CustomForm from "../../../components/form";

const items = [
  "fullName",
  "email",
  "phoneNumber",
  "password",
  "confirmPassword",
];

const RegisterForm = () => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onFinish = (userData) => {
    setLoading(true);
    const phone = userData.phoneNumber || userData.phoneNumberNotRequired;
    if (phone) {
      userData.phoneNumber = `${phone}`;
    }
    register(userData)
      .then(() => navigate("/verify-email"))
      .catch((error) => {
        const errors = error.response.data.validationErrors;
        Object.keys(errors).forEach((fieldName) => {
          form.setFields([
            {
              name: fieldName,
              errors: [errors[fieldName][0]],
            },
          ]);
          const responseErrors = form
            .getFieldsError()
            .filter((i) => i.errors.length ?? i);
          setErrors(responseErrors);
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <p className="auth-form-title margin-top-sm">Create Account</p>
      <CustomForm form={form} setErrors={setErrors} onFinish={onFinish}>
        {items.map((item) => (
          <FloatingLabelInput
            key={item}
            name={item}
            errors={errors}
            autoComplete="new-password"
          />
        ))}
        <Agreement setChecked={setChecked} />
        <CustomButton
          buttonTitle="Sign Up"
          customClass="margin-top-xl margin-bottom-xs"
          disabled={!checked || loading}
          loading={loading}
          htmlType="submit"
          block
        />
      </CustomForm>
    </>
  );
};

export default RegisterForm;
