import { Result } from "antd";
import CustomButton from "../button";
import "./styles.css";

const CustomResult = ({ status, text, icon, onButtonClick, buttonText }) => {
  const customClass = status === false ? "error" : status ? "success" : "";

  return (
    <Result
      className={`result-wrapper ${customClass}`}
      status={status ? "success" : "error"}
      icon={icon}
      subTitle={<p className="text-sm text-black">{text}</p>}
      extra={
        buttonText && (
          <CustomButton buttonTitle={buttonText} onClick={onButtonClick} />
        )
      }
    />
  );
};

export default CustomResult;
