import { useEffect, useState } from "react";
import { Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompany,
  selectUser,
  setCompany,
} from "../../redux/slices/userSlice.js";
import { useParams } from "react-router-dom";
import {
  getContractorCompanyById,
  getContractorProfileById,
} from "../../services/contractor.js";
import { setContractorProfile } from "../../redux/slices/contractorSlice.js";
import { getCompany } from "../../services/user.js";
import { message } from "../../utils/utils.js";
import ProfileInfo from "./components/ProfileInfo.js";
import CompanyProfileInfo from "./components/CompanyProfileInfo.js";
import PageTitle from "../../components/pageTitle/index.js";
import Loading from "../../components/loading/index.js";
import PasswordManagement from "./components/PasswordManagement.js";
import isEmpty from "lodash/isEmpty.js";
import get from "lodash/get";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const company = useSelector(selectCompany);
  const { role } = useSelector(selectUser);
  const { contractorId } = useParams();
  const dispatch = useDispatch();
  const isAdmin = role === "admin";

  useEffect(() => {
    if (contractorId) {
      getContractorProfileById(contractorId)
        .then((profile) =>
          dispatch(setContractorProfile({ ...profile, id: contractorId }))
        )
        .catch((error) =>
          console.error("getContractorProfileById --- ERROR", error)
        );
      getContractorCompanyById(contractorId)
        .then((contractorCompany) =>
          dispatch(
            setCompany({
              company: {
                ...contractorCompany,
                contractorId: contractorCompany.userId,
              },
            })
          )
        )
        .catch((error) =>
          console.error("getContractorCompanyById --- ERROR", error)
        )
        .finally(() => setLoading(false));
    } else {
      if (!isAdmin) {
        getCompany()
          .then((data) => {
            dispatch(setCompany({ company: data }));
          })
          .catch((err) => {
            console.error("Company ERROR --- ", err);
            const error = get(err, "response.data.message");
            if (error !== "Company not found") {
              message({
                type: "error",
                message: error,
              });
            }
            dispatch(setCompany({ company: {} }));
          })
          .finally(() => setLoading(false));
        return;
      }
      setLoading(false);
      dispatch(setCompany({ company: {} }));
      dispatch(setContractorProfile({}));
    }
    return () => {
      dispatch(setContractorProfile({}));
      dispatch(setCompany({ company: {} }));
      setLoading(false);
    };
  }, [contractorId, dispatch, setLoading]);

  if (loading) {
    return (
      <div className="page-loading-container">
        <Loading />
      </div>
    );
  }
  return (
    <div>
      <PageTitle title="Profile Management" />
      <Flex justify="center" className="profile-content-wrapper">
        {isAdmin && isEmpty(company) ? (
          <Flex className="profile-content" gap={24}>
            <ProfileInfo />
            <div className="profile-content-child">
              {!contractorId && <PasswordManagement />}
            </div>
          </Flex>
        ) : (
          <Flex gap={24} className="profile-content">
            <Flex gap={24} vertical className="profile-content-child">
              <ProfileInfo />
              {(!isAdmin || !contractorId) && <PasswordManagement />}
            </Flex>
            {(!isAdmin || !isEmpty(company)) && (
              <CompanyProfileInfo company={company} />
            )}
          </Flex>
        )}
      </Flex>
    </div>
  );
};

export default Profile;
