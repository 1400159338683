import { Flex } from "antd";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice.js";

const Statuses = ({ status = "", currentOptions = {} }) => {
  const { role } = useSelector(selectUser);

  if (!status) {
    return null;
  }
  const { color , label } = currentOptions[status];
  if (role === "admin") {
    return (
      <div className="statuses-container">
        <Flex gap={4} align="center">
          <div
            style={{
              width: 12,
              height: 12,
              borderRadius: 6,
              backgroundColor: color,
            }}
          />
          <p
            style={{ color: color }}
            className="text-xs"
          >
            {label}
          </p>
        </Flex>
      </div>
    );
  }
  return (
    <div className="statuses-container">
      {Object.keys(currentOptions).map((item) => {
        const isCurrent = status === item;
        const currentColor = isCurrent
          ? color
          : "#7A7A7A";
        return (
          <Flex gap={4} align="center" key={item}>
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: 6,
                backgroundColor: isCurrent ? color : "unset",
                border: !isCurrent ? "1px solid #7A7A7A" : "unset",
              }}
            />
            <p style={{ color: `${currentColor}` }} className="text-xs">
              {currentOptions[item].label}
            </p>
          </Flex>
        );
      })}
    </div>
  );
};

export default Statuses;
