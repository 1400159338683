import { useLocation, useNavigate } from "react-router-dom";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Result from "../../components/result";

const UpdatePasswordResult = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const success = (state || {}).success;

  const onButtonClick = () => {
    if (success) {
      navigate("/sign-in");
      return;
    }
    navigate("/forgot-password");
  };

  const buttonText = success ? "Continue" : "Try Again";

  return (
    <div className="wrapper">
      <div className="auth-forms-section-content">
        <Result
          status={success}
          text={
            <p className="margin-sm text-bold">
              {success
                ? "Your password has been changed"
                : "Sorry, the link is no longer valid or has already been used"}
            </p>
          }
          onButtonClick={onButtonClick}
          buttonText={buttonText}
          icon={!success ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
        />
      </div>
    </div>
  );
};

export default UpdatePasswordResult;
