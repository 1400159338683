import api from "../utils/request";

export const getContractors = async ({
  offset = 0,
  limit = 25,
  searchQuery = "",
  deactivated = false,
}) => {
  try {
    let url = `/users/contractors?offset=${offset}&limit=${limit}`;
    if (deactivated) {
      url += "&deactivated=true";
    }
    if (searchQuery) {
      url += `&query=${searchQuery}`;
    }

    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("contractors - getContractors - catch", error);
    throw error;
  }
};

export const getContractorProfileById = async (id) => {
  try {
    const url = `/users/contractors/${id}/profile`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("contractors - getContractorProfileById - catch", error);
    throw error;
  }
};

export const updateContractorProfileById = async (values, id) => {
  const url = `/users/contractors/profile/${id}`;
  try {
    const company = await api.put(url, values);
    return company.data.data;
  } catch (error) {
    console.error(" ---- user - updateCompany - catch", error);
    throw error;
  }
};

export const getContractorCompanyById = async (id) => {
  try {
    const url = `/users/contractors/${id}/company`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("contractors - getContractorCompanyById - catch", error);
    throw error;
  }
};

export const updateContractorCompanyById = async (values, id) => {
  const url = `/users/contractors/company/${id}`;
  try {
    const company = await api.put(url, values);
    return company.data.data;
  } catch (error) {
    console.error(" ---- user - updateContractorCompanyById - catch", error);
    throw error;
  }
};

export const deactivateContractorByAdmin = async (id) => {
  try {
    const url = `/users/contractors/${id}/deactivate`;
    const response = await api.patch(url);
    return response;
  } catch (error) {
    console.error("contractors - deactivateContractorByAdmin - catch", error);
    throw error;
  }
};

export const restoreContractorByAdmin = async (id) => {
  try {
    const url = `/users/contractors/${id}/activate`;
    const response = await api.patch(url);
    return response;
  } catch (error) {
    console.error("contractors - activateContractorByAdmin - catch", error);
    throw error;
  }
};

export const getContractorsTotalCount = async () => {
  try {
    const url = `/users/contractors/total-number`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("contractors - getContractorsTotalCount - catch", error);
    throw error;
  }
};
