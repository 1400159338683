import { Tag } from "antd";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const Status = ({ status = "", currentOptions = {} }) => {
  if(isEmpty(currentOptions) || !status){
    return null
  }
  const { label, listColor } = currentOptions[status];
  return (
    <Tag
      color={status === "completed_project" ? "geekblue" : listColor}
      className="status-tag"
    >
      {label}
    </Tag>
  );
};

export default Status;
