import { useCallback, useState } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

const useSetUploadError = ({ form }) => {
  const [errors, setErrors] = useState([]);
  const [filesList, setFilesList] = useState([]);

  const setUploadErrorField = useCallback(() => {
    const currentErrors = form.getFieldsError();
    const uploadError = {
      name: ["upload"],
      errors: ["The field is required"],
    };

    if (isEmpty(filesList)) {
      const updatedErrors = currentErrors
        .map((error) => (error.name[0] === "upload" ? uploadError : error))
        .filter((error) => !!error.errors[0]);

      form.setFields(updatedErrors);
      setErrors(updatedErrors);

      return uploadError;
    } else {
      const filteredErrors = currentErrors.filter(
        (error) => error.name[0] !== "upload" || !error.errors[0]
      );

      form.setFields(filteredErrors);
      setErrors(filteredErrors);

      return {
        name: ["upload"],
        errors: [""],
      };
    }
  }, [filesList, form]);

  const currentError = get(
    errors.find((error) => error.name[0] === "upload"),
    "errors[0]",
    ""
  );
  return {
    errors,
    setErrors,
    filesList,
    setFilesList,
    currentError,
    setUploadErrorField,
  };
};

export default useSetUploadError;
