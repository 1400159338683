import { useState } from "react";
import { Button, Divider, Form, Input, Select, Space } from "antd";
import { selectInputs } from "../../utils/formItems";
import { PlusOutlined } from "@ant-design/icons";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const FloatingLabelSelectItem = ({
  name,
  options = [],
  onChange = () => {},
  errors = [],
  initialValue = "",
  currentError = false
}) => {
  const [newItem, setNewItem] = useState("");
  const [hide, setHide] = useState(false);
  const inputConfig = selectInputs[name];
  const { rules = [], placeholder, defaultOptions, label } = inputConfig;
  const form = Form.useFormInstance();
  
  const [value, setValue] = useState(initialValue || form.getFieldValue(name));
  const [focus, setFocus] = useState(false);

  const onInputChange = (event) => {
    setNewItem(event.target.value);
  };
  const onAddNewItem = (e) => {
    const item = newItem.trim();
    if (!item || options.includes(item)) {
      return;
    }
    options.push(item);
    setNewItem("");
  };

  if (!inputConfig) {
    console.error(`Invalid input name: ${name}`);
    return null;
  }
  const currentOptions = !isEmpty(defaultOptions) ? defaultOptions : options;

  const hideLabel = hide || focus || value;

  const handleAutoFill = (e) => {
    setHide(e.animationName === "onAutoFillStart");
  };

  const renderFieldError = () => {
    return errors.some((error) => {
      if (error.name[0] === name) {
        return true;
      }
      return false;
    });
  };

  const errorMessage = errors.find((error) => error.name[0] === name)
    ?.errors[0];
  const hasError = currentError || !!form.getFieldError(name).length;
  
  const renderInput = () => {
    return (
      <Select
        value={value}
        className={`form-select ${!!hasError ? "form-select-error" : ""}`}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onAnimationStart={handleAutoFill}
        onChange={(e) => {
          onChange(e);
          return setValue(e);
        }}
        virtual={false}
        dropdownRender={(menu) => (
          <>
            {menu}
            {isEmpty(defaultOptions) && (
              <>
                <Divider className="add-new-item-divider" />
                <Space className="add-new-item-wrapper">
                  <Input
                    placeholder="Add new option"
                    value={newItem}
                    onChange={onInputChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={onAddNewItem}
                  >
                    Add item
                  </Button>
                </Space>
              </>
            )}
          </>
        )}
        options={currentOptions.map((item) => ({
          label: item,
          value: item,
        }))}
      />
    );
  };

  return (
    <div className="floating-wrapper">
      <label className="input_container">
        <span
          className={`label ${hideLabel ? "hide" : ""} ${
            !!renderFieldError() && hideLabel ? "text-error" : ""
          }`}
        >
          {placeholder}
        </span>
        <Form.Item
          name={name}
          rules={rules}
          label={label}
          initialValue={initialValue}
        >
          {renderInput()}
        </Form.Item>
      </label>
      <p className={`form-item-error text-error ${errorMessage ? "show" : ""}`}>
        {errorMessage}
      </p>
    </div>
  );
};

export default FloatingLabelSelectItem;
