import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deletePaymentMethod,
  getPaymentMethods,
  makePaymentMethodDefault,
  payDraftByCard,
  payRevisionByCard,
} from "../../services/paymentMethods";
import { message } from "../../utils/utils";
import AddNewCardForm from "./components/AddNewCardForm";
import PageTitle from "../../components/pageTitle";
import CustomDrawer from "../../components/drawer";
import Loading from "../../components/loading";
import PaymentMethodsItems from "./components/PaymentMethodsItems";
import "./styles.css";

const PaymentMethods = ({ pageTitle = "" }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newCardModalOpen, setNewCardModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const { draftId, revisionId } = useParams();
  const { state } = useLocation();

  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    getPaymentMethods()
      .then((data) => {
        data.map((item) => item.default && setSelectedItemId(item.id));
        setPaymentMethods(data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDefault = (id) => {
    setSelectedItemId(id);
    makePaymentMethodDefault(id).catch(console.error);
  };

  const handleSelectForPaying = (id) => {
    setSelectedItemId(id);
  };

  const handleCancel = () => {
    setNewCardModalOpen(false);
  };

  const onPay = () => {
    const id = draftId || revisionId;
    if (!id) {
      return;
    }
    const currentDraftId = draftId || (state || {}).draftId;
    const fetchRequest = draftId ? payDraftByCard : payRevisionByCard;
    fetchRequest(id, { paymentMethodId: selectedItemId })
      .then(() => {
        message({
          message: "Your payment has been successfully processed",
          type: "success",
        });
      })
      .catch((error) => {
        console.error({ error });
        message({ message: "Payment has been rejected", type: "error" });
      })
      .finally(() =>
        navigate(
          draftId
            ? `/projects/${currentDraftId}/drawing`
            : `/revisions/${revisionId}`,
          {
            state: { refetch: true },
          }
        )
      );
  };

  const onCardDelete = async (id) => {
    return deletePaymentMethod(id)
      .then(() => {
        setPaymentMethods((prevMethods) =>
          prevMethods.filter((card) => card.id !== id)
        );
        setSelectedItemId(
          (paymentMethods.filter((card) => card.id !== id)[0] || {}).id
        );
        message({
          message: "Your credit card has been successfully deleted",
          type: "success",
        });
      })
      .catch((error) => {
        console.error("onCardDelete --- error", error);
        message({ message: "Something went wrong", type: "error" });
      });
  };

  if (loading) {
    return (
      <div className="page-loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <PageTitle title={pageTitle} />
      <PaymentMethodsItems
        paymentMethods={paymentMethods}
        handleDefault={handleDefault}
        handleSelectForPaying={handleSelectForPaying}
        onPay={onPay}
        onAddClick={() => setNewCardModalOpen(true)}
        onCardDelete={onCardDelete}
        selectedItemId={selectedItemId}
      />
      <CustomDrawer
        title="Add a Credit Card"
        open={newCardModalOpen}
        onCancel={handleCancel}
        content={
          <AddNewCardForm onCancel={handleCancel} updateForm={fetchData} />
        }
      />
    </div>
  );
};

export default PaymentMethods;
