import { useEffect, useState } from "react";
import { Layout } from "antd";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { findOpenKeys, privateMenuItems } from "../../utils/menuItems";
import { useWindowSize } from "../../hooks/useWindowSize";
import { selectUser } from "../../redux/slices/userSlice.js";
import { useSelector } from "react-redux";
import Logo from "../logo/index.js";
import PageHeader from "../header";
import LogoutMenuItem from "../logoutMenuItem/index.js";
import CustomMenu from "../menu/index.js";
import "./styles.css";

const { Content, Sider } = Layout;

const PageLayout = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { role } = useSelector(selectUser);
  const { isMobile } = useWindowSize();
  const accessToken = localStorage.getItem("accessToken");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let pathname = location.pathname;
    setActiveMenuItem(pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const currentPath =
    location.pathname !== "/" ? location.pathname : "/projects";

  if (!accessToken) {
    return <Navigate to="/sign-in" />;
  }
  if (location.pathname === "/") {
    return <Navigate to="/projects" />;
  }

  const onMenuItemClick = ({ key }) => {
    if (key === currentPath) {
      setShouldRefresh(true);
    }
    navigate(key);
  };

  // const defaultOpenKeys = findOpenKeys(privateMenuItems(role), currentPath);
  const defaultOpenKeys = ["contractors", "projects", "/payments-section"];
  return (
    <Layout className="page-layout">
      {!isMobile && (
        <Sider
          className="page-sider"
          width={240}
          breakpoint="md"
          collapsedWidth="0"
        >
          <div className="menu-logo-wrapper">
            <Logo />
          </div>
          <CustomMenu
            defaultSelectedKeys={[currentPath]}
            defaultOpenKeys={defaultOpenKeys}
            items={privateMenuItems(role)}
            selectedKeys={[activeMenuItem]}
            onMenuItemClick={onMenuItemClick}
          />
          <LogoutMenuItem />
        </Sider>
      )}
      <Layout className="page-content-container">
        {isMobile && <PageHeader />}
        <Content className="page-layout-content">
          <Outlet key={shouldRefresh ? "refreshed" : "original"} />
        </Content>
      </Layout>
    </Layout>
  );
};
export default PageLayout;
