import { Flex } from "antd";
import IconWithText from "../iconWithText";
import "./styles.css";

const InfoItem = ({ title = "", value = "", icon }) => {
  return (
    <Flex vertical align="center" gap={24} className="info-item text-center text-semibold-light">
      <IconWithText IconComponent={icon} text={title} iconClassname="text-lg text-primary"/>
      <p className="text-lg text-black text-semibold">{value}</p>
    </Flex>
  );
};

export default InfoItem;
