import { Flex } from "antd";
import CustomButton from "../button";

const ModalFooter = ({
  submitTitle = "Deactivate",
  cancelTitle = "Cancel",
  loading = false,
  disabled = false,
  onSubmit = () => {},
  onCancel = () => {},
  htmlType,
  type,
  cancelType = "default",
  block = false,
  danger = false,
}) => {
  return (
    <Flex className="modal-custom-footer" gap={16}>
      <CustomButton
        type={type}
        buttonTitle={submitTitle}
        disabled={disabled}
        onClick={onSubmit}
        htmlType={htmlType}
        loading={loading}
        block={block}
        danger={danger}
      />
      <CustomButton
        type={cancelType}
        buttonTitle={cancelTitle}
        onClick={onCancel}
      />
    </Flex>
  );
};

export default ModalFooter;
