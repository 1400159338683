import { Modal } from "antd";
import "./styles.css";

const CustomModal = ({ title, content, onOk, open, onCancel, okText, footer, closeIcon = false, maskClosable = true, customClass }) => {
  return (
    <Modal
      className={`modal ${customClass ? customClass : ""}`}
      centered
      open={open}
      title={title}
      okText={okText}
      closeIcon={closeIcon}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      destroyOnClose
      maskClosable={maskClosable}
    >
      {content}
    </Modal>
  );
};

export default CustomModal;
