import { Badge, Spin, Tag } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { getDraftTypeLabel } from "../../../utils/drafts";
import Text from "../../../components/typography";
import Status from "../../../components/status";
import moment from "moment";

export const columns = (
  isAdmin,
  submitted,
  onEditClick,
  onDeleteClick,
  statuses
) =>
  [
    {
      title: "Project",
      isSmall: true,
      dataIndex: "name",
      render: ({ name, id }) => (
        <Text text={name} src={`/projects/${id}/details`} isLink={submitted} />
      ),
    },
    {
      title: "Service Type",
      isSmall: true,
      dataIndex: "draftTypes",
      render: ({ draftTypes }) => <Text text={getDraftTypeLabel(draftTypes)} />,
    },
    isAdmin && {
      title: "Company",
      isSmall: true,
      dataIndex: "companyName",
      render: ({ companyName, userId }) => (
        <Text text={companyName} src={`/contractors/${userId}`} isLink />
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      isSmall: true,
      render: ({ createdAt }) => <Text text={moment(createdAt).format("L")} />,
    },
    submitted && {
      title: "Price",
      dataIndex: "totalPrice",
      isSmall: true,
      render: ({ totalPrice, status }) => (
        <p className={status === "completed_project" ? "text-error" : ""}>
          {totalPrice}
        </p>
      ),
    },
    submitted && {
      title: "Revisions",
      dataIndex: "revisions",
      isSmall: true,
      render: ({ revisions, newRevisions = 0 }) => (
        <Badge count={newRevisions} color="#4D6EF2">
          <Tag className="revision-tag">{revisions}</Tag>
        </Badge>
      ),
    },
    submitted && {
      title: "Status",
      isSmall: true,
      dataIndex: "status",
      render: ({ status }) => (
        <div className="status-container">
          <Status status={status} currentOptions={statuses} />
        </div>
      ),
    },
    !isAdmin &&
      !submitted && {
        title: "Edit",
        dataIndex: "edit",
        isSmall: true,
        render: ({ id }, loading, currentId) => (
          <div
            className="edit-action-icon-wrapper"
            onClick={() => onEditClick(id)}
          >
            {!loading ? (
              <EditOutlined className="is-link text-xl" />
            ) : currentId === id ? (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            ) : (
              <EditOutlined className="is-link text-xl" />
            )}
          </div>
        ),
      },
    !isAdmin &&
      !submitted && {
        title: "Delete",
        dataIndex: "delete",
        isSmall: true,
        render: ({ id }) => (
          <div onClick={() => onDeleteClick(id)}>
            <DeleteOutlined className="cursor-pointer text-xl text-error" />
          </div>
        ),
      },
  ].filter(Boolean);
