import { Space } from "antd";
import { Link } from "react-router-dom";

const LoginActions = () => {
  return (
    <div className="space-between">
      <Space>
          <Link to="/forgot-password">
            <p className="is-link text-sm">Forgot Password?</p>
          </Link>
        </Space>
    </div>
  );
};

export default LoginActions;
