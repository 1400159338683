import api from "../utils/request";

export const getCompany = async () => {
  try {
    const company = await api.get("/company");
    return company.data.data;
  } catch (error) {
    console.error("user - getCompany - catch", error);
    throw error;
  }
};

export const createCompany = async (formData) => {
  try {
    const company = await api.post("/company", formData);
    return company.data.data;
  } catch (error) {
    console.error("user - createCompany - catch", error);
    throw error;
  }
};

export const updateCompany = async (formData) => {
  try {
    const company = await api.put("/company", formData);
    return company.data.data;
  } catch (error) {
    console.error(" ---- user - updateCompany - catch", error);
    throw error;
  }
};

export const getProfile = async () => {
  try {
    const company = await  api.get("/profile");
    return company.data.data;
  } catch (error) {
    console.error("user - getProfile - catch", error);
    throw error;
  }
};

export const updateProfile = async (values) => {
  try {
    const company = await api.put("/profile", values);
    return company.data.data;
  } catch (error) {
    console.error(" ---- user - updateCompany - catch", error);
    throw error;
  }
};

export const changePassword = async (values) => {
  try {
    const response = await api.put("/profile/password", values)
    return response.data.data;
  } catch (error) {
    console.error(" ---- user - changePassword - catch", error);
    throw error;
  }
}