import { Divider, Flex } from "antd";
import { useWindowSize } from "../../hooks/useWindowSize";
import InfoItem from "./InfoItem";
import "./styles.css";

const GeneralInfo = ({ items = [] }) => {
  const { isMobile } = useWindowSize();
  return (
    <Flex className="general-info-container" align="center" vertical={isMobile}>
      {items.map((item, index) => (
        <Flex key={index} vertical={isMobile}>
          <div>
            <InfoItem
              title={item.title}
              value={item.value}
              icon={item.icon}
            />
          </div>
          {index !== items.length - 1 && (
            <Divider
              type={!isMobile ? "vertical" : "horizontal"}
              className="info-divider"
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default GeneralInfo;
