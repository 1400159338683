import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Flex } from "antd";
import { DownOutlined, ProjectOutlined } from "@ant-design/icons";
import { statuses } from "../../../utils/utils.js";
import { useTableMethods } from "../../../hooks/useTableMethods.js";
import { useWindowSize } from "../../../hooks/useWindowSize.js";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice.js";
import { apiByRole } from "../../../utils/apiByRole.js";
import Text from "../../../components/typography";
import Status from "../../../components/status";
import RadioButtonFilters from "../../../components/radioButtonFilters/index.js";
import CustomTable from "../../../components/table/index.js";
import CustomDrawer from "../../../components/drawer/index.js";
import FiltersForm from "./FiltersForm.js";
import IconWithText from "../../../components/iconWithText/index.js";
import InfiniteScrollCards from "../../../components/infiniteScrollCards/index.js";
import isEmpty from "lodash/isEmpty.js";
import moment from "moment";
import "../styles.css";
const defaultFilters = { status: "" };

const Revisions = ({ draftId = "", draftName = "", refetch = false }) => {
  const { isMobile } = useWindowSize();
  const { role } = useSelector(selectUser);
  const fetchRequest = apiByRole[role].revisions.getRevisions;
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const status = searchParams.get("status") || "";
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const {
    data,
    currentPage,
    loading,
    fetchData,
    handleFilterChange,
    handlePaginationChange,
    handleResetFilter,
  } = useTableMethods({
    fetchRequest,
    draftId: draftId,
    defaultFilters,
  });

  useEffect(() => {
    if (!!refetch) {
      fetchData();
    }
  }, [refetch]);

  const columns = [
    {
      title: "N",
      dataIndex: "index",
      key: "index",
      isSmall: true,
      render: ({ index }) => <Text text={index} />,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      isSmall: true,
      render: ({ createdAt }) => (
        <Text text={moment(createdAt).format("L")} minWidth="max-content" />
      ),
    },
    {
      title: "Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      isSmall: true,
      render: ({ totalPrice, status }) => (
        <p className={status === "need_payment" ? "text-error" : ""}>
          {totalPrice}
        </p>
      ),
    },
    {
      title: "Status",
      isSmall: true,
      dataIndex: "status",
      render: ({ status }) => (
        <div className="status-container">
          <Status status={status} currentOptions={filterOptions} />
        </div>
      ),
    },
    {
      title: "Details",
      dataIndex: "id",
      key: "id",
      isSmall: true,
      render: ({ id }) => (
        <Link to={`/revisions/${id}`} state={{ draftId, draftName }}>
          View
        </Link>
      ),
    },
  ];
  if (isEmpty(data)) {
    return null;
  }
  const fields = {
    index: {
      label: "Revision Number",
    },
    createdAt: {
      label: "Created Date",
      isDate: true,
    },
    totalPrice: {
      label: "Price",
      render: ({ totalPrice, status }) => (
        <p className={status === "need_payment" ? "text-error" : ""}>
          {totalPrice}
        </p>
      ),
    },
    status: {
      label: "Status",
      render: ({ status }) => {
        return (
          <Status
            className="revision-tag"
            status={status}
            currentOptions={filterOptions}
          />
        );
      },
    },
  };

  const filterOptions = statuses[role].revision;

  const onFilterChange = ({ item }) => {
    setSelectedStatus(item);
    searchParams.set("status", item);
    handleFilterChange({ filterName: "status", value: item });
  };
  const onFiltersReset = () => {
    handleResetFilter("status");
    setSelectedStatus("");
  };

  const onFilterFinish = ({ status }) => {
    setFilterModalOpen(false);
    onFilterChange({ item: status });
  };

  const renderActions = ({ id }) => {
    const arr = [];
    arr.push(
      <div
        className="is-link"
        onClick={() =>
          navigate(`/revisions/${id}`, { state: { draftId, draftName } })
        }
      >
        <IconWithText
          text="View Details"
          IconComponent={ProjectOutlined}
          styles={{ justifyContent: "center" }}
        />
      </div>
    );
    return arr;
  };

  return (
    <div className="revisions-container">
      <div className="revisions-filters-container">
        <Flex gap={24} align="center">
          {selectedStatus && (
            <p className="is-link text-md" onClick={onFiltersReset}>
              Clear Filters
            </p>
          )}
          {!isMobile ? (
            <RadioButtonFilters
              filterOptions={filterOptions}
              selectedItem={selectedStatus}
              onFilterChange={onFilterChange}
              customClass="revisions-filters"
            />
          ) : (
            <div
              className="filter-badge-wrapper"
              onClick={() => setFilterModalOpen(true)}
            >
              {selectedStatus && (
                <Badge dot color="#4D6EF2" className="filter-badge" />
              )}
              <IconWithText
                customClass="row-reverse text-gray"
                text="Status"
                IconComponent={DownOutlined}
              />
            </div>
          )}
        </Flex>
      </div>
      {isMobile ? (
        <InfiniteScrollCards
          data={data}
          fetchMoreData={fetchData}
          fields={fields}
          loading={loading}
          renderActions={renderActions}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={data}
          loading={loading}
          handlePaginationChange={handlePaginationChange}
          currentPage={currentPage || 1}
          customClass="scrollable-container-revisions"
        />
      )}
      <CustomDrawer
        open={filterModalOpen}
        onCancel={() => setFilterModalOpen(false)}
        title="Status"
        content={
          <FiltersForm
            onFilterFinish={onFilterFinish}
            onCancel={() => setFilterModalOpen(false)}
            initialValues={{ status: selectedStatus }}
            filterOptions={filterOptions}
          />
        }
      />
    </div>
  );
};

export default Revisions;
