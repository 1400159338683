import { useState } from "react";
import { Form } from "antd";
import useSetUploadError from "../../../hooks/useSetUploadError";
import UploadWithDragAndDrop from "../../../components/uploadWithDragAndDrop";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput";
import CustomForm from "../../../components/form";
import ModalFooter from "../../../components/modal/ModalFooter";

const SendRevisionsForm = ({ onFinish, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { filesList, setFilesList, currentError, setUploadErrorField } =
    useSetUploadError({ form });

  const beforeFinish = async (values) => {
    if (setUploadErrorField().errors[0]) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    if (values.note) {
      formData.append("notes", values.note);
    }
    filesList.forEach((file) => {
      formData.append("files", file);
    });
    onFinish(formData).finally(() => setLoading(false));
  };

  return (
    <div className="forms-container">
      <CustomForm
        className="full-width"
        form={form}
        onFinish={beforeFinish}
        // setErrors={setErrors}
        setUploadErrorField={setUploadErrorField}
      >
        <Form.Item name="upload">
          <UploadWithDragAndDrop
            requestOnUpload={false}
            setList={setFilesList}
            unlimited
            setUploadErrorField={setUploadErrorField}
            currentError={currentError}
          />
        </Form.Item>
        <FloatingLabelInput name="note" />
        <div className="margin-top-xxxl">
          <ModalFooter
            htmlType="submit"
            submitTitle="Submit"
            onCancel={onCancel}
            loading={loading}
            disabled={loading}
          />
        </div>
      </CustomForm>
    </div>
  );
};

export default SendRevisionsForm;
