import { useLocation, useNavigate } from "react-router-dom";
import Result from "../../components/result";
import api from "../../utils/request";
import get from "lodash/get";

const DoVerifyInfo = () => {
  const { state = {} } = useLocation();
  const text = (state || {}).content || "Please verify your email!";
  const navigate = useNavigate();
  const verified = (state || {}).verified;
  const accessToken = (state || {}).accessToken;
  let buttonText;

  if (accessToken && verified === false) {
    buttonText = "Resend Link";
  }
  const onButtonClick = () => {
    localStorage.setItem("accessToken", accessToken);
    api.get("/auth/verify_email").catch((err) => {
      const error = get(err, "response.data.message");
      if (error === "This email is already verified") {
        navigate("/projects");
      }
    });
  };

  return (
    <div className="wrapper">
      <div className="auth-forms-section-content">
        <Result
          icon={null}
          text={text}
          buttonText={buttonText}
          onButtonClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export default DoVerifyInfo;
