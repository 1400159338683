import { Flex } from "antd";
import { memo, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../styles.css";

const TableHeader = ({
  columns = [],
  smallColumnWidth = {},
  setSmallColumnWidth = () =>{},
}) => {
  const location = useLocation();
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      const smallColumnWidths = {};
      columns.forEach((col) => {
        const element = document.getElementById(`column-${col.dataIndex}`);
        if (col.isSmall) {
          const elements = document.querySelectorAll(
            `#col-item-data-${col.dataIndex}:first-child`
          );
          if (element && elements.length > 0) {
            const theLargest = [...elements].reduce(
              (acc, curr) => Math.max(acc, curr.offsetWidth),
              0
            );
            const max = Math.max(element.offsetWidth, theLargest);
            smallColumnWidths[col.dataIndex] = max;
          }
          return;
        }
        // smallColumnWidths[col.dataIndex] = element.offsetWidth
        // debugger
        // const allSmallWidthsSum = Object.values(smallColumnWidths).reduce(
        //   (acc, curr) => acc + curr,
        //   0
        // );
      });
      setSmallColumnWidth(smallColumnWidths);
    }
    initialRender.current = false;
  }, [location, columns, setSmallColumnWidth]);

  return (
    <Flex
      className="table-header table-list-item"
      justify="space-between"
      gap={32}
    >
      {columns.map((col) => (
        <p
          key={col.title}
          id={`column-${col.dataIndex}`}
          className={`full-width text-semibold ${
            col.isSmall ? "small-col" : ""
          }`}
          style={{
            width: smallColumnWidth[col.dataIndex],
            minWidth: smallColumnWidth[col.dataIndex],
          }}
        >
          {col.title}
        </p>
      ))}
    </Flex>
  );
};

export default memo(TableHeader);

// import { Flex } from "antd";
// import { memo, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import "../styles.css";

// const TableHeader = ({ columns = [], smallColumnWidth ,setSmallColumnWidth }) => {
//   const location = useLocation();
//   useEffect(() => {
//     const smallColumnWidths = {};
//     columns.map((col) => {
//       if (col.isSmall) {
//         const element = document.getElementById(`column-${col.dataIndex}`);
//         setTimeout(() => {
//           const elements = document.querySelectorAll(
//             `#col-item-data-${col.dataIndex}:first-child`
//           );
//           // const elements = document.querySelectorAll(`#column-${col.dataIndex}`);

//           const theLargest = [...elements].reduce((acc, curr) => {
//             return Math.max(acc, curr.offsetWidth);
//           }, 0);
//           if (element && element.offsetWidth) {
//             const max = Math.max(element.offsetWidth, theLargest);
//             // debugger;

//             smallColumnWidths[col.dataIndex] = max;
//             // element.style.width = `${max}px`
//             // element.style.minWidth = `${max}px`
//             // return;
//           }
//           setSmallColumnWidth(smallColumnWidths);
//         }, 1000);
//       }
//     });
//   }, [location]);

//   return (
//     <Flex
//       className="table-header table-list-item"
//       justify="space-between"
//       gap={32}
//     >
//       {columns.map((col) => (
//         <p
//           key={col.title}
//           id={`column-${col.dataIndex}`}
//           className={`full-width text-semibold ${
//             col.isSmall ? "small-col" : ""
//           }`}
//           style={{
//             width: smallColumnWidth[col.dataIndex],
//             minWidth: smallColumnWidth[col.dataIndex],
//           }}
//         >
//           {col.title}
//         </p>
//       ))}
//     </Flex>
//   );
// };

// export default memo(TableHeader);
