import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice.js";
import CustomTable from "../../../components/table/index.js";

const TransactionsDesktop = ({
  data = {},
  currentPage = 0,
  loading = false,
  handlePaginationChange = () => {},
  columns = [],
}) => {
  const { role } = useSelector(selectUser);
  const isAdmin = role === "admin";
  if (!isAdmin) {
    columns.splice(1, 1);
  }
  return (
    <div className="margin-top-md">
      <CustomTable
        customClass="scrollable-container-transactions"
        {...{ data, columns, currentPage, handlePaginationChange, loading }}
      />
    </div>
  );
};

export default TransactionsDesktop;
