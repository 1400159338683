import React, { useState } from "react";
import { Steps } from "antd";
import {
  compactFields,
  renderSolarDraftDetailsSteps,
} from "../../../utils/drafts";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useSelector } from "react-redux";
import { selectCurrentDraft } from "../../../redux/slices/draftSlice.js";
import { handleDownloadWithBlob } from "../../../utils/utils.js";
import DetailsStep from "./DetailsStep";
import "../styles.css";

const ProjectDetails = () => {
  const [current, setCurrent] = useState(0);
  const { isMobile } = useWindowSize();
  const draft = useSelector(selectCurrentDraft);

  const onDownloadClick = ({ file }) => {
    handleDownloadWithBlob({ url: file.url, fileName: file.fileName });
  };

  const onDownloadAll = () => {
    draft.clientAttachments.forEach((file) => onDownloadClick({ file }));
  };

  const steps = renderSolarDraftDetailsSteps(
    current,
    isMobile,
    draft,
    () => setCurrent(current + 1),
    () => setCurrent(current - 1),
    onDownloadClick,
    onDownloadAll
  );

  return (
    <div className="project-details-container">
      <div className="project-details-wrapper margin-top-extra-large">
        {!!draft.draftTypes?.solar ? (
          <div className="solar-details-container">
            <Steps
              className="solar-steps"
              current={current}
              items={steps}
              labelPlacement="vertical"
              responsive={false}
            />
            <p className="steps-title text-center text-primary text-md margin-bottom-xxs">
              {steps[current].title}
            </p>
            {steps[current].content}
          </div>
        ) : (
          <DetailsStep
            item={draft}
            fields={compactFields}
            onDownloadClick={onDownloadClick}
            onDownloadAll={onDownloadAll}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
