import { useState } from "react";
import { Flex, Form } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { addNewCard } from "../../../services/paymentMethods.js";
import { message } from "../../../utils/utils.js";
import FloatingLabelSelectItem from "../../../components/inputs/FloatingLabelSelectItem.js";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput.js";
import ModalFooter from "../../../components/modal/ModalFooter.js";
import CustomForm from "../../../components/form";
import CustomCard from "../../../components/card";
import flatMap from "lodash/flatMap.js";
import "../styles.css";

const AddNewCardForm = ({ updateForm, onCancel = () => {} }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();

  const deleteFormErrors = (fieldName) => {
    form.setFields([
      {
        name: fieldName,
        errors: [],
      },
    ]);
  };

  const onFinishFailed = (params) => {
    const errorsArray = flatMap(params.errorFields, "errors");
    setErrors(errorsArray);
    Object.keys(params.values).forEach((fieldName) => {
      deleteFormErrors(fieldName);
    });
  };

  const onFinish = async (values) => {
    setButtonLoading(true);
    addNewCard(values)
      .then(() => updateForm())
      .then(() => {
        onCancel();
        message({
          message: "Your credit card has been successfully added",
          type: "success",
        });
      })
      .catch((error) => {
        const errors = Object.keys(error.validationErrors).map((key) => {
          return {
            errors: error.validationErrors[key],
            name: [key],
          };
        });
        if (errors) {
          setErrors(errors);
          return;
        }
        message({ message: "Something went wrong", type: "error" });
        console.error("Add new card failed:", error);
      })
      .finally(() => setButtonLoading(false));
  };
  const renderCurrentError = (field) => {
    return errors.some((i) => i.name[0] === field);
  };

  return (
    <div className="forms-container">
      <CustomForm
        form={form}
        onFinish={onFinish}
        setErrors={setErrors}
        onFailed={onFinishFailed}
      >
        <FloatingLabelInput
          name="cardholderName"
          currentError={renderCurrentError("cardholderName")}
        />
        <FloatingLabelInput
          name="cardNumber"
          currentError={renderCurrentError("cardNumber")}
          maxLength={19}
        />
        <Flex gap={24} className="exp-date-wrapper">
          <FloatingLabelSelectItem
            name="expMonth"
            currentError={renderCurrentError("expMonth")}
          />
          <FloatingLabelSelectItem
            name="expYear"
            currentError={renderCurrentError("expYear")}
          />
          <FloatingLabelInput
            name="cvv"
            currentError={renderCurrentError("cvv")}
            maxLength={4}
          />
        </Flex>
        {errors.some((i) => !!(i.errors || []).length) && (
          <CustomCard
            customClass="margin-top-md"
            description={
              <Flex align="flex-start">
                <div>
                  <ExclamationCircleFilled className="text-error" />
                </div>
                <ul className="errors-list">
                  <Form.ErrorList
                    className="text-error"
                    errors={errors.map((item) => (
                      <Flex gap={8}>
                        <li className="error-item text-black text-semibold">
                          {item.errors[0]}
                        </li>
                      </Flex>
                    ))}
                  />
                </ul>
              </Flex>
            }
          />
        )}
        <ModalFooter
          submitTitle="Add"
          loading={buttonLoading}
          disabled={buttonLoading}
          htmlType="submit"
          onCancel={onCancel}
        />
      </CustomForm>
    </div>
  );
};

export default AddNewCardForm;
