import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import IconWithText from "../../../components/iconWithText";
import NewInfoCard from "../../../components/infoCard/NewInfoCard.js";
import "../styles.css";

const DetailsStep = ({
  item,
  fields,
  currentStep,
  onNextClick = () => {},
  onBackClick = () => {},
  onDownloadClick = () => {},
  onDownloadAll = () => {},
}) => {
  const renderActions = () => {
    if (currentStep === undefined) {
      return null;
    }
    const actions = [
      <div className="is-link" onClick={onNextClick}>
        <IconWithText
          text="Next"
          IconComponent={RightOutlined}
          iconClassname="text-sm"
          styles={{ justifyContent: "center", flexDirection: "row-reverse" }}
        />
      </div>,
    ];
    if (currentStep === 3) {
      actions.pop();
    }
    if (currentStep !== 0) {
      actions.unshift(
        <div className="is-link" onClick={onBackClick}>
          <IconWithText
            text="Back"
            IconComponent={LeftOutlined}
            iconClassname="text-sm"
            styles={{ justifyContent: "center" }}
            customClass="text-gray"
          />
        </div>
      );
    }
    return actions;
  };
  return (
    <div className="detailsStep-content-wrapper">
      <NewInfoCard
        item={item}
        fields={fields}
        renderActions={renderActions()}
        params={{ onDownloadClick, onDownloadAll }}
      />
    </div>
  );
};

export default DetailsStep;
