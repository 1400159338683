// import { useEffect, useRef } from "react";
import { Button } from "antd";
import "./styles.css";

const CustomButton = ({
  customClass = "",
  buttonTitle = "Ok",
  type = "primary",
  onClick = () => {},
  icon = null,
  htmlType,
  disabled,
  loading,
  block,
  danger = false,
  styles,
  size = "middle",
}) => {
  // need changes or not
  // const btnRef = useRef(null);
  // useEffect(() => {
  //   if (btnRef && btnRef.current) {
  //     btnRef.current.style.minWidth = `${btnRef.current.offsetWidth}px`;
  //   }
  // }, [btnRef && btnRef.current]);

  return (
    <Button
      // ref={btnRef}
      className={`font-size-md button ${customClass}`}
      type={type}
      onClick={onClick}
      block={block}
      disabled={disabled}
      loading={loading}
      style={styles}
      htmlType={htmlType}
      size={size}
      icon={icon}
      danger={danger}
    >
      {buttonTitle}
    </Button>
  );
};

export default CustomButton;
