import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const SearchInput = ({
  value = "",
  placeholder = "Search",
  onChange = () => {},
  prefix = <SearchOutlined className="search-icon" />,
}) => {
  return (
    <Input
      className="search-input"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      allowClear
      prefix={prefix}
    />
  );
};

export default SearchInput;
