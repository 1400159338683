import { memo, useEffect, useState } from "react";
import { Checkbox, Flex } from "antd";
import { deleteDraft, getDraftByContractor } from "../../services/drafts";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice.js";
import { message, removePrefix, statuses } from "../../utils/utils";
import { columns } from "./components/columns.js";
import { apiByRole } from "../../utils/apiByRole.js";
import SearchInput from "../../components/inputs/SearchInput";
import SolarRequest from "../../components/createDraftForm/SolarRequest.js";
import CustomDrawer from "../../components/drawer/index.js";
import CustomTable from "../../components/table/index.js";
import CreateDraft from "../../components/createDraft/index.js";
import RadioButtonFilters from "../../components/radioButtonFilters/index.js";
import isEmpty from "lodash/isEmpty.js";
import get from "lodash/get.js";
import "./styles.css";

const ProjectsDesktop = ({ submitted = true }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = parseInt(searchParams.get("page"), 10) || 1;
  const query = searchParams.get("search") || "";
  const status = searchParams.get("status") || "";
  const hasNewRevisions = searchParams.get("hasNewRevision") || "";
  const [drafts, setDrafts] = useState({ items: [], count: 0 });
  const [loading, setLoading] = useState(true);
  const [currentDraftLoading, setCurrentDraftLoading] = useState({
    loading: false,
    currentId: "",
  });
  const [currentPage, setCurrentPage] = useState(page);
  const [searchQuery, setSearchQuery] = useState(query);
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [draftModalOpen, setDraftModalOpen] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState({});
  const [hasNewRevision, setHasNewRevision] = useState(hasNewRevisions);

  const { role } = useSelector(selectUser);
  const navigate = useNavigate();
  const isAdmin = role === "admin";

  const fetchData = () => {
    setLoading(true);
    const fetchRequest = apiByRole[role].projects.getDrafts;
    fetchRequest({
      offset: (currentPage - 1) * 25,
      limit: 25,
      searchQuery,
      status: selectedStatus,
      hasNewRevision,
      submitted,
    })
      .then(setDrafts)
      .catch((error) =>
        console.error("GET-ActiveContractors - CATCH --- ERROR", error)
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!!searchQuery) {
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchData();
    }
  }, [currentPage, searchQuery, selectedStatus, submitted, hasNewRevision]);

  useEffect(() => setSearchQuery(""), [submitted]);
  useEffect(() => setSearchQuery(query), [query]);

  const handleResetFilters = () => {
    setSelectedStatus("");
    setHasNewRevision(false);
    searchParams.delete("status");
    searchParams.delete("hasNewRevision");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const onFilterChange = ({ newRevisionChecked, item }) => {
    if (item) {
      setSelectedStatus(item);
      searchParams.set("status", item);
    }
    if (newRevisionChecked !== undefined) {
      setHasNewRevision(newRevisionChecked);
      searchParams.set("hasNewRevision", newRevisionChecked);
    }
    setCurrentPage(1);
    searchParams.set("page", "1");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
    searchParams.set("page", page);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleSearch = (value) => {
    setCurrentPage(1);
    setSearchQuery(value);
    value ? searchParams.set("search", value) : searchParams.delete("search");
    searchParams.set("page", "1");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const onSubmit = () => {
    if (!isEmpty(selectedDraft) && !submitted) {
      setDrafts({
        count: drafts.count - 1,
        items: drafts.items.filter((draft) => selectedDraft.id !== draft.id),
      });
    }
    message({
      type: "success",
      message: "Your request has been successfully sent!",
    });
  };
  const onEditClick = (id) => {
    setCurrentDraftLoading({ loading: true, currentId: id });
    getDraftByContractor(id)
      .then((res) => {
        let newData = {
          ...res,
          ...get(res, "electricalData", {}),
          ...get(res, "solarData", {}),
          projectName: res.name,
          phoneNumberNotRequired: res.phone,
        };
        if (!isEmpty(newData.clientAttachments)) {
          newData.clientAttachments = newData.clientAttachments.map((file) => {
            return {
              ...file,
              dbName: file.fileName,
              name: removePrefix(file.fileName),
            };
          });
        }
        if (res.solarData) {
          newData.batteryStorage = res.solarData.batteryStorage ? "Yes" : "No";
          newData.note = res.solarData.note;
        }
        if (res.electricalData) {
          newData.acDisconnect = newData.acDisconnect ? "Yes" : "No";
          newData.pvProductionMeter = newData.pvProductionMeter ? "Yes" : "No";
          newData.mainElectricalPanelUpgrade =
            newData.mainElectricalPanelUpgrade ? "Yes" : "No";
          newData.electricalNote = res.electricalData.note;
          if (!isEmpty(newData.subpanelNew)) {
            newData.newBusRating = newData.subpanelNew.busRating;
            newData.newBreakerRating = newData.subpanelNew.breakerRating;
          }
          if (!isEmpty(newData.subpanelExisting)) {
            newData.existingBusRating = newData.subpanelExisting.busRating;
            newData.existingBreakerRating =
              newData.subpanelExisting.breakerRating;
          }
        }
        setSelectedDraft(newData);
      })
      .finally(() => {
        setCurrentDraftLoading({ loading: false, currentId: "" });
        setDraftModalOpen(true);
      });
  };
  const statusFilterOptions = statuses[role].project;

  const onDeleteDraft = (id) => {
    deleteDraft(id).then(() =>
      setDrafts((prev) => {
        return {
          count: prev.count - 1,
          items: prev.items.filter((f) => f.id !== id),
        };
      })
    );
  };

  const renderFormTitle = () => {
    const draftTypes = Object.keys(selectedDraft.draftTypes || [])
      .map((key) => {
        if (selectedDraft.draftTypes[key]) {
          return key;
        }
      })
      .filter(Boolean);
    if (draftTypes.includes("solar")) {
      return "Solar Engineering  Request";
    }
    if (draftTypes.length === 2) {
      return "Electrical & Structural  Engineering Request";
    }
    if (draftTypes[0] === "electrical") {
      return "Electrical Engineering Request";
    }
    return "Structural  Engineering Request";
  };
  const currentStatuses = statuses[role].project;

  return (
    <div className="projects-container">
      {!isAdmin && submitted && (
        <div className="create-draft-button">
          <CreateDraft fetchData={fetchData} />
        </div>
      )}
      <div className="margin-top-xxxl margin-bottom-md space-between text-md">
        <SearchInput
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {(submitted || isAdmin) && (
          <Flex gap={24} align="center">
            {(selectedStatus || hasNewRevision) && (
              <p className="is-link text-md" onClick={handleResetFilters}>
                Clear Filters
              </p>
            )}
            {isAdmin && submitted && (
              <Checkbox
                className="text-gray text-md"
                onChange={(e) =>
                  onFilterChange({ newRevisionChecked: e.target.checked })
                }
                checked={hasNewRevision}
              >
                New Revisions
              </Checkbox>
            )}
            <RadioButtonFilters
              filterOptions={statusFilterOptions}
              selectedItem={selectedStatus}
              onFilterChange={onFilterChange}
            />
          </Flex>
        )}
      </div>
      <CustomTable
        customClass="scrollable-container-projects"
        columns={columns(
          isAdmin,
          submitted,
          onEditClick,
          onDeleteDraft,
          currentStatuses
        )}
        data={drafts}
        loading={loading}
        handlePaginationChange={handlePaginationChange}
        currentPage={currentPage || 1}
        currentDraftLoading={currentDraftLoading}
      />
      <CustomDrawer
        open={draftModalOpen}
        title={renderFormTitle()}
        content={
          <SolarRequest
            selectedDraft={selectedDraft}
            onSubmit={onSubmit}
            onCancel={() => setDraftModalOpen(false)}
            selectedTypes={
              !isEmpty(selectedDraft) &&
              Object.keys(selectedDraft.draftTypes)
                .map((item) => selectedDraft.draftTypes[item] && item)
                .filter(Boolean)
            }
          />
        }
        onCancel={() => setDraftModalOpen(false)}
      />
    </div>
  );
};

export default memo(ProjectsDesktop);
