import { useState } from "react";
import { Form } from "antd";
import { changePassword } from "../../../services/user.js";
import { message } from "../../../utils/utils.js";
import ModalFooter from "../../../components/modal/ModalFooter.js";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput.js";
import CustomForm from "../../../components/form/index.js";
import get from "lodash/get";
import "../styles.css";

const PasswordForm = ({ onCancel = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    changePassword(values)
      .then(() => {
        onCancel();
        message({
          message: "Password has been successfully updated",
          type: "success",
        });
        return;
      })
      .catch((error) => {
        const errorMessage = get(error, "response.data.message");
        if (errorMessage) {
          form.setFields([
            {
              name: "currentPassword",
              errors: [errorMessage],
            },
          ]);
          setErrors((prev) => [
            ...prev,
            {
              name: ["currentPassword"],
              errors: [errorMessage],
            },
          ]);
          return;
        }
        message({
          message: "Something went wrong",
          type: "error",
        });
        console.error("PasswordForm - ERROR ----------- ", error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="margin-top-md forms-container">
        <CustomForm form={form} onFinish={onFinish} setErrors={setErrors}>
          <FloatingLabelInput name="currentPassword" errors={errors} />
          <FloatingLabelInput name="newPassword" errors={errors} />
          <FloatingLabelInput name="confirmNewPassword" errors={errors} />
          <ModalFooter
            htmlType="submit"
            submitTitle="Update"
            onCancel={onCancel}
            loading={loading}
            disabled={loading}
          />
        </CustomForm>
      </div>
    </>
  );
};

export default PasswordForm;
