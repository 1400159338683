import { useState } from "react";
import { getCompany } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { message } from "../../utils/utils.js";
import CustomButton from "../button";
import Modal from "../modal";
import CreateDraftForm from "../createDraftForm";
import CustomDrawer from "../drawer/index.js";
import ModalContent from "../modal/ModalContent.js";
import ModalFooter from "../modal/ModalFooter.js";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import ChooseTypesForm from "../createDraftForm/ChooseTypesForm.js";

const options = {
  solar: "Solar Drawing",
  electrical: "Electrical Engineering",
  structural: "Structural Engineering",
};

const CreateDraft = ({ fetchData = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const [draftModalOpen, setDraftModalOpen] = useState(false);
  const [noCompanyModalOpen, setNoCompanyModalOpen] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [hasChoosed, setHasChoosed] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setLoading(true);
    getCompany()
      .then((response) => {
        if (isEmpty(response)) {
          setNoCompanyModalOpen(true);
          return;
        }
        setDraftModalOpen(true);
      })
      .catch((error) => {
        console.error("Create draft button click ERROR ---- ", error);
        if (get(error, "response.data.message") === "Company not found") {
          setNoCompanyModalOpen(true);
          return;
        }
        message({
          type: "error",
          message: "Something went wrong",
        });
      })
      .finally(() => setLoading(false));
  };
  const handleNavigateToCompany = () => {
    setNoCompanyModalOpen(false);
    navigate("/profile");
  };

  const onCancel = () => {
    setDraftModalOpen(false);
    setHasChoosed(false);
    setSelectedTypes([]);
    return;
  };

  const renderFormTitle = () => {
    if (selectedTypes.includes("solar")) {
      return "Solar Engineering  Request";
    }
    if (selectedTypes.length === 2) {
      return "Electrical & Structural  Engineering Request";
    }
    if (selectedTypes[0] === "electrical") {
      return "Electrical Engineering Request";
    }
    return "Structural  Engineering Request";
  };

  return (
    <>
      <CustomButton
        type="primary"
        buttonTitle="Create Draft"
        onClick={handleClick}
        loading={loading}
        disabled={loading}
      />
      <Modal
        open={noCompanyModalOpen}
        footer={null}
        content={
          <>
            <ModalContent
              text={
                " Please ensure all necessary company data is available before selecting service types"
              }
            />
            <ModalFooter
              submitTitle={"Continue"}
              onSubmit={handleNavigateToCompany}
              onCancel={() => setNoCompanyModalOpen(false)}
            />
          </>
        }
      />
      <CustomDrawer
        open={draftModalOpen}
        onCancel={onCancel}
        title={hasChoosed ? renderFormTitle() : "Service Type"}
        content={
          hasChoosed ? (
            <CreateDraftForm
              selectedTypes={selectedTypes}
              isSolar={selectedTypes.includes("solar")}
              onCancel={onCancel}
              fetchData={fetchData}
            />
          ) : (
            <ChooseTypesForm
              types={Object.values(options)}
              checked={isEmpty(selectedTypes)}
              setTypeValues={(checkedValues) => {
                setSelectedTypes(
                  Object.keys(options).filter((item) =>
                    checkedValues.includes(options[item])
                  )
                );
              }}
              onSubmit={() => setHasChoosed(true)}
              onCancel={onCancel}
            />
          )
        }
      />
    </>
  );
};

export default CreateDraft;
