import { useState } from "react";
import { Form, Input } from "antd";
import {
  maxCharactersValidator,
  phoneNumberValidators,
} from "../../utils/formValidators";
import { inputs } from "../../utils/formItems";
import "./styles.css";

const FloatingLabelInput = ({
  name,
  errors = [],
  initialValue = "",
  currentError = false,
  maxLength = 250,
  autoComplete = "",
}) => {
  const form = Form.useFormInstance();
  const [value, setValue] = useState(
    initialValue || form.getFieldValue(name) || ""
  );
  const [hide, setHide] = useState(false);
  const [focus, setFocus] = useState(false);
  const [visible, setVisible] = useState(false);
  const inputConfig = inputs[name] || {};
  const {
    placeholder,
    dependencies,
    type = () => {},
    renderSuffix = () => null,
    rules = [],
    normalize,
  } = inputConfig;
  const hideLabel = hide || focus || value;
  const handleAutoFill = (e) => {
    setHide(e.animationName === "onAutoFillStart");
  };

  const renderFieldError = () => {
    return errors.some((error) => {
      if (error.name[0] === name) {
        return true;
      }
      return false;
    });
  };

  const isTextarea =
    name === "note" ||
    name === "notes" ||
    name === "electricalNote" ||
    name === "structuralNote";

  const renderInput = () => {
    if (isTextarea) {
      return (
        <Input.TextArea
          value={value}
          name={name}
          onAnimationStart={handleAutoFill}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => setValue(e.target.value)}
          className="input-textarea input"
          autoSize
          count={
            {
              show: true,
              max: 1000,
            }
          }
        />
      );
    }
    return (
      <>
        <Input
          className={`input ${currentError ? "input-error" : ""}`}
          value={value}
          name={name}
          onAnimationStart={handleAutoFill}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={(e) => {
            if (name === "phoneNumber" || name === "phoneNumberNotRequired") {
              const isInvalid = phoneNumberValidators(e.target.value);
              if (
                !isInvalid ||
                e.target.value === "" ||
                (e.target.value.length === 1 && e.target.value === "+")
              ) {
                setValue(e.target.value);
              }
              return;
            }
            return setValue(e.target.value);
          }}
          maxLength={maxLength}
          type={type(visible)}
          autoComplete={autoComplete}
        />
        {renderSuffix({ visible, setVisible })}
      </>
    );
  };

  if (!inputConfig) {
    console.error(`Invalid input name: ${name}`);
    return null;
  }
  const allRules = [...rules, { validator: maxCharactersValidator }];
  const errorMessage = errors.find((error) => error.name[0] === name)
    ?.errors[0];

  return (
    <div className="floating-wrapper">
      <Form.Item
        name={name}
        rules={allRules}
        normalize={normalize}
        dependencies={dependencies}
      >
        <label
          className={`input_container ${
            isTextarea ? "textarea-container" : ""
          }`}
        >
          <span
            className={`${!isTextarea ? "label" : "textarea-label"} ${
              hideLabel ? "hide" : ""
            } ${!!renderFieldError() && hideLabel ? "text-error" : ""}`}
          >
            {placeholder}
          </span>
          {renderInput()}
        </label>
      </Form.Item>
      <p className={`form-item-error text-error ${errorMessage ? "show" : ""}`}>
        {errorMessage}
      </p>
    </div>
  );
};

export default FloatingLabelInput;
