import { Flex } from "antd";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import CustomButton from "../button";
import { removePrefix } from "../../utils/utils";
import "./styles.css";

const Attachments = ({
  id = "",
  title = "",
  files = [],
  onDownloadClick = () => {},
  onDownloadAll = () => {},
  buttonLoading = false,
  buttonDisabled = false,
}) => {
  return (
    <div className="attachments-list-wrapper">
      <p className="text-md text-center margin-bottom-xl text-black text-semibold">
        {title}
      </p>
      <div
        className={`attachments-list text-black text-semibold-light ${
          files.length > 1 ? "is-multiple" : ""
        }`}
      >
        <div className="files">
          {files.map((file) => (
            <Flex
              key={file.fileName}
              className="file-list-item"
              justify="space-between"
              align="center"
            >
              <Flex gap={8} style={{ width: "calc(100% - 48px)" }}>
                <PaperClipOutlined className="text-md" />
                <p className="attachment-name">
                  {!id ? removePrefix(file.fileName) : file.fileName}
                </p>
              </Flex>
              <CustomButton
                type="text"
                buttonTitle=""
                customClass="files-download-button"
                icon={
                  <DownloadOutlined
                    className={`text-xl ${!buttonDisabled ? "is-link" : ""}`}
                  />
                }
                className="is-link"
                onClick={() => onDownloadClick({ file })}
                disabled={buttonDisabled}
              />
            </Flex>
          ))}
        </div>
        {files.length > 1 && (
          <CustomButton
            buttonTitle="Download All"
            type="text"
            customClass="all-files-button is-link"
            block
            onClick={() => onDownloadAll(title)}
            icon={<DownloadOutlined />}
            disabled={buttonDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default Attachments;
