import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex } from "antd";
import { FileSyncOutlined, LeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice.js";
import {
  sendRevision,
  sendRevisionPriceByAdmin,
} from "../../../services/revisions";
import {
  deleteDrawingByRevisionId,
  uploadDrawingByRevisionId,
} from "../../../services/drawing.js";
import {
  handleDownloadWithBlob,
  message,
  statuses,
} from "../../../utils/utils.js";
import { useWindowSize } from "../../../hooks/useWindowSize.js";
import { apiByRole } from "../../../utils/apiByRole.js";
import ProjectDetailsHeader from "./ProjectDetailsHeader.js";
import PageTitle from "../../../components/pageTitle";
import SendPriceForm from "./SendPriceForm.js";
import SendRevisionsForm from "./SendRevisionsForm.js";
import Loading from "../../../components/loading/index.js";
import NewInfoCard from "../../../components/infoCard/NewInfoCard.js";
import NewInfoField from "../../../components/infoField/NewInfoField";
import IconWithText from "../../../components/iconWithText/index.js";
import Attachments from "../../../components/attachments/index.js";
import Text from "../../../components/typography/index.js";
import CustomDrawer from "../../../components/drawer/index.js";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

const fields = {
  companyName: {
    label: "Company",
    url: ({ userId }) => `/contractors/${userId}`,
  },
  draftName: {
    label: "Project",
    url: ({ draftId }) => `/projects/${draftId}/details`,
  },
  index: {
    label: "Revision Number",
  },
  createdAt: {
    label: "Revision Created Date",
    isDate: true,
  },
  totalPrice: {
    label: "Revision Price",
    render: ({ totalPrice }) => <Text text={`$${totalPrice}`} />,
  },
};
const revisionFields = {
  clientAttachments: {
    render: ({ id, clientAttachments }, { onDownloadClick, onDownloadAll }) => {
      if (!clientAttachments.length) {
        return null;
      }
      return (
        <div key={id} className="margin-top-md">
          <Attachments
            id={id}
            title="Revision"
            files={clientAttachments}
            onDownloadClick={onDownloadClick}
            onDownloadAll={onDownloadAll}
          />
        </div>
      );
    },
  },
  notes: {
    render: ({ notes }, { currentRevsionNote }) =>
      notes && <NewInfoField field={currentRevsionNote} value={notes} />,
  },
};
const drawingFields = {
  clientAttachments: {
    render: (
      { id, drawings },
      { onDownloadClick, onDownloadAll, buttonDisabled }
    ) => {
      if (!drawings.length) {
        return null;
      }
      return (
        <div key={id} className="margin-top-md">
          <Attachments
            id={id}
            title="Drawing"
            files={drawings}
            onDownloadClick={onDownloadClick}
            onDownloadAll={onDownloadAll}
            buttonDisabled={buttonDisabled}
          />
        </div>
      );
    },
  },
  notes: {
    render: ({ adminNotes }, { currentDrawingNote }) =>
      adminNotes && (
        <NewInfoField field={currentDrawingNote} value={adminNotes} />
      ),
  },
};
const RevisionDetails = () => {
  const [key, setKey] = useState(new Date());
  const [sendRevisionModalOpen, setSendRevisionModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [revision, setRevision] = useState({});
  const [filesList, setFilesList] = useState([]);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const { role } = useSelector(selectUser);
  const { revisionId } = useParams();
  const navigate = useNavigate();

  const fetchData = () => {
    const request = apiByRole[role].revisions.getRevision;

    request(revisionId)
      .then((response) => {
        const totalPrice = (response.price || {}).total;
        return setRevision({
          ...response,
          currentComponent: "Revision",
          ...(totalPrice !== undefined && { totalPrice }),
          drawings: response.drawings.map((file) => {
            return {
              ...file,
              name: file.fileName,
              dbName: file.fileName,
              uid: file.id,
            };
          }),
        });
      })
      .catch((error) => console.error("Project --- catch", error))
      .finally(() => setLoading(false));
  };
  console.log(revision);

  useEffect(() => {
    fetchData();
  }, [role, revisionId, key]);

  useEffect(() => {
    if ((revision.drawings || []).length !== filesList.length) {
      fetchData();
    }
  }, [priceModalOpen]);

  const onDownloadClick = ({ file }) => {
    const fileData = file || get(revision, "drawings[0]", {});
    handleDownloadWithBlob({ url: fileData.url, fileName: fileData.fileName });
  };

  const onDownloadAll = (component = "Revision") => {
    const attachments =
      component === "Revision" ? "clientAttachments" : "drawings";
    revision[attachments].forEach((file) => onDownloadClick({ file }));
  };

  const buttonDisabled = revision.status !== "complete" && role !== "admin";
  const currentRevsionNote =
    role === "admin" ? "Contractor Notes" : "Your Notes";
  const currentDrawingNote = role === "admin" ? "Your Notes" : "Admin Notes";

  const uploadRequest = (formData) => {
    const upload = uploadDrawingByRevisionId(revisionId, formData);
    return upload;
  };

  const onDeleteAttachment = (file) => {
    return deleteDrawingByRevisionId(revisionId, file.id);
  };

  const onSendPriceFinish = (values) => {
    if (!!values.price) {
      values.price = +values.price;
    }
    if (!!values.projectPrice) {
      values.price = +values.projectPrice;
    }
    sendRevisionPriceByAdmin(revisionId, values)
      .catch(() => {
        setPriceModalOpen(false);
        return message({ message: "Something went wrong", type: "error" });
      })
      .then(() => {
        setKey(new Date());
        setPriceModalOpen(false);
        return message({
          message: "Your email has been successfully sent",
          type: "success",
        });
      })
      .catch(() => {
        setPriceModalOpen(false);
        return message({ message: "Something went wrong", type: "error" });
      });
  };
  const onProccedToPaymentClick = () => {
    navigate(`/payment-methods/revisions/${revision.id}`, {
      state: {
        draftId: revision.draftId,
        draftName: revision.draftName,
      },
    });
  };

  const onSendRevision = (values) => {
    const send = sendRevision(revision.draftId, values)
      .then(() => {
        setSendRevisionModalOpen(false);
        return message({
          message: "Your request has been successfully sent",
          type: "success",
        });
      })
      .catch(() => {
        setSendRevisionModalOpen(false);
        return message({ message: "Something went wrong", type: "error" });
      });
    return send;
  };

  const handleGoBack = () => {
    navigate(`/projects/${revision.draftId}/revisions`);
  };

  const renderActions = () => {
    const arr = [];
    if (role === "contractor") {
      arr.push(
        <div className="is-link" onClick={() => setSendRevisionModalOpen(true)}>
          <IconWithText
            text={"Send Revision"}
            IconComponent={FileSyncOutlined}
            styles={{ justifyContent: "center" }}
          />
        </div>
      );
    }
    return arr;
  };

  const currentOptions = statuses[role].revision;

  if (loading) {
    return (
      <div className="page-loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      {!isMobile ? (
        <>
          <PageTitle title="Revision Details" />
          <Flex
            align="center"
            justify="space-between"
            className="project-details-header-wrapper margin-top-xxxl"
          >
            <div className="is-link" onClick={handleGoBack}>
              <IconWithText
                text="Back to Revisions"
                IconComponent={LeftOutlined}
                iconClassname="text-xxs"
                gap={4}
              />
            </div>
            <ProjectDetailsHeader
              data={revision}
              onPriceClick={() => setPriceModalOpen(true)}
              onProccedToPaymentClick={onProccedToPaymentClick}
              fetchData={fetchData}
              currentOptions={currentOptions}
            />
          </Flex>
        </>
      ) : (
        <>
          <div className="is-link margin-bottom-xxl" onClick={handleGoBack}>
            <IconWithText
              text="Back to Revisions"
              IconComponent={LeftOutlined}
              iconClassname="text-xxs"
              gap={4}
            />
          </div>
          <PageTitle title="Revision Details" />
          <ProjectDetailsHeader
            data={revision}
            onPriceClick={() => setPriceModalOpen(true)}
            onProccedToPaymentClick={onProccedToPaymentClick}
            fetchData={fetchData}
            currentOptions={currentOptions}
          />
        </>
      )}
      <Flex vertical className="revision-container" gap={isMobile ? 8 : 24}>
        <NewInfoCard
          item={revision}
          fields={fields}
          renderActions={renderActions()}
          params={{ onDownloadClick, onDownloadAll }}
        />
        {!isEmpty(revision.clientAttachments) && (
          <NewInfoCard
            item={revision}
            fields={revisionFields}
            params={{ onDownloadClick, onDownloadAll, currentRevsionNote }}
          />
        )}
        {!isEmpty(revision.drawings) && (
          <NewInfoCard
            item={revision}
            fields={drawingFields}
            params={{
              onDownloadClick,
              onDownloadAll,
              currentDrawingNote,
              buttonDisabled,
            }}
          />
        )}
      </Flex>
      <CustomDrawer
        open={priceModalOpen || sendRevisionModalOpen}
        title={priceModalOpen ? "Send Drawing" : "Revision"}
        customClass="send-form-modal-container"
        content={
          priceModalOpen ? (
            <SendPriceForm
              name={priceModalOpen ? "price" : "revision"}
              onFinish={priceModalOpen ? onSendPriceFinish : onSendRevision}
              filesList={filesList}
              setFilesList={setFilesList}
              uploadRequest={uploadRequest}
              attachments={revision.drawings}
              onDeleteAttachment={onDeleteAttachment}
              onCancel={() => setPriceModalOpen(false)}
            />
          ) : (
            <SendRevisionsForm
              onFinish={onSendRevision}
              onCancel={() => setSendRevisionModalOpen(false)}
            />
          )
        }
        onCancel={() =>
          priceModalOpen
            ? setPriceModalOpen(false)
            : setSendRevisionModalOpen(false)
        }
      />
    </div>
  );
};

export default RevisionDetails;
