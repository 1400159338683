import { useState } from "react";
import { Flex } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { creditCardImage } from "../../../utils/utils";
import CustomImage from "../../../components/image";
import CustomModal from "../../../components/modal";
import ModalFooter from "../../../components/modal/ModalFooter";
import ModalContent from "../../../components/modal/ModalContent";

const PaymentItem = ({
  data = {},
  onAddClick,
  onCardDelete,
  selected,
  handleSelectedItemChange,
}) => {
  const { last4, cardType } = data;
  const isCard = !!data.id;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = (id) => {
    setLoading(true);
    onCardDelete(id)
      .then(() => setDeleteModalOpen(false))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Flex
        className={`payment-item ${selected ? "payment-item-selected" : ""}`}
        justify="space-between"
        align="center"
        onClick={handleSelectedItemChange}
      >
        <Flex gap={14} align="center" flex={1} onClick={onAddClick}>
          <div className="image-wrapper">
            {isCard ? (
              <CustomImage
                src={
                  creditCardImage[cardType] ||
                  require("../../../components/images/default.png")
                }
              />
            ) : (
              <div className="add-new-card-image flex-container-center cursor-pointer">
                <PlusOutlined className="text-primary text-xl" />
              </div>
            )}
          </div>
          {isCard ? (
            <div>
              <p>{cardType}</p>
              <p className="text-gray">Last digits.... {last4}</p>
            </div>
          ) : (
            <p className="is-link text-semibold">Add a credit card</p>
          )}
        </Flex>
        {isCard && (
          <div
            className="text-error cursor-pointer"
            onClick={() => setDeleteModalOpen(true)}
          >
            <DeleteOutlined className="text-xl" />
          </div>
        )}
      </Flex>
      <CustomModal
        open={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        footer={null}
        content={
          <>
            <ModalContent text={"Are you sure you want to delete your card?"} />
            <ModalFooter
              submitTitle={"Delete"}
              onSubmit={() => handleDelete(data.id)}
              onCancel={() => setDeleteModalOpen(false)}
              loading={loading}
              disabled={loading}
              type="default"
              danger
            />
          </>
        }
      />
    </>
  );
};

export default PaymentItem;
