import { useState } from "react";
import { DatePicker } from "antd";
import { CalendarOutlined, CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const { RangePicker } = DatePicker;

const MyRangePicker = ({
  onCalendarChange = () => {},
  value = [],
  defaultValue = null,
  placement = "bottomLeft",
  customClass = "",
  customPopupClass = "",
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [hasValue, setHasValue] = useState(!startDate && !endDate);

  const onRangeChange = (dates) => {
    onCalendarChange(dates);
    setHasValue(!dates || dates.length === 0);
  };

  const renderIcon = () => {
    return hasValue ? <CalendarOutlined /> : <CloseOutlined />;
  };

  return (
    <RangePicker
      className={`range-picker ${customClass ? customClass : ""}`}
      popupClassName={`range-picker-popup ${
        customPopupClass ? customPopupClass : ""
      }`}
      allowEmpty={[true, true]}
      suffixIcon={renderIcon()}
      onCalendarChange={onRangeChange}
      value={!isEmpty(value) ? value : defaultValue}
      format="MM-DD-YYYY"
      placement={placement}
    />
  );
};

export default MyRangePicker;
