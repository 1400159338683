import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { verifyEmail } from "../../services/auth";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import Result from "../../components/result";
import Loading from "../../components/loading";
import get from "lodash/get";
import api from "../../utils/request";

const VerifyEmailResult = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const verificationToken = searchParams.get("token");
  const navigate = useNavigate();

  const success = get(status, "success");

  useEffect(() => {
    if (verificationToken) {
      verifyEmail(verificationToken)
        .then((res) => {
          if (get(res, "data")) {
            setStatus(res.data);
          }
          setErrorMessage("");
        })
        .catch((err) => {
          setErrorMessage(err.message);
          setStatus(err);
        })
        .finally(() => setLoading(false));
      return;
    }
    setLoading(false);
  }, [verificationToken]);

  const onButtonClick = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (
      errorMessage === "You are already verified with this link" ||
      !accessToken
    ) {
      navigate("/sign-in");
      return;
    }
    if (success && accessToken) {
      navigate("/projects");
      return;
    }
    if (!success && accessToken) {
      setLoading(true);
      api
        .get("/auth/verify_email")
        .then(() => {
          navigate("/verify-email");
        })
        .catch((err) => {
          const error = get(err, "response.data.message");

          if (error === "This email is already verified") {
            navigate("/projects");
          }
        })
        .finally(() => setLoading(false));
      return;
    }
  };

  const buttonText =
    errorMessage === "You are already verified with this link" || !success
      ? "Go to Sign In"
      : "Continue";

  return (
    <div className="wrapper">
      <div className="auth-forms-section-content">
        {!loading ? (
          <Result
            status={success}
            text={
              success ? (
                <>
                  <p>Thank You!</p> <p>Your registration was successful</p>
                </>
              ) : (
                errorMessage
              )
            }
            onButtonClick={onButtonClick}
            buttonText={buttonText}
            icon={!success ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default VerifyEmailResult;
