import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../services/auth";
import { Form, message } from "antd";
import CustomButton from "../../../components/button";
import FloatingLabelInput from "../../../components/inputs/FloatingLabelInput";
import CustomForm from "../../../components/form";

const ForgotPasswordForm = () => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    forgotPassword(values)
      .then((response) => {
        if (response) {
          message.success(
            "Reset Password Link Sent! Please check your inbox and follow the link provided to create a new password"
          );
          navigate("/verify-email", {
            state: {
              content: "An email has been sent to you. Check your inbox",
            },
          });
        }
      })
      .catch((error) => {
        const errors = error.response.data.validationErrors;
        if (error) {
          Object.keys(errors).forEach((fieldName) => {
            return form.setFields([
              {
                name: fieldName,
                errors: [errors[fieldName][0]],
              },
            ]);
          });
        }
        message.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <p className="auth-form-title">Forgot Password</p>
      <p className="text-gray margin-bottom-sm">
        Please enter your Email address. You will receive a link to create a new
        password via Email.
      </p>
      <CustomForm form={form} onFinish={onFinish} setErrors={setErrors}>
        <FloatingLabelInput name="email" errors={errors} />
        <CustomButton
          buttonTitle="Reset Password"
          customClass="margin-top-xl"
          disabled={loading}
          loading={loading}
          htmlType="submit"
          block
        />
      </CustomForm>
    </>
  );
};

export default ForgotPasswordForm;
