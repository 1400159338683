const SolarIcon = ({ fill = "#7A7A7A" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2976_24830)">
        <path
          d="M16.0003 6.04688C10.512 6.04688 6.04688 10.512 6.04688 16.0003C6.04688 21.4886 10.512 25.9538 16.0003 25.9538C21.4886 25.9538 25.9538 21.4886 25.9538 16.0003C25.9538 10.512 21.4886 6.04688 16.0003 6.04688ZM16.0003 24.7038C11.2012 24.7038 7.29688 20.7994 7.29688 16.0003C7.29688 11.2012 11.2012 7.29688 16.0003 7.29688C20.7994 7.29688 24.7038 11.2012 24.7038 16.0003C24.7038 20.7994 20.7994 24.7038 16.0003 24.7038Z"
          fill={fill}
        />
        <path
          d="M16 4.20581C16.1658 4.20581 16.3247 4.13996 16.4419 4.02275C16.5592 3.90554 16.625 3.74657 16.625 3.58081V0.625C16.625 0.45924 16.5592 0.300269 16.4419 0.183058C16.3247 0.065848 16.1658 0 16 0C15.8342 0 15.6753 0.065848 15.5581 0.183058C15.4408 0.300269 15.375 0.45924 15.375 0.625V3.58081C15.375 3.74657 15.4408 3.90554 15.5581 4.02275C15.6753 4.13996 15.8342 4.20581 16 4.20581Z"
          fill={fill}
        />
        <path
          d="M16 27.793C15.8342 27.793 15.6753 27.8588 15.5581 27.976C15.4408 28.0932 15.375 28.2522 15.375 28.418V31.3738C15.375 31.5395 15.4408 31.6985 15.5581 31.8157C15.6753 31.9329 15.8342 31.9988 16 31.9988C16.1658 31.9988 16.3247 31.9329 16.4419 31.8157C16.5592 31.6985 16.625 31.5395 16.625 31.3738V28.418C16.625 28.2522 16.5592 28.0932 16.4419 27.976C16.3247 27.8588 16.1658 27.793 16 27.793Z"
          fill={fill}
        />
        <path
          d="M9.24924 5.55559C9.33212 5.69915 9.46864 5.8039 9.62875 5.84681C9.78887 5.88971 9.95947 5.86725 10.103 5.78437C10.2466 5.70149 10.3513 5.56498 10.3942 5.40486C10.4371 5.24475 10.4147 5.07415 10.3318 4.93059L8.85387 2.37078C8.81313 2.29912 8.75862 2.23624 8.69348 2.18574C8.62834 2.13523 8.55385 2.09812 8.4743 2.07652C8.39476 2.05493 8.31173 2.04928 8.22999 2.0599C8.14825 2.07053 8.06942 2.09721 7.99804 2.13843C7.92666 2.17964 7.86413 2.23456 7.81407 2.30004C7.764 2.36552 7.72738 2.44025 7.70631 2.51994C7.68524 2.59962 7.68014 2.68269 7.69131 2.76436C7.70247 2.84602 7.72968 2.92467 7.77137 2.99578L9.24924 5.55559Z"
          fill={fill}
        />
        <path
          d="M22.7503 26.4414C22.6674 26.2979 22.5309 26.1931 22.3708 26.1502C22.2107 26.1073 22.0401 26.1298 21.8965 26.2126C21.7529 26.2955 21.6482 26.432 21.6053 26.5921C21.5624 26.7523 21.5848 26.9229 21.6677 27.0664L23.1457 29.6262C23.1864 29.6979 23.2409 29.7608 23.306 29.8113C23.3712 29.8618 23.4457 29.8989 23.5252 29.9205C23.6048 29.9421 23.6878 29.9477 23.7695 29.9371C23.8513 29.9265 23.9301 29.8998 24.0015 29.8586C24.0729 29.8174 24.1354 29.7624 24.1855 29.697C24.2355 29.6315 24.2721 29.5568 24.2932 29.4771C24.3143 29.3974 24.3194 29.3143 24.3082 29.2327C24.2971 29.151 24.2698 29.0723 24.2282 29.0012L22.7503 26.4414Z"
          fill={fill}
        />
        <path
          d="M5.55754 9.24729L2.99773 7.76942C2.92662 7.72773 2.84797 7.70052 2.76631 7.68936C2.68464 7.67819 2.60158 7.68329 2.52189 7.70435C2.4422 7.72542 2.36747 7.76204 2.30199 7.81211C2.23652 7.86218 2.18159 7.9247 2.14038 7.99609C2.09917 8.06747 2.07248 8.1463 2.06185 8.22803C2.05123 8.30977 2.05688 8.3928 2.07847 8.47235C2.10007 8.5519 2.13719 8.62638 2.18769 8.69152C2.23819 8.75667 2.30108 8.81118 2.37273 8.85192L4.93254 10.3299C5.0761 10.4127 5.2467 10.4352 5.40682 10.3923C5.56693 10.3494 5.70344 10.2446 5.78633 10.1011C5.86921 9.95752 5.89166 9.78692 5.84876 9.6268C5.80585 9.46669 5.7011 9.33017 5.55754 9.24729Z"
          fill={fill}
        />
        <path
          d="M29.6282 23.1476L27.0684 21.6697C26.9248 21.5868 26.7542 21.5643 26.5941 21.6072C26.434 21.6501 26.2975 21.7549 26.2146 21.8985C26.1317 22.042 26.1093 22.2126 26.1522 22.3727C26.1951 22.5328 26.2998 22.6694 26.4434 22.7522L29.0032 24.2301C29.0743 24.2718 29.1529 24.299 29.2346 24.3102C29.3163 24.3213 29.3993 24.3162 29.479 24.2952C29.5587 24.2741 29.6334 24.2375 29.6989 24.1874C29.7644 24.1373 29.8193 24.0748 29.8605 24.0034C29.9018 23.9321 29.9284 23.8532 29.9391 23.7715C29.9497 23.6898 29.944 23.6067 29.9224 23.5272C29.9008 23.4476 29.8637 23.3731 29.8132 23.308C29.7627 23.2429 29.6998 23.1883 29.6282 23.1476Z"
          fill={fill}
        />
        <path
          d="M4.20581 16C4.20581 15.8342 4.13996 15.6753 4.02275 15.5581C3.90554 15.4408 3.74657 15.375 3.58081 15.375H0.625C0.45924 15.375 0.300269 15.4408 0.183058 15.5581C0.065848 15.6753 0 15.8342 0 16C0 16.1658 0.065848 16.3247 0.183058 16.4419C0.300269 16.5592 0.45924 16.625 0.625 16.625H3.58081C3.74657 16.625 3.90554 16.5592 4.02275 16.4419C4.13996 16.3247 4.20581 16.1658 4.20581 16Z"
          fill={fill}
        />
        <path
          d="M31.3757 15.375H28.4199C28.2542 15.375 28.0952 15.4408 27.978 15.5581C27.8608 15.6753 27.7949 15.8342 27.7949 16C27.7949 16.1658 27.8608 16.3247 27.978 16.4419C28.0952 16.5592 28.2542 16.625 28.4199 16.625H31.3757C31.5415 16.625 31.7005 16.5592 31.8177 16.4419C31.9349 16.3247 32.0007 16.1658 32.0007 16C32.0007 15.8342 31.9349 15.6753 31.8177 15.5581C31.7005 15.4408 31.5415 15.375 31.3757 15.375Z"
          fill={fill}
        />
        <path
          d="M4.93254 21.6697L2.37273 23.1476C2.30108 23.1883 2.23819 23.2429 2.18769 23.308C2.13719 23.3731 2.10007 23.4476 2.07847 23.5272C2.05688 23.6067 2.05123 23.6898 2.06185 23.7715C2.07248 23.8532 2.09917 23.9321 2.14038 24.0034C2.18159 24.0748 2.23652 24.1373 2.30199 24.1874C2.36747 24.2375 2.4422 24.2741 2.52189 24.2952C2.60158 24.3162 2.68464 24.3213 2.76631 24.3102C2.84797 24.299 2.92662 24.2718 2.99773 24.2301L5.55754 22.7522C5.7011 22.6694 5.80585 22.5328 5.84876 22.3727C5.89166 22.2126 5.86921 22.042 5.78633 21.8985C5.70344 21.7549 5.56693 21.6501 5.40682 21.6072C5.2467 21.5643 5.0761 21.5868 4.93254 21.6697Z"
          fill={fill}
        />
        <path
          d="M26.7567 10.4137C26.8662 10.4137 26.9738 10.3848 27.0685 10.3299L29.6284 8.85192C29.7 8.81118 29.7629 8.75667 29.8134 8.69152C29.8639 8.62638 29.901 8.5519 29.9226 8.47235C29.9442 8.3928 29.9499 8.30977 29.9392 8.22803C29.9286 8.1463 29.9019 8.06747 29.8607 7.99609C29.8195 7.9247 29.7646 7.86218 29.6991 7.81211C29.6336 7.76204 29.5589 7.72542 29.4792 7.70435C29.3995 7.68329 29.3165 7.67819 29.2348 7.68936C29.1531 7.70052 29.0745 7.72773 29.0034 7.76942L26.4435 9.24729C26.3243 9.31609 26.2311 9.42233 26.1784 9.54953C26.1257 9.67672 26.1165 9.81775 26.1522 9.95072C26.1879 10.0837 26.2665 10.2011 26.3758 10.2849C26.4851 10.3686 26.619 10.4138 26.7567 10.4137Z"
          fill={fill}
        />
        <path
          d="M10.103 26.2126C9.95944 26.1298 9.78885 26.1073 9.62875 26.1502C9.46864 26.1931 9.33213 26.2978 9.24924 26.4414L7.77137 29.0012C7.72968 29.0723 7.70247 29.151 7.69131 29.2326C7.68014 29.3143 7.68524 29.3974 7.70631 29.477C7.72738 29.5567 7.764 29.6315 7.81407 29.6969C7.86413 29.7624 7.92666 29.8173 7.99804 29.8586C8.06942 29.8998 8.14825 29.9265 8.22999 29.9371C8.31173 29.9477 8.39476 29.9421 8.4743 29.9205C8.55385 29.8989 8.62834 29.8617 8.69348 29.8112C8.75862 29.7607 8.81313 29.6979 8.85387 29.6262L10.3318 27.0664C10.4147 26.9228 10.4371 26.7522 10.3942 26.5921C10.3513 26.432 10.2466 26.2955 10.103 26.2126Z"
          fill={fill}
        />
        <path
          d="M21.8965 5.78434C22.0401 5.86722 22.2107 5.88968 22.3708 5.84678C22.5309 5.80388 22.6674 5.69914 22.7503 5.55559L24.2281 2.99578C24.2698 2.92467 24.297 2.84602 24.3082 2.76436C24.3194 2.68269 24.3143 2.59962 24.2932 2.51994C24.2721 2.44025 24.2355 2.36552 24.1854 2.30004C24.1354 2.23456 24.0729 2.17964 24.0015 2.13843C23.9301 2.09721 23.8513 2.07053 23.7695 2.0599C23.6878 2.04928 23.6048 2.05493 23.5252 2.07652C23.4457 2.09812 23.3712 2.13523 23.306 2.18574C23.2409 2.23624 23.1864 2.29912 23.1456 2.37078L21.6677 4.93059C21.5848 5.07415 21.5624 5.24474 21.6053 5.40485C21.6482 5.56496 21.753 5.70147 21.8965 5.78434Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2976_24830">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SolarIcon;
