import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentDraft: {},
};

const draftSlice = createSlice({
  name: "draft",
  initialState,
  reducers: {
    setCurrentDraft(state, action) {
      state.currentDraft = action.payload;
    },
  },
});

export const { setCurrentDraft } = draftSlice.actions;

export const selectCurrentDraft = (state) => state.draft.currentDraft;

export default draftSlice.reducer;
