import { Checkbox, Form } from "antd";

const Agreement = ({ setChecked }) => {
  return (
    <div>
      <Form.Item name="agreement" valuePropName="checked">
        <div>
          <Checkbox
            className="privacy-checkbox"
            onChange={() => setChecked((checked) => !checked)}
          />
          <span className="font-size-sm">
            By checking this box, you acknowledge that you have read and agree
            to our
          </span>
          <a
            href={"/privacy-policy"}
            target="_blank"
            className="is-link font-size-sm"
          >
            {" "}
            Privacy Policy
          </a>
          <span> and </span>
          <a
            href="/terms-and-conditions"
            target="_blank"
            className="is-link font-size-sm"
          >
            Terms and Conditions
          </a>
        </div>
      </Form.Item>
    </div>
  );
};

export default Agreement;
