import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import userSlice from "./slices/userSlice.js";
import contractorSlice from "./slices/contractorSlice.js";
import draftSlice from "./slices/draftSlice.js";

const reducer = {
  auth: authReducer,
  user: userSlice,
  contractor: contractorSlice,
  draft: draftSlice,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
