import api from "../utils/request";

export const getTransactionsByAdmin = async ({
  offset = 0,
  limit = 25,
  searchQuery = "",
  startDate = "",
  endDate = "",
  type = "",
}) => {
  try {
    let url = `/admin/transactions?offset=${offset}&limit=${limit}`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (searchQuery) {
      url += `&query=${searchQuery}`;
    }

    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("payments - getTransactions - catch", error);
    throw error;
  }
};

export const getTransactionsByContractor = async ({
  offset = 0,
  limit = 25,
  searchQuery = "",
  startDate = "",
  endDate = "",
  type = "",
}) => {
  try {
    let url = `/payments?offset=${offset}&limit=${limit}`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (searchQuery) {
      url += `&query=${searchQuery}`;
    }

    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("payments - getTransactionsByContractor - catch", error);
    throw error;
  }
};

export const getPaymentsGeneralInfoByAdmin = async ({
  startDate = "",
  endDate = "",
}) => {
  try {
    const url = `/admin/transactions/general-info`;
    let queryParams = "";
    if (startDate) {
      queryParams += `&startDate=${startDate}`;
    }
    if (endDate) {
      queryParams += `&endDate=${endDate}`;
    }
    const fullUrl = queryParams ? `${url}?${queryParams}` : url;
    const response = await api.get(fullUrl);
    return response.data.data;
  } catch (error) {
    console.error("payments - getPaymentsGeneralInfoByAdmin - catch", error);
    throw error;
  }
};

export const getPaymentsGeneralInfoByContractor = async ({
  startDate = "",
  endDate = "",
}) => {
  try {
    const url = `/payments/general-info`;
    let queryParams = "";
    if (startDate) {
      queryParams += `&startDate=${startDate}`;
    }
    if (endDate) {
      queryParams += `&endDate=${endDate}`;
    }
    const fullUrl = queryParams ? `${url}?${queryParams}` : url;
    const response = await api.get(fullUrl);
    return response.data.data;
  } catch (error) {
    console.error(
      "payments - getPaymentsGeneralInfoByContractor - catch",
      error
    );
    throw error;
  }
};
