import { useGoogleLogin } from "@react-oauth/google";
import { googleLogin } from "../../../services/auth";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/button";
import GmailIcon from "../../../components/icons/GmailIcon";
import get from "lodash/get";

const ByGmail = () => {
  const navigate = useNavigate();

  const handleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleLogin(tokenResponse.code)
        .then((response) => {
          const { accessToken, refreshToken } = get(response, "data");
          if (accessToken && refreshToken) {
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            const lastPathname = localStorage.getItem("last-pathname");
            navigate(!!lastPathname ? lastPathname : "/projects");
          }
        })
        .catch((error) => console.error("Google Login --- error --- ", error));
    },
    flow: "auth-code",
  });
  return (
    <div className="google-login-button-container">
      <div className="by-gmail-dividers-wrapper">
        <div className="custom-divider" />
        <span className="text-or text-gray">or</span>
        <div className="custom-divider" />
      </div>
      <CustomButton
        icon={<GmailIcon />}
        buttonTitle="Google"
        type="default"
        block
        onClick={handleLogin}
      />
    </div>
  );
};

export default ByGmail;
