import { useState, useEffect } from "react";
import { Flex, List, Pagination } from "antd";
import TableHeader from "./tableHeader/index.js";
import isEmpty from "lodash/isEmpty.js";
import "./styles.css";

const CustomTable = ({
  data = {},
  columns = [],
  currentPage = 0,
  loading = false,
  handlePaginationChange = () => {},
  currentDraftLoading = {},
  customClass = "",
}) => {
  const [smallColumnWidth, setSmallColumnWidth] = useState({});
  const [currentColumns, setCurrentColumns] = useState([]);
  const { loading: draftLoading, currentId } = currentDraftLoading;

  useEffect(() => {
    setSmallColumnWidth({});
    if (isEmpty(columns)) {
      return;
    }
    setCurrentColumns(columns);
  }, [data]);

  return (
    <div className="margin-top-md table-container">
      <TableHeader
        columns={currentColumns}
        setSmallColumnWidth={setSmallColumnWidth}
        smallColumnWidth={smallColumnWidth}
      />
      <div
        id="scrollableDiv"
        className={`scrollable-container ${customClass ? customClass : ""}`}
      >
        <List
          split={false}
          loading={loading}
          dataSource={data.items}
          renderItem={(item) => (
            <List.Item key={item.id} className="list-item-wrapper">
              <div id={item.id} className="full-width">
                <Flex
                  justify="space-between"
                  align="center"
                  className="list-item item"
                  gap={32}
                >
                  {currentColumns.map((column, index) => {
                    const columnRender =
                      column.render &&
                      column.render(item, draftLoading, currentId);
                    const hasValue =
                      columnRender &&
                      columnRender.props &&
                      (!!columnRender.props.text ||
                        !!columnRender.props.children ||
                        !!columnRender.props.onClick);
                    const val = !!item[column.dataIndex]
                      ? item[column.dataIndex]
                      : "-";

                    return (
                      <div
                        key={index}
                        id={`col-item-wrapper-${column.dataIndex}`}
                        className={`col-item-data-wrapper ${
                          column.isSmall ? "small-col" : ""
                        }`}
                      >
                        <div
                          id={`col-item-data-${column.dataIndex}`}
                          style={{
                            width: smallColumnWidth[column.dataIndex],
                            minWidth: smallColumnWidth[column.dataIndex],
                          }}
                        >
                          {hasValue && columnRender ? columnRender : val}
                        </div>
                      </div>
                    );
                  })}
                </Flex>
              </div>
            </List.Item>
          )}
        />
      </div>
      <div className="text-center margin-top-lg">
        <Pagination
          showSizeChanger={false}
          pageSize={25}
          current={currentPage || 1}
          total={data.count}
          onChange={handlePaginationChange}
          showTitle={false}
        />
      </div>
    </div>
  );
};

export default CustomTable;
