import { useState } from "react";
import { getValueFromBoolean } from "../../utils/utils.js";
import FloatingLabelSelectItem from "../inputs/FloatingLabelSelectItem.js";
import FloatingLabelInput from "../inputs/FloatingLabelInput.js";
import "./styles.css";

const ElectricalForm = ({ options = {}, initialValues = {}, errors = [] }) => {
  const hasAcDisconnect = getValueFromBoolean(initialValues.acDisconnect);
  const [selectedAcDisconnect, setSelectedAcDisconnect] = useState(
    localStorage.getItem("solar-request-selected-acDisconnect") ||
      hasAcDisconnect
  );

  const {
    indicateMspLocation,
    mainElectricalPanelUpgrade,
    mspBusRating,
    mainBreakerRating,
    mspBusBarFeedLocation,
    busRating,
    breakerRating,
    acDisconnect,
    pvProductionMeter,
  } = options;

  const onAcDisconnectChange = (selected) => {
    setSelectedAcDisconnect(selected);
    localStorage.setItem("solar-request-selected-acDisconnect", selected);
  };

  return (
    <div className="form-wrapper margin-top-xxl">
      <FloatingLabelSelectItem
        name="indicateMspLocation"
        options={indicateMspLocation}
        initialValue={initialValues.indicateMspLocation}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="mainElectricalPanelUpgrade"
        options={mainElectricalPanelUpgrade}
        initialValue={getValueFromBoolean(
          initialValues.mainElectricalPanelUpgrade
        )}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="mspBusRating"
        options={mspBusRating}
        initialValue={initialValues.mspBusRating}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="mainBreakerRating"
        options={mainBreakerRating}
        initialValue={initialValues.mainBreakerRating}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="mspBusBarFeedLocation"
        options={mspBusBarFeedLocation}
        initialValue={initialValues.mspBusBarFeedLocation}
        errors={errors}
      />
      <p className="text-semibold text-sm margin-bottom-sm">
        Existing Service Sub panel
      </p>
      <FloatingLabelSelectItem
        name="existingBusRating"
        options={busRating}
        initialValue={(initialValues.subpanelExisting || {}).busRating}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="existingBreakerRating"
        options={breakerRating}
        initialValue={(initialValues.subpanelExisting || {}).breakerRating}
        errors={errors}
      />
      <p className="text-semibold text-sm margin-bottom-sm">
        New Service Sub Panel
      </p>
      <FloatingLabelSelectItem
        name="newBusRating"
        options={busRating}
        initialValue={(initialValues.subpanelNew || {}).busRating}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="newBreakerRating"
        options={breakerRating}
        initialValue={(initialValues.subpanelNew || {}).breakerRating}
        errors={errors}
      />
      <FloatingLabelSelectItem
        name="acDisconnect"
        options={acDisconnect}
        onChange={onAcDisconnectChange}
        initialValue={selectedAcDisconnect}
        errors={errors}
      />
      {selectedAcDisconnect === "Yes" && (
        <FloatingLabelInput
          name="size"
          errors={errors}
          initialValue={initialValues.size}
        />
      )}
      <FloatingLabelSelectItem
        name="pvProductionMeter"
        options={pvProductionMeter}
        initialValue={getValueFromBoolean(initialValues.pvProductionMeter)}
        errors={errors}
      />
      <div className="margin-bottom-xxxl">
        <FloatingLabelInput
          name="electricalNote"
          errors={errors}
          initialValue={initialValues.note}
        />
      </div>
    </div>
  );
};

export default ElectricalForm;
