import React from "react";
import { Dropdown, Menu } from "antd";
import { filters } from "./filters";
import "./styles.css";

const NewFilterDropdown = ({
  filter = "Filters",
  items = [],
  customClass = "",
}) => {
  const menu = <Menu className="dropdown-menu" items={items} />;
  return (
    <Dropdown
      overlayClassName={`filters-container ${customClass ? customClass : ""}`}
      menu={menu}
      trigger={["click"]}
      placement="bottomRight"
      dropdownRender={() => (
        <div className="dropdown-wrapper">
          {React.cloneElement(menu, {
            style: {},
          })}
        </div>
      )}
    >
      <div className="text-gray">{(filters[filter] || {}).render()}</div>
    </Dropdown>
  );
};

export default NewFilterDropdown;
