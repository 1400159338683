export const globalStyles = {
  colorPrimary: "#4D6EF2",
  screenMDMin: 767,
  screenMD: 768,
};

export const menuStyles = {
  darkItemColor: "#FFFFFF",
  darkItemSelectedBg: "#4d6ef2",
  itemBorderRadius: 8,
  itemPaddingInline: 0,
  itemMarginInline: 0,
  itemMarginBlock: 0,
  darkSubMenuItemBg: "transparent",
  iconSize: 22
};

export const buttonStyles = {
  defaultBorderColor: "#4D6EF2",
  defaultBg: "#F6F8FF",
  defaultColor: "#4D6EF2"
};

export const tableStyles = {
  headerBg: "#49576B",
  headerColor: "#FFFFFF"
};

export const resultStyles = {
  iconFontSize: 45,
};

export const badgeStyles = {
  indicatorHeight: 18,
}