const ProjectInformationIcon = ({ fill = "none" }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2473_29061)">
        <path
          d="M28.4418 22.3081C28.3256 22.1918 28.1644 22.125 28 22.125C27.8356 22.125 27.6744 22.1918 27.5581 22.3081C27.4419 22.4244 27.375 22.5849 27.375 22.75C27.375 22.9144 27.4418 23.0756 27.5581 23.1918C27.6744 23.3081 27.8356 23.375 28 23.375C28.1644 23.375 28.3256 23.3081 28.4418 23.1918C28.5581 23.0756 28.625 22.9144 28.625 22.75C28.625 22.5856 28.5581 22.4244 28.4418 22.3081Z"
          fill="#4D6EF2"
        />
        <path
          d="M31.375 30.75H28.625V24.9375C28.625 24.5923 28.3451 24.3125 28 24.3125C27.6549 24.3125 27.375 24.5923 27.375 24.9375V30.75H7.625V24.875C7.625 24.5298 7.34513 24.25 7 24.25C6.65487 24.25 6.375 24.5298 6.375 24.875V30.75H4.625V14.3126L16 5.43214L27.375 14.3126V20.25C27.375 20.5951 27.6549 20.875 28 20.875C28.3451 20.875 28.625 20.5951 28.625 20.25V15.2885L28.7342 15.3738C28.8478 15.4625 28.9835 15.5061 29.1186 15.5061C29.2794 15.5061 29.4395 15.4443 29.5607 15.3231L31.8151 13.0687C31.9416 12.9421 32.0079 12.7672 31.9969 12.5885C31.9859 12.4097 31.8989 12.2443 31.7577 12.1341L28 9.20045V2.93745C28 2.59227 27.7201 2.31245 27.375 2.31245H23.7526C23.4075 2.31245 23.1276 2.59227 23.1276 2.93745V5.39658L16.3846 0.132328C16.1586 -0.0441094 15.8414 -0.0441094 15.6154 0.132328L0.24225 12.1341C0.101062 12.2443 0.014 12.4097 0.003 12.5885C-0.0079375 12.7672 0.0583125 12.942 0.184875 13.0687L2.43919 15.3231C2.66231 15.5461 3.01694 15.568 3.26575 15.3738L3.375 15.2885V30.75H0.625C0.279875 30.75 0 31.0298 0 31.375C0 31.7201 0.279875 32 0.625 32H31.375C31.7201 32 32 31.7201 32 31.375C32 31.0298 31.7201 30.75 31.375 30.75ZM24.3776 3.56245H26.75V8.22458L24.3776 6.37245V3.56245ZM2.93225 14.0483L1.5685 12.6845L16 1.41783L30.4315 12.6845L29.0678 14.0483L16.3846 4.14658C16.2716 4.05833 16.1358 4.0142 16 4.0142C15.8642 4.0142 15.7284 4.05833 15.6154 4.14658L2.93225 14.0483Z"
          fill="#4D6EF2"
        />
        <path
          d="M22 13.625H10C9.65488 13.625 9.375 13.9048 9.375 14.25V26.25C9.375 26.5951 9.65488 26.875 10 26.875H22C22.3451 26.875 22.625 26.5951 22.625 26.25V14.25C22.625 13.9048 22.3451 13.625 22 13.625ZM15.375 25.625H10.625V20.875H15.375V25.625ZM15.375 19.625H10.625V14.875H15.375V19.625ZM21.375 25.625H16.625V20.875H21.375V25.625ZM21.375 19.625H16.625V14.875H21.375V19.625Z"
          fill="#4D6EF2"
        />
        <path
          d="M7 21.7812C6.65488 21.7812 6.375 22.0611 6.375 22.4062V22.4163C6.375 22.7614 6.65488 23.0413 7 23.0413C7.34512 23.0413 7.625 22.7614 7.625 22.4163V22.4062C7.625 22.0611 7.34512 21.7812 7 21.7812Z"
          fill="#4D6EF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2473_29061">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProjectInformationIcon;
