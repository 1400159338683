import { useState } from "react";
import { Flex, Form, Switch } from "antd";
import {
  createCompactDraft,
  submitDraft,
  uploadDraftAttachment,
} from "../../services/drafts.js";
import useSetUploadError from "../../hooks/useSetUploadError.js";
import UploadWithDragAndDrop from "../uploadWithDragAndDrop/index.js";
import CustomForm from "../form/index.js";
import FloatingLabelInput from "../inputs/FloatingLabelInput.js";
import ModalFooter from "../modal/ModalFooter.js";
import "./styles.css";

const allPossibleTypes = ["solar", "electrical", "structural"];

const CompactForm = ({ selectedTypes, formTitle, onCancel, onSubmit }) => {
  const [isLink, setIsLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const {
    errors,
    setErrors,
    filesList,
    setFilesList,
    currentError,
    setUploadErrorField,
  } = useSetUploadError({ form });

  const onFinish = (values) => {
    if (!isLink && setUploadErrorField().errors[0]) {
      return;
    }
    setLoading(true);
    const draftTypes = allPossibleTypes.reduce((result, type) => {
      result[type] = selectedTypes.includes(type);
      return result;
    }, {});
    values.draftTypes = draftTypes;
    values.name = values.projectName;
    delete values.projectName;
    createCompactDraft(values)
      .then((response) => {
        if (!values.clientAttachmentLink) {
          const uploadPromises = filesList.map((item) => {
            const formData = new FormData();
            formData.append("file", item);
            return uploadDraftAttachment(response.id, formData);
          });
          return Promise.all(uploadPromises).then(() =>
            submitDraft(response.id).then(() => {
              onSubmit();
              onCancel();
            })
          );
        }
        submitDraft(response.id).then(() => {
          onSubmit();
          onCancel();
        });
      })
      .catch((error) => {
        console.error({ error });
      })
      .finally(() => setLoading(false));
  };

  const onSwitchChange = () => {
    setIsLink((prevValue) => !prevValue);
    if (isLink) {
      form.setFieldsValue({ clientAttachmentLink: "" });
    }
  };

  return (
    <div className="forms-container">
      <p className="text-center text-lg text-semibold margin-bottom-xl">
        {formTitle}
      </p>
      <CustomForm
        form={form}
        onFinish={onFinish}
        setUploadErrorField={setUploadErrorField}
        setErrors={setErrors}
      >
        <FloatingLabelInput name="projectName" errors={errors} />
        <FloatingLabelInput name="address" errors={errors} />
        <FloatingLabelInput name="city" errors={errors} />
        <FloatingLabelInput name="state" errors={errors} />
        <FloatingLabelInput name="zipCode" errors={errors} />
        <Flex justify="space-between">
          <p className="text-semibold text-sm margin-bottom-xs">
            {!isLink ? "Upload PDF files" : "Insert Link"}
          </p>
          <Switch
            checkedChildren="PDF"
            unCheckedChildren="Link"
            onChange={onSwitchChange}
            className="compact-switch"
          />
        </Flex>
        {!isLink ? (
          <Form.Item name="upload">
            <UploadWithDragAndDrop
              accept="application/pdf"
              requestOnUpload={false}
              setList={setFilesList}
              currentError={currentError}
              setUploadErrorField={setUploadErrorField}
            />
          </Form.Item>
        ) : (
          <FloatingLabelInput name="clientAttachmentLink" errors={errors} />
        )}
        <div
          className={`margin-bottom-xxxl ${
            filesList.length ? "margin-top-md" : ""
          }`}
        >
          <FloatingLabelInput name="note" errors={errors} />
        </div>
        <ModalFooter
          htmlType="submit"
          submitTitle="Submit"
          onCancel={onCancel}
          loading={loading}
          disabled={loading}
          cancelType="text"
        />
      </CustomForm>
    </div>
  );
};

export default CompactForm;
