// import { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { useWindowSize } from "./useWindowSize";

// export function useTableMethods({ draftId, fetchRequest }) {
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const query = searchParams.get("search") || "";
//   const page = parseInt(searchParams.get("page"), 10) || 1;
//   const status = searchParams.get("status") || "";
//   const type = searchParams.get("type") || "";
//   const [selectedStatus, setSelectedStatus] = useState(status);
//   const [selectedType, setSelectedType] = useState(type);
//   const [searchQuery, setSearchQuery] = useState(query);
//   const [data, setData] = useState();
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(page);
//   const navigate = useNavigate();
//   const { isMobile } = useWindowSize();

//   const fetchData = (props) => {
//     setLoading(true);
//     fetchRequest({
//       draftId,
//       offset: !isMobile ? (currentPage - 1) * 25 : 0,
//       limit: 25,
//       status: selectedStatus,
//       searchQuery,
//       type: selectedType,
//       ...props,
//     })
//       .then(setData)
//       .catch((error) =>
//         console.error("useTableMethods - fetchData --- catch ---", error)
//       )
//       .finally(() => setLoading(false));
//   };

//   const fetchMoreData = (props) => {
//     setLoading(true);
//     fetchRequest({
//       draftId,
//       offset: data.items.length,
//       limit: 25,
//       searchQuery,
//       status: selectedStatus,
//       type: selectedType,
//       ...props,
//     })
//       .then((newData) =>
//         setData({
//           count: newData.count,
//           items: [...data.items, ...newData.items],
//         })
//       )
//       .catch((error) =>
//         console.error("GET-DraftsBYADMIN - CATCH --- ERROR", error)
//       )
//       .finally(() => setLoading(false));
//   };

//   useEffect(() => {
//     if (!!searchQuery) {
//       const timeoutId = setTimeout(fetchData, 500);
//       return () => clearTimeout(timeoutId);
//     } else {
//       fetchData();
//     }
//     fetchData();
//   }, [currentPage, selectedStatus, searchQuery]);

//   const handleResetFilter = () => {
//     setSelectedStatus("");
//     if (!isMobile) {
//       setCurrentPage(1);
//       searchParams.delete("page");
//     }
//     searchParams.delete("status");
//     navigate(`${location.pathname}?${searchParams.toString()}`);
//   };

//   const onFilterChange = (status) => {
//     setSelectedStatus(status);
//     if (!isMobile) {
//       setCurrentPage(1);
//       searchParams.set("page", "1");
//     }
//     searchParams.set("status", status);
//     navigate(`${location.pathname}?${searchParams.toString()}`);
//   };

//   const handlePaginationChange = (page) => {
//     setCurrentPage(page);
//     searchParams.set("page", page);
//     navigate(`${location.pathname}?${searchParams.toString()}`);
//   };

//   const handleSearch = (value) => {
//     setCurrentPage(1);
//     setSearchQuery(value);
//     value ? searchParams.set("search", value) : searchParams.delete("search");
//     searchParams.set("page", "1");
//     navigate(`${location.pathname}?${searchParams.toString()}`);
//   };
//   const handleResetSearchQuary = () => {
//     setSearchQuery("");
//   };
//   return {
//     data,
//     setData,
//     selectedStatus,
//     currentPage,
//     searchQuery,
//     setSearchQuery,
//     loading,
//     fetchData,
//     fetchMoreData,
//     onFilterChange,
//     handleSearch,
//     handlePaginationChange,
//     handleResetFilter,
//     handleResetSearchQuary,
//   };
// }

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "./useWindowSize";

export function useTableMethods({ draftId, fetchRequest, defaultFilters }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const initialFilters = { ...defaultFilters };
  for (const filter in defaultFilters) {
    initialFilters[filter] = searchParams.get(filter) || defaultFilters[filter];
  }
  const query = searchParams.get("search") || "";
  const [searchQuery, setSearchQuery] = useState(query);
  const [filters, setFilters] = useState(initialFilters);
  const [data, setData] = useState({ count: 0, items: [] });
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page"), 10) || 1
  );
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  const fetchData = (props) => {
    setLoading(true);
    fetchRequest({
      draftId,
      offset: !isMobile ? (currentPage - 1) * 25 : data.items.length,
      limit: 25,
      searchQuery,
      ...filters,
      ...props,
    })
      .then((response) =>
        setData((prevData) => {
          return !isMobile
            ? response
            : {
                count: response.count,
                items: [...prevData.items, ...response.items],
              };
        })
      )
      .catch((error) => console.error("fetchData error:", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (searchQuery) {
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchData();
    }
  }, [searchQuery, currentPage, filters]);

  const handleFilterChange = ({ filterName, value, filters }) => {
    if (isMobile) {
      setData({ count: 0, items: [] });
    }
    if (!isMobile) {
      setCurrentPage(1);
      searchParams.set("page", "1");
    }
    if (filters) {
      setFilters((prevFilters) => {
        return { ...prevFilters, ...filters };
      });
      for (const key in filters) {
        if (!filters[key]) {
          searchParams.delete(key);
        }
        if (filters[key] !== null && filters[key] !== undefined) {
          searchParams.set(key, filters[key]);
        }
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
      return;
    }
    const newFilters = { ...filters, [filterName]: value };
    setFilters(newFilters);
    searchParams.set(filterName, value);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
    searchParams.set("page", page);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleSearch = (value) => {
    if (isMobile) {
      setData({ count: 0, items: [] });
    }
    setSearchQuery(value);
    value ? searchParams.set("search", value) : searchParams.delete("search");
    searchParams.set("page", "1");
    navigate(`${location.pathname}?${searchParams.toString()}`);
    setCurrentPage(1);
  };

  const handleResetFilter = (type) => {
    if (isMobile) {
      setData({ count: 0, items: [] });
    }
    if (typeof type !== "string") {
      setFilters(defaultFilters);
      for (const filter in filters) {
        searchParams.delete(filter);
      }
      navigate(`${location.pathname}?${searchParams.toString()}`);
      return;
    }
    const resetedFilter = { [type]: "" };
    const newFilters = { ...filters, ...resetedFilter };
    setFilters(newFilters);
    setCurrentPage(1);
    searchParams.delete("page");
    for (const filter in filters) {
      if (filter === type) {
        searchParams.delete(filter);
      }
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleResetSearchQuery = () => {
    handleSearch("");
  };
  
  return {
    data,
    setData,
    filters,
    currentPage,
    loading,
    fetchData,
    handleFilterChange,
    handlePaginationChange,
    handleSearch,
    handleResetFilter,
    handleResetSearchQuery,
  };
}
