import { Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { statuses } from "../../utils/utils";

export const filters = {
  status: {
    title: "Status",
    render: () => (
      <Space>
        Status
        <DownOutlined />
      </Space>
    ),
    // items: ({ role, filter, onFilterChange }) =>
    //   Object.values(statuses[role][filter]).map((item) => {
    //     return {
    //       label: (
    //         <div
    //           className={`status-container status-item cursor-pointer ${
    //             selectedStatus === item && "activeItem"
    //           }`}
    //           onClick={() => onFilterChange(item)}
    //         >
    //           adsd
    //           {/* <Status status={item} /> */}
    //         </div>
    //       ),
    //       key: item,
    //     };
    //   }),
  },
  type: {
    title: "Type",
    render: () => (
      <Space>
        Type
        <DownOutlined />
      </Space>
    ),
  }
};
