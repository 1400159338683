import { Space } from "antd";
import { Link } from "react-router-dom";
import RegisterForm from "./components/RegisterForm";
import ByGmail from "./components/ByGmail";
import "./styles.css";

const Register = () => {
  return (
    <div className="wrapper">
      <div className="auth-forms-section-content">
        <RegisterForm />
        <ByGmail />
        <div className="other-mode margin-top-sm">
          <Space>
            <span>Have an account?</span>
            <Link to="/sign-in">
              <p className="is-link text-sm">Sign In</p>
            </Link>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default Register;
