import { Flex } from "antd";
import CustomDrawer from "../../../components/drawer/index.js";
import SendRevisionsForm from "./SendRevisionsForm.js";
import NewInfoCard from "../../../components/infoCard/NewInfoCard.js";
import IconWithText from "../../../components/iconWithText/index.js";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice.js.js";
import { FileSyncOutlined } from "@ant-design/icons";
import Attachments from "../../../components/attachments/index.js";
import CustomCard from "../../../components/card/index.js";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

const Drawing = ({
  draft = {},
  onDownloadClick,
  onDownloadAll,
  onSendRevision,
  sendRevisionModalOpen,
  setSendRevisionModalOpen,
  buttonDisabled,
}) => {
  const { role } = useSelector(selectUser);
  const dataClone = cloneDeep(draft);

  if (isEmpty(dataClone.drawings)) {
    return (
      <Flex className="drawing-container">
        <CustomCard
          customClass="drawing-empty"
          description="The draft has no drawing"
        />
      </Flex>
    );
  }

  const fields = {
    drawings: {
      render: ({ id, drawings }, { onDownloadClick, onDownloadAll }) => {
        if (!drawings.length) {
          return null;
        }
        return (
          <div key={id} className="margin-top-md">
            <Attachments
              id={id}
              title="Drawing"
              files={drawings}
              onDownloadClick={onDownloadClick}
              onDownloadAll={onDownloadAll}
              buttonDisabled={buttonDisabled}
            />
          </div>
        );
      },
    },
    adminNote: {
      label: "Notes",
    },
  };

  const renderActions = () => {
    const arr = [];
    if (role === "contractor") {
      arr.push(
        <div className="is-link" onClick={() => setSendRevisionModalOpen(true)}>
          <IconWithText
            text={"Send Revision"}
            IconComponent={FileSyncOutlined}
            styles={{ justifyContent: "center" }}
          />
        </div>
      );
    }
    return arr;
  };
  return (
    <div className="drawing-container">
      <NewInfoCard
        item={dataClone}
        fields={fields}
        renderActions={renderActions()}
        params={{ onDownloadClick, onDownloadAll }}
      />
      <CustomDrawer
        open={sendRevisionModalOpen}
        title="Revision"
        onCancel={() => setSendRevisionModalOpen(false)}
        content={
          <SendRevisionsForm
            onFinish={onSendRevision}
            onCancel={() => setSendRevisionModalOpen(false)}
          />
        }
      />
    </div>
  );
};

export default Drawing;
