import axios from "axios";
import api from "../utils/request";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const register = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/sign_up`, userData);
    return response.data;
  } catch (error) {
    console.error("Error in registration:", error);
    throw error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/verify_email`, {
      token,
    });
    return response;
  } catch (error) {
    console.error("Verify Failed");
    throw error.response.data;
  }
};

export const login = async (credentials) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/auth/sign_in`,
      credentials
    );
    return response.data;
  } catch (error) {
    console.error("AUTH -login - catch", error);
    throw error;
  }
};

export const googleLogin = async (token) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/google_sign_in`, {
      token,
    });
    return response.data;
  } catch (error) {
    console.error("AUTH - Google login - catch", error);
    throw error;
  }
};

export const forgotPassword = async (credentials) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/auth/forgot_passsword`,
      credentials
    );
    return response.data;
  } catch (error) {
    console.error("AUTH -forgot-password - catch", error);
    throw error;
  }
};

export const resetPassword = async (credentials) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/auth/reset_passsword`,
      credentials
    );
    return response.data;
  } catch (error) {
    console.error("AUTH -forgot-reset - catch", error);
    throw error.response.data;
  }
};

export const logout = async () => {
  try {
    const response = await api.get("/auth/sign_out");
    return response.data;
  } catch (error) {
    console.error(" ---- user - logout - catch", error);
    throw error;
  }
};
