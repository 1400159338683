import { useEffect, useState } from "react";
import { Divider } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentDraft } from "../../../redux/slices/draftSlice";
import { getDraftByContractor } from "../../../services/drafts";
import { getRevisionByContractor } from "../../../services/revisions";
import PaymentItem from "./PaymentItem";
import PaySection from "./PaySection";
import CustomCard from "../../../components/card";
import isEmpty from "lodash/isEmpty";

const PaymentMethods = ({
  paymentMethods,
  onPay,
  onAddClick,
  onCardDelete,
  handleDefault,
  handleSelectForPaying,
  selectedItemId,
}) => {
  const [loading, setLoading] = useState(true);
  const [payData, setPayData] = useState({});
  const draft = useSelector(selectCurrentDraft);
  const { price = {} } = payData;
  const { draftId, revisionId } = useParams();

  const handleSelectedItemChange = (id) => {
    if (revisionId || draftId) {
      handleSelectForPaying(id);
      return;
    }
    handleDefault(id);
  };

  useEffect(() => {
    if (!isEmpty(draft)) {
      setPayData({
        id: draft.id,
        projectName: draft.name,
        price: draft?.price?.total,
        status: draft.status,
      });
      setLoading(false);
      return;
    }
    if (draftId) {
      getDraftByContractor(draftId)
        .then((response) => {
          setPayData({
            id: response.id,
            projectName: response.name,
            price: response?.price?.total,
            status: response.status,
          });
        })
        .finally(() => setLoading(false));
    }
    if (revisionId) {
      getRevisionByContractor(revisionId)
        .then((response) => {
          setPayData({
            id: response.id,
            projectName: response.draftName,
            price: response?.price?.total,
            status: response.status,
          });
        })
        .finally(() => setLoading(false));
      return;
    }
    if (!draftId && !revisionId) {
      setPayData({});
      setLoading(false);
    }
  }, [draftId, draft, revisionId]);

  const renderComponent = () => {
    return (
      <>
        {paymentMethods.map((item) => (
          <div key={item.id}>
            <PaymentItem
              data={item}
              onCardDelete={onCardDelete}
              selected={selectedItemId === item.id}
              handleSelectedItemChange={() => handleSelectedItemChange(item.id)}
            />
          </div>
        ))}
        {paymentMethods.length < 3 && (
          <div>
            <PaymentItem onAddClick={onAddClick} />
          </div>
        )}
        <>
          <Divider className="margin-top-xxs margin-bottom-md" />
          <p className="margin-top-sm text-gray">
            You can add maximum 3 credit cards
          </p>
        </>
      </>
    );
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <CustomCard
        customClass="payment-methods-container padding-md"
        description={renderComponent()}
      />
      {!isEmpty(payData) && (
        <CustomCard
          customClass="payment-methods-container padding-md margin-top-xl"
          description={
            <div>
              <PaySection
                projectName={payData.projectName}
                price={price}
                onPayClick={onPay}
                noCard={isEmpty(paymentMethods)}
              />
            </div>
          }
        />
      )}
    </>
  );
};

export default PaymentMethods;
