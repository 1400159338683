import { useEffect, useState } from "react";
import { Checkbox, Flex, Form } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { addNewEmailOption, getEmailsOptions } from "../../services/drafts";
import MultipleTagsEmailInput from "../inputs/MultipleTagsEmailInput";
import FloatingLabelInput from "../inputs/FloatingLabelInput";
import CreatableMultipleSelect from "../inputs/CreatableMultipleSelect";
import IconWithText from "../iconWithText";
import ModalFooter from "../modal/ModalFooter";
import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import "./styles.css";

const EmailForm = ({
  onFinish,
  files = [],
  onFileSelect,
  onFileSelectAll,
  selectedAll,
  selectedFiles = [],
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [emails, setEmails] = useState([
    "narek.h@engineerinc.io",
    "habet.y@engineerinc.io",
    "armine@engineerinc.io",
  ]);
  const [form] = Form.useForm();

  useEffect(() => {
    getEmailsOptions()
      .then((response) => {
        const data = response.map((item) => ({ value: item, label: item }));
        setOptions(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const onFinishFailed = () => {
    const emailsError = form.getFieldsError(["emails"]);
    setErrors((prev) => [...prev, ...emailsError]);
  };

  const handleBlur = (name, values) => {
    const allErrors = form.getFieldsError();
    if (isEmpty(values)) {
      const error = {
        name: [name],
        errors: ["The field is required"],
      };
      const newArr = allErrors.map((i) => (i.name[0] === name ? error : i));
      const filteredArr = newArr.filter((f) => !!f.errors[0]);
      form.setFields([...filteredArr]);
      setErrors([...filteredArr]);
      return error;
    } else {
      form.setFields([{ name: [name], errors: [] }]);
      setErrors([]);
    }
  };
  const errorMessage = errors.find((error) => error.name[0] === "emails")
    ?.errors[0];
  return (
    <div className="email-form-container  forms-container">
      <Form
        className="email-form"
        labelCol={{ style: { color: "#7A7A7A" } }}
        form={form}
        validateTrigger="onBlur"
        onFinishFailed={onFinishFailed}
        onFinish={(values) => {
          const optionsValues = options.map((option) => option.value);
          const missingValue = difference(values.emails, optionsValues);
          if (missingValue.length > 0) {
            addNewEmailOption(values.emails);
          }
          setLoading(true);
          return onFinish({ ...values, cc: emails });
        }}
        requiredMark={false}
      >
        <div className="email-form-input">
          <CreatableMultipleSelect
            name="emails"
            options={options}
            setOptions={setOptions}
            resetValue={(selectedOptions) =>
              form.setFieldValue("emails", selectedOptions)
            }
            handleBlur={handleBlur}
          />
          <p className="creatable-select-prefix-label">To</p>
          <p className="text-error margin-bottom-md">{errorMessage}</p>
        </div>
        <Form.Item name="cc" colon={false}>
          <MultipleTagsEmailInput emails={emails} setEmails={setEmails} />
          <p className="creatable-select-prefix-label">CC</p>
        </Form.Item>
        <FloatingLabelInput name="notes" errors={errors} />
        {!!files.length && (
          <Form.Item name="clientAttachments" className="margin-top-xxxl">
            <Flex justify="space-between">
              <p>Attached Files</p>
              {files.length > 1 && (
                <Checkbox
                  onChange={(e) => onFileSelectAll(e.target.checked)}
                  defaultChecked={selectedAll}
                  checked={selectedAll}
                  className="select-all-checkbox"
                >
                  Select All
                </Checkbox>
              )}
            </Flex>
            <div className="files-container">
              <div className="files">
                {files.map((file) => (
                  <Flex
                    key={file.key || file.fileName}
                    className="file-item"
                    justify="space-between"
                    gap={16}
                  >
                    <IconWithText
                      text={file.fileName}
                      IconComponent={PaperClipOutlined}
                    />
                    <Checkbox
                      checked={selectedFiles.includes(
                        file.key || file.fileName
                      )}
                      onChange={(e) =>
                        onFileSelect(
                          file.key || file.fileName,
                          e.target.checked
                        )
                      }
                    />
                  </Flex>
                ))}
              </div>
            </div>
          </Form.Item>
        )}
        <div className="margin-top-xl">
          <ModalFooter
            htmlType="submit"
            submitTitle="Send"
            onCancel={onCancel}
            loading={loading}
            disabled={loading}
          />
        </div>
      </Form>
    </div>
  );
};

export default EmailForm;
