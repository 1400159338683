const ProjectInformationIconSmall = ({ fill = "none" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
    >
      <g clipPath="url(#clip0_2981_29355)">
        <path
          d="M21.3314 16.7311C21.2442 16.6439 21.1233 16.5938 21 16.5938C20.8767 16.5938 20.7558 16.6439 20.6686 16.7311C20.5814 16.8183 20.5312 16.9387 20.5312 17.0625C20.5312 17.1858 20.5814 17.3067 20.6686 17.3939C20.7558 17.481 20.8767 17.5312 21 17.5312C21.1233 17.5312 21.2442 17.4811 21.3314 17.3939C21.4186 17.3067 21.4688 17.1858 21.4688 17.0625C21.4688 16.9392 21.4186 16.8183 21.3314 16.7311Z"
          fill="#4681F4"
        />
        <path
          d="M23.5312 23.0625H21.4688V18.7031C21.4688 18.4442 21.2588 18.2343 21 18.2343C20.7412 18.2343 20.5312 18.4442 20.5312 18.7031V23.0625H5.71875V18.6562C5.71875 18.3974 5.50884 18.1875 5.25 18.1875C4.99116 18.1875 4.78125 18.3974 4.78125 18.6562V23.0625H3.46875V10.7344L12 4.07411L20.5312 10.7344V15.1875C20.5312 15.4463 20.7412 15.6562 21 15.6562C21.2588 15.6562 21.4688 15.4463 21.4688 15.1875V11.4663L21.5507 11.5303C21.6359 11.5968 21.7376 11.6296 21.839 11.6296C21.9595 11.6296 22.0796 11.5832 22.1706 11.4923L23.8613 9.80153C23.9562 9.70656 24.0059 9.5754 23.9977 9.44134C23.9895 9.30728 23.9242 9.1832 23.8183 9.10056L21 6.90034V2.20309C21 1.9442 20.7901 1.73434 20.5312 1.73434H17.8145C17.5556 1.73434 17.3457 1.9442 17.3457 2.20309V4.04743L12.2884 0.0992461C12.119 -0.033082 11.881 -0.033082 11.7115 0.0992461L0.181688 9.10056C0.0757969 9.1832 0.0105 9.30728 0.00225 9.44134C-0.00595312 9.5754 0.0437344 9.70651 0.138656 9.80153L1.82939 11.4923C1.99673 11.6596 2.2627 11.676 2.44931 11.5303L2.53125 11.4663V23.0625H0.46875C0.209906 23.0625 0 23.2724 0 23.5312C0 23.7901 0.209906 24 0.46875 24H23.5312C23.7901 24 24 23.7901 24 23.5312C24 23.2724 23.7901 23.0625 23.5312 23.0625ZM18.2832 2.67184H20.0625V6.16843L18.2832 4.77934V2.67184ZM2.19919 10.5362L1.17638 9.51339L12 1.06337L22.8236 9.51339L21.8008 10.5362L12.2884 3.10993C12.2037 3.04375 12.1019 3.01065 12 3.01065C11.8981 3.01065 11.7963 3.04375 11.7116 3.10993L2.19919 10.5362Z"
          fill="#4681F4"
        />
        <path
          d="M16.5 10.2188H7.5C7.24116 10.2188 7.03125 10.4286 7.03125 10.6875V19.6875C7.03125 19.9463 7.24116 20.1562 7.5 20.1562H16.5C16.7588 20.1562 16.9688 19.9463 16.9688 19.6875V10.6875C16.9688 10.4286 16.7588 10.2188 16.5 10.2188ZM11.5312 19.2188H7.96875V15.6562H11.5312V19.2188ZM11.5312 14.7188H7.96875V11.1562H11.5312V14.7188ZM16.0312 19.2188H12.4688V15.6562H16.0312V19.2188ZM16.0312 14.7188H12.4688V11.1562H16.0312V14.7188Z"
          fill="#4681F4"
        />
        <path
          d="M5.25 16.3359C4.99116 16.3359 4.78125 16.5458 4.78125 16.8047V16.8122C4.78125 17.0711 4.99116 17.281 5.25 17.281C5.50884 17.281 5.71875 17.0711 5.71875 16.8122V16.8047C5.71875 16.5458 5.50884 16.3359 5.25 16.3359Z"
          fill="#4681F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_2981_29355">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProjectInformationIconSmall;
