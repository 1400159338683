import api from "../utils/request";

export const createDraft = async (values) => {
  try {
    const url = `/drafts/solar`;
    const response = await api.post(url, values);
    return response.data.data;
  } catch (error) {
    console.error("drafts - createDraft - catch", error);
    throw error.response.data;
  }
};

export const updateDraft = async (values, draftId, step = "project") => {
  try {
    const url = `/drafts/solar/${draftId}/${step}`;
    const response = await api.put(url, values);
    return response.data.data;
  } catch (error) {
    console.error("drafts - updateDraft - catch", error);
    throw error.response.data;
  }
};

export const getDraftOptions = async (step = "solar") => {
  try {
    const url = `/drafts/options/${step}`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - getDraftOptions - catch", error);
    throw error.response.data;
  }
};

export const uploadDraftAttachment = async (draftId, formData) => {
  try {
    const url = `/drafts/${draftId}/attachment`;
    const response = await api.put(url, formData);
    return response.data.data;
  } catch (error) {
    console.error("drafts - getDraftOptions - catch", error);
    throw error.response.data;
  }
};

export const deleteDraftAttachment = async (draftId, fileName) => {
  try {
    const url = `/drafts/${draftId}/attachment/${fileName}`;
    const response = await api.delete(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - deleteDraftAttachment - catch", error);
    throw error.response.data;
  }
};

export const deleteAttachmentsByDraftId = async (draftId) => {
  try {
    const url = `/drafts/${draftId}/attachments`;
    const response = await api.delete(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - deleteAttachmentsByDraftId - catch", error);
    throw error.response.data;
  }
};

export const submitDraft = async (draftId) => {
  try {
    const url = `/drafts/${draftId}/submit`;
    const response = await api.patch(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - submitDraft - catch", error);
    throw error.response.data;
  }
};

export const createCompactDraft = async (values) => {
  try {
    const url = `/drafts/compact`;
    const response = await api.post(url, values);
    return response.data.data;
  } catch (error) {
    console.error("drafts - createCompactDraft - catch", error);
    throw error.response.data;
  }
};

export const getDraftsByAdmin = async ({
  offset = 0,
  limit = 25,
  searchQuery = "",
  status = "",
  hasNewRevision,
}) => {
  try {
    let url = `/admin/drafts?offset=${offset}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }
    if (hasNewRevision) {
      url += `&hasNewRevision=${hasNewRevision}`;
    }
    if (searchQuery) {
      url += `&query=${searchQuery}`;
    }

    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - getDraftsByAdmin - catch", error);
    throw error;
  }
};

export const getDraftsByContractor = async ({
  offset = 0,
  limit = 25,
  searchQuery = "",
  status = "",
  submitted = true,
}) => {
  try {
    let url = `/drafts?offset=${offset}&limit=${limit}&submitted=${submitted}`;
    if (status) {
      url += `&status=${status}`;
    }
    if (searchQuery) {
      url += `&query=${searchQuery}`;
    }
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - getDraftsByAdmin - catch", error);
    throw error;
  }
};

// export const getDraftsByContractor = async ({
//   offset = 0,
//   limit = 25,
//   searchQuery = "",
//   // status = "",
//   // submitted = true,
// }) => {
//   // debugger;
//   try {
//     let url = `/drafts?offset=${offset}&limit=${limit}`;
//     // if (status) {
//     //   url += `&status=${status}`;
//     // }
//     // if (searchQuery) {
//     //   url += `&query=${searchQuery}`;
//     // }
//     if (searchQuery) {
//       const query = searchQuery.replace("?", "&");
//       url += query;
//     }
//     const response = await api.get(url);
//     return response.data.data;
//   } catch (error) {
//     console.error("drafts - getDraftsByAdmin - catch", error);
//     throw error;
//   }
// };

export const getDraftByContractor = async (id = "") => {
  try {
    const url = `/drafts/${id}`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - getDraftByContractor - catch", error);
    throw error.response.data;
  }
};

export const getDraftByAdmin = async (id = "") => {
  try {
    const url = `/admin/drafts/${id}`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - getDraftByAdmin - catch", error);
    throw error.response.data;
  }
};

export const assignByDraftId = async (id = "", values = {}) => {
  try {
    const url = `/admin/drafts/${id}/assign`;
    const response = await api.post(url, values);
    return response.data.data;
  } catch (error) {
    console.error("drafts - assignByDraftId - catch", error);
    throw error.response.data;
  }
};

export const sendDraftPriceByAdmin = async (id = "", values = {}) => {
  try {
    const url = `/admin/drafts/${id}/price`;
    const response = await api.patch(url, values);
    return response.data.data;
  } catch (error) {
    console.error("drafts - sendDraftPriceByAdmin - catch", error);
    throw error.response.data;
  }
};

export const getEmailsOptions = async () => {
  try {
    const url = `/admin/options/emails`;
    const response = await api.get(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - getEmailsOptions - catch", error);
    throw error.response.data;
  }
};

export const addNewEmailOption = async (emails = "") => {
  try {
    const url = `/admin/options/emails`;
    const response = await api.post(url, { emails });
    return response.data.data;
  } catch (error) {
    console.error("drafts - addNewEmailOption - catch", error);
  }
};

export const deleteDraft = async (draftId) => {
  try {
    const url = `/drafts/${draftId}`;
    const response = await api.delete(url);
    return response.data.data;
  } catch (error) {
    console.error("drafts - deleteDraft - catch", error);
    throw error.response.data;
  }
};
